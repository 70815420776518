import React from "react";
import { NavLink } from "react-router-dom";

export default function DownloadBlock({ blockData }) {
  return (
    <>
      <section className="section-padding">
        <div className="custom-container">
          <div className="row gy-4 g-lg-5">
            {blockData?.cmpBlock_download_lists.map((value, index) => (
              <div className="col-lg-4 col-xxl-3" key={index}>
                <div className="contact-box download-box">
                  <div className="contact-box-contnet">
                    <p>{value.cmpBlock_download_title}</p>
                    <NavLink
                      to={value.cmpBlock_download_btn_url}
                      target="_blank"
                      className="btn btn-primary"
                    >
                      {value.cmpBlock_download_btn_title}
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
