import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SectionHeading1 from "../../heading/SectionHeading1";
import ProductCard3 from "../../cards/ProductCard3";
import ProductCard2 from "../../cards/ProductCard2";
import NotFoundProduct from "../../data-loader/NotFoundProduct";

export default function SimilarItemsSlider({
  path,
  sliderHeading,
  sliderData,
  boxLayout,
  size,
}) {
  if (!sliderData) return null;

  return (
    <>
      {sliderData && (
        <section
          className={`section-padding${
            size && size
          } bg-shadow-lr bg-shadow-white`}
        >
          {sliderHeading && (
            <SectionHeading1 data={{ heading: sliderHeading }} />
          )}
          {sliderData && sliderData.length > 0 ? (
            boxLayout && boxLayout === 2 ? (
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={15}
                breakpoints={{
                  576: {
                    width: 576,
                    slidesPerView: 1.1,
                    spaceBetween: 15,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2.2,
                    spaceBetween: 25,
                  },
                  992: {
                    width: 992,
                    slidesPerView: 2.5,
                    spaceBetween: 25,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 3.1,
                    spaceBetween: 50,
                  },
                  1400: {
                    width: 1400,
                    slidesPerView: 3.5,
                    spaceBetween: 50,
                  },
                }}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
                id="product-swiper"
              >
                {sliderData &&
                  sliderData.map((value, index) => (
                    <SwiperSlide key={index}>
                      <ProductCard2
                        path={`${path}/${value.category_slug}/${value._id}`}
                        value={value}
                        index={index}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : (
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={15}
                breakpoints={{
                  576: {
                    width: 576,
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 2.3,
                    spaceBetween: 25,
                  },
                  992: {
                    width: 992,
                    slidesPerView: 2.8,
                    spaceBetween: 25,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 3.5,
                    spaceBetween: 50,
                  },
                  1400: {
                    width: 1400,
                    slidesPerView: 3.5,
                    spaceBetween: 50,
                  },
                  1680: {
                    width: 1680,
                    slidesPerView: 4.5,
                    spaceBetween: 50,
                  },
                }}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
                id="product-swiper"
              >
                {sliderData &&
                  sliderData.map((value, index) => (
                    <SwiperSlide key={index}>
                      <ProductCard3
                        path={`${path}/${value.category_slug}/${value._id}`}
                        value={value}
                        index={index}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            )
          ) : (
            <NotFoundProduct data={{ heading: "No Similar Items Found" }} />
          )}
        </section>
      )}
    </>
  );
}
