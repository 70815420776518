import React, { useEffect } from "react";
import NotificationMessage from "../../cards/NotificationMessage";
import { useNavigate } from "react-router-dom";

export default function OrderPaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 6500);
  }, []);

  return (
    <>
      <main className="custom-container form-page-wrapper-container checkout-page">
        <div className="form-page-wrapper">
          <NotificationMessage
            type="success"
            icon={<i className="fa-solid fa-check"></i>}
            message="Payment successful! Your order has been placed successfully."
            redicate={true}
          />
        </div>
      </main>
    </>
  );
}
