import React from "react";
import IsAuthenticated from "../../auth-check/IsAuthenticated";
import { JsonData } from "../../../../JsonData";
import { NavLink } from "react-router-dom";

export default function CardVariantPrice({ price, className }) {
  if (!price) return null;

  if (!IsAuthenticated())
    return (
      <NavLink to="/user/login" className="variant-info-price">
        Login to view price.
      </NavLink>
    );

  return (
    <>
      <b>
        <sup>{JsonData.CURRENCY_ICON}</sup>
        {price}
      </b>
    </>
  );
}
