/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { SERVER_URL } from '../config/Config'

export default function ContactDetailBlock({ blockData }) {
  return (
    <>
      <div className='custom-container'>
        <section className="section-padding contact-us-details">
          <div className="row gy-4">
            {blockData && blockData?.cmpBlock_contact_address !== "" && <div className="col-md-6 col-lg-4">
              <div className="contact-box">
                <div className="contact-box-icon">
                  <img src={`${SERVER_URL}${blockData?.cmpBlock_contact_image_address}`} alt="Address" />
                </div>
                <address dangerouslySetInnerHTML={{__html:blockData?.cmpBlock_contact_address}}></address>
              </div>
            </div>}
            {blockData && blockData?.cmpBlock_contact_number !== "" && <div className="col-md-6 col-lg-4">
              <div className="contact-box">
                <div className="contact-box-icon">
                  <img src={`${SERVER_URL}${blockData?.cmpBlock_contact_image_number}`} alt="Contact Number" />
                </div>
                <address>{blockData?.cmpBlock_contact_number}</address>
              </div>
            </div>}
            {blockData && blockData?.cmpBlock_contact_email !== "" && <div className="col-md-6 col-lg-4">
              <div className="contact-box">
                <div className="contact-box-icon">
                  <img src={`${SERVER_URL}${blockData?.cmpBlock_contact_image_email}`} alt="Phone Details" />
                </div>
                <address>{blockData?.cmpBlock_contact_email}</address>
              </div>
            </div>}
          </div>
        </section>
        {blockData?.cmpBlock_contact_map_url && <section className="section-padding">
          <div id="map">
            <iframe src={blockData?.cmpBlock_contact_map_url} width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            <div className="map-loader">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </section>}
      </div>
    </>
  )
}
