import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { imageBaseUrl } from "../../../../../config/Config";
import { JsonData } from "../../../../../../JsonData";
import "../../../../../assets/css/product-single-variants.css";

const ProductAdditionalOptionsHtmlDemo2 = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordion = (key) => {
    if (activeKey === key) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const [isOpenImg, setIsOpenImg] = useState(false);
  const [isImg, setIsImg] = useState("");

  const handleImgToggle = () => {
    setIsImg("");
    setIsOpenImg(!isOpenImg);
  };

  return (
    <>
      <h5 className="mb-4">Product Variants</h5>
      <div className="product-variants">
        <div className="product-variant-head product-variant-grid desktop">
          <div className="grid-item">
            <span className="head-tag">Type</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Part No.</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Measurement (MM)</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Measurement (IN)</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Action</span>
          </div>
        </div>
        <div className="product-variant-head product-variant-grid mobile">
          <div className="grid-item">
            <span className="head-tag">Items</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Details</span>
          </div>
        </div>
        <Accordion activeKey={activeKey}>
          <div className="product-variant-body scrollbar-sm scrollbar-gray">
            {[0, 1, 2, 3, 4].map((_, index) => (
              <>
                <div className="product-variant-group">
                  <div className="product-variant-grid">
                    <div className="grid-item mobile">
                      <span className="body-tag">Type</span>
                    </div>
                    <div className="grid-item">
                      <div className="product-info-box-small">
                        <div
                          className="product-info-box-small-image"
                          onClick={() => {
                            setIsOpenImg(true);
                            setIsImg(
                              "https://store.passtooling.com:5000/public/uploads/products/punch-with-guided-cutting-part-form-1.png"
                            );
                          }}
                        >
                          <img
                            src={`https://store.passtooling.com:5000/public/uploads/products/punch-with-guided-cutting-part-form-1.png`}
                            alt="Default"
                            className="img-fluid p-2"
                          />
                          {/* <img
                        src={`${imageBaseUrl}/default-image-150-100.png`}
                        alt="Default"
                        className="img-fluid p-2"
                      /> */}
                        </div>
                        <div className="product-info-box-small-text">
                          <p>Round</p>
                          <b>
                            {JsonData.CURRENCY_ICON}
                            802.40
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="grid-item mobile">
                      <span className="body-tag">Part No.</span>
                    </div>
                    <div className="grid-item">
                      <p>4010B02-CU-15-1</p>
                    </div>
                    <div className="grid-item mobile">
                      <span className="body-tag">Measurement (MM)</span>
                    </div>
                    <div className="grid-item">
                      <div>
                        <p className="mb-0">
                          <b style={{ fontWeight: "var(--fw-bold)" }}>Min: </b>
                          00.01 mm
                        </p>
                        <p className="mb-0">
                          <b style={{ fontWeight: "var(--fw-bold)" }}>Max: </b>
                          31.70 mm
                        </p>
                      </div>
                    </div>
                    <div className="grid-item mobile">
                      <span className="body-tag">Measurement (IN)</span>
                    </div>
                    <div className="grid-item">
                      <div>
                        <p className="mb-0">
                          <b style={{ fontWeight: "var(--fw-bold)" }}>Min: </b>
                          00.06 in
                        </p>
                        <p className="mb-0">
                          <b style={{ fontWeight: "var(--fw-bold)" }}>Max: </b>2
                          in
                        </p>
                      </div>
                    </div>
                    <div className="grid-item mobile">
                      <span className="body-tag">Action</span>
                    </div>
                    <div className="grid-item">
                      <div className="product-variant-action-btn">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-view`}>
                              Choose this option
                            </Tooltip>
                          }
                        >
                          <button
                            type="button"
                            className={`sm-icon sm ${
                              activeKey === index.toString() && "active"
                            }`}
                            onClick={() => handleAccordion(index.toString())}
                          >
                            <i className="fa-solid fa-chevron-down"></i>
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-arrow`}>
                              View variant details
                            </Tooltip>
                          }
                        >
                          <button type="button" className="sm-icon sm">
                            <i className="fa-solid fa-eye"></i>
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-plus`}>
                              Add Variant to cart
                            </Tooltip>
                          }
                        >
                          <button type="button" className="sm-icon sm">
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <Accordion.Item
                    eventKey={index.toString()}
                    className="product-variant-accordion"
                  >
                    <Accordion.Body>
                      <h3>Choose this option</h3>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="">Measurement</label>
                          <div className="measurement-input">
                            <input
                              className="form-control"
                              id="measurementValue"
                              name="measurementValue"
                              placeholder="##.##"
                            />
                            <div className="select-wrapper">
                              <select className="form-control">
                                <option value="IN">IN</option>
                                <option value="MM">MM</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="">Coating</label>
                          <div className="select-wrapper">
                            <select className="form-control">
                              <option value="">--- Select ---</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="">
                            Shear Type
                            <small className="text-muted"> (Price: $10)</small>
                          </label>
                          <div className="select-wrapper">
                            <select className="form-control" value="Option 1">
                              <option value="">--- Select ---</option>
                              <option value="Option 1">Option 1</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="">Other</label>
                          <div className="select-wrapper">
                            <select className="form-control">
                              <option value="">--- Select ---</option>
                              <option value="Option 1">Option 1</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </>
            ))}
          </div>
        </Accordion>
      </div>
      {isOpenImg && (
        <div className="image-view-fixed">
          <div className="image-view-box">
            {isImg ? (
              <img src={isImg} alt="" className="img-fluid" />
            ) : (
              <img
                src={`${imageBaseUrl}/product-default-1.png`}
                alt=""
                className="img-fluid"
              />
            )}
            <button type="button" className="sm-icon" onClick={handleImgToggle}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="image-view-overlay" onClick={handleImgToggle}></div>
        </div>
      )}
    </>
  );
};

export default ProductAdditionalOptionsHtmlDemo2;
