import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";

export class FormModelService {
  constructor() {
    this.endpoints = {
      contactUs: "/form/public/contact-us", // public
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async sendContactUs(payload) {
    try {
      const response = await axios.post(
        this.endpoints.contactUs,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
