export const CmsPageId = {
  Home: "654cd99c67dd51b14d9021fd",
  AboutUs: "654cd0e7bee04c126c751b61",
  History: "654cda4b67dd51b14d90225a",
  Production: "654cdcac67dd51b14d9022b1",
  Sustainability: "654cdfa067dd51b14d9022fd",
  ContactUs: "654ce0e267dd51b14d90232e",
  Downloads: "6558a2694a962add35db101d",
};

export const CmsPageType = {
  Home: "CMP_BLOCK_HOME",
  AboutUs: "CMP_BLOCK_ABOUTUS",
  History: "CMP_BLOCK_HISTORY",
  Production: "CMP_BLOCK_PRODUCTION",
  Sustainability: "CMP_BLOCK_SUSTAINABILITY",
  ContactUs: "CMP_BLOCK_CONTACTUS",
  Downloads: "CMP_BLOCK_DOWNLOAD",
};
