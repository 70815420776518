import { toast } from "react-toastify";
import { JsonData } from "../../../JsonData";

export const handleApiError = (error) => {
  if (error.code === "ERR_NETWORK") {
    toast.error(error.message, {
      icon: <i className="fa-solid fa-triangle-exclamation"></i>,
    });
  } else if (error.response && error.response.data) {
    const resp = error.response.data;
    toast.warning(resp.message, {
      icon: <i className="fa-solid fa-triangle-exclamation"></i>,
    });
  }
};

export const showOrderShippingStatusTag = (initialStatus) => {
  return (
    <>
      {initialStatus === JsonData.ORDER_SHIPPING_STATUS.Shipped ? (
        <span className="status-tag tag-warning">
          {JsonData.ORDER_SHIPPING_STATUS.Shipped}
        </span>
      ) : initialStatus === JsonData.ORDER_SHIPPING_STATUS.Delivered ? (
        <span className="status-tag tag-success">
          {JsonData.ORDER_SHIPPING_STATUS.Delivered}
        </span>
      ) : (
        <span className="status-tag tag-dark">
          {JsonData.ORDER_SHIPPING_STATUS.Pending}
        </span>
      )}
    </>
  );
};

export const showOrderPaymentStatusTag = (initialStatus) => {
  return (
    <>
      {initialStatus === JsonData.ORDER_PAYMENT_STATUS.Paid ? (
        <span className="status-tag tag-success">
          {JsonData.ORDER_PAYMENT_STATUS.Paid}
        </span>
      ) : initialStatus === JsonData.ORDER_PAYMENT_STATUS.Failed ? (
        <span className="status-tag tag-danger">
          {JsonData.ORDER_PAYMENT_STATUS.Failed}
        </span>
      ): initialStatus === JsonData.ORDER_PAYMENT_STATUS.unpaid ? (
        <span className="status-tag tag-info">
          {JsonData.ORDER_PAYMENT_STATUS.unpaid}
        </span>
      ) : initialStatus === JsonData.ORDER_PAYMENT_STATUS.AwaitingPayment ? (
        <span className="status-tag tag-info">
          {JsonData.ORDER_PAYMENT_STATUS.AwaitingPayment}
        </span>
      ) : initialStatus === JsonData.ORDER_PAYMENT_STATUS.Overdue ? (
        <span className="status-tag tag-danger">
          {JsonData.ORDER_PAYMENT_STATUS.Overdue}
        </span>
      ) : (
        <span className="status-tag tag-dark">
          {JsonData.ORDER_PAYMENT_STATUS.Pending}
        </span>
      )}
    </>
  );
};

export const showOrderStatusTag = (initialStatus) => {
  return (
    <>
      {initialStatus === JsonData.ORDER_STATUS.Processing ? (
        <span className="status-tag tag-warning">
          {JsonData.ORDER_STATUS.Processing}
        </span>
      ) : initialStatus === JsonData.ORDER_STATUS.Delivered ? (
        <span className="status-tag tag-success">
          {JsonData.ORDER_STATUS.Delivered}
        </span>
      ) : initialStatus === JsonData.ORDER_STATUS.Cancelled ? (
        <span className="status-tag tag-danger">
          {JsonData.ORDER_STATUS.Cancelled}
        </span>
      ) : initialStatus === JsonData.ORDER_STATUS.Pending ? (
        <span className="status-tag tag-dark">
          {JsonData.ORDER_STATUS.Pending}
        </span>
      ) : initialStatus === JsonData.ORDER_STATUS.Draft ? (
        <span className="status-tag tag-dark">
          {JsonData.ORDER_STATUS.Draft}
        </span>
      ) : (
        <span className="status-tag tag-dark">None</span>
      )}
    </>
  );
};
