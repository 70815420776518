import React from "react";
import AuthService from "../../../../../../services/AuthService";
import { CartService } from "../../../../../../services/CartService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { findCartItemAction } from "../../../../../../redux-service/actions/action";
import VariantRemoveToCart from "./VariantRemoveToCart";
import IsAuthenticated from "../../../../../auth-check/IsAuthenticated";
import { useNavigate } from "react-router-dom";
import {
  getCartProductHelper,
  getCartVariantHelper,
} from "../../../../../helper/Helper";

export default function VariantAddToCart({ value, variantValue, isDisabled }) {
  const userId = AuthService.getUserId();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const getCartProduct = getCartProductHelper(getCartData, value?._id);

  const getCartVariant = getCartVariantHelper(
    getCartData,
    value?._id,
    variantValue?._id
  );

  async function addToCartHandler() {
    if (!IsAuthenticated()) {
      navigate("/user/login");
      return;
    }

    if (!value || !variantValue) {
      console.error("Cart value is missing.");
      return;
    }

    try {
      variantValue.variant_quantity = 1;

      const payload = {
        cart_customer_id: userId,
        cart_product_id: value?._id,
        cart_selected_product_variants: JSON.stringify(variantValue),
      };

      const response = await new CartService().itemVariantAddToCartList(
        payload
      );

      if (response?.data?.status) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });
        if (userId) {
          dispatch(findCartItemAction(userId));
          // dispatch(headerCartMenuToggleAction());
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (getCartVariant?.isInCart) {
    return (
      <>
        <VariantRemoveToCart
          value={getCartProduct?.data}
          variantValue={variantValue}
          size="sm"
        />
      </>
    );
  }

  return (
    <>
      <button
        title="Add to Cart"
        type="button"
        className="sm-icon sm"
        onClick={addToCartHandler}
        disabled={isDisabled}
      >
        <i className="fa-solid fa-plus"></i>
      </button>
    </>
  );
}
