import React, { useState } from "react";
import { SERVER_URL, imageBaseUrl } from "../../config/Config";
import { JsonData } from "../../../JsonData";
import RemoveToCartBtn from "../button/RemoveToCartBtn";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerCartMenuCloseAction } from "../../redux-service/actions/action";
import VariantQtyPlusMinus from "../pages/products/single/variant-table/helper/VariantQtyPlusMinus";
import VariantRemoveToCart from "../pages/products/single/variant-table/helper/VariantRemoveToCart";
import { Accordion } from "react-bootstrap";
import {
  fetchSelectedChoseVariantOptionInCart,
  fetchSelectedMeasurementInCart,
} from "../helper/Helper";

export default function CartVariableTable({ value, index }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeAccordion, setActiveAccordion] = useState(null);

  if (!value) {
    return null;
  }

  const productVariants = value?.cart_selected_product_variants;

  const goToproduct = (slug, id) => {
    navigate(`/${slug}/${id}`);
    dispatch(headerCartMenuCloseAction());
  };

  const toggleAccordion = (variantId) => {
    setActiveAccordion((prevId) => (prevId === variantId ? null : variantId));
  };

  return (
    <>
      <tr className="table-hr hover-unset" key={`cart_table_hr_${value?._id}`}>
        <td colSpan={10}>
          <hr />
        </td>
      </tr>
      {productVariants.map((variantValue, _) => (
        <React.Fragment key={`tr_variation_row_${variantValue._id}`}>
          <tr className="variation-tr hover-unset">
            <td>
              <div>
                <div className="d-flex gap-2 align-items-center cart-item">
                  <div className="cart-img">
                    {variantValue.variant_image !== "" ? (
                      <img
                        src={`${SERVER_URL}${variantValue.variant_image}`}
                        alt={variantValue.additional_type}
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={`${imageBaseUrl}/default-image-150-100.png`}
                        alt="Cart default"
                        className="img-fluid p-2"
                      />
                    )}
                  </div>
                  <div className="text">
                    <b
                      className="title"
                      onClick={() => {
                        goToproduct(
                          value?.cart_product?.product_slug,
                          value?.cart_product_id
                        );
                      }}
                    >
                      {variantValue?.variant_name}
                    </b>
                    <p>
                      {fetchSelectedMeasurementInCart(
                        variantValue?.variant_measurement_selected
                      )}
                    </p>
                    <p>
                      <b>QTY: </b> {variantValue?.variant_quantity}
                    </p>
                    {variantValue?.variant_options_selected?.length > 0 && (
                      <p
                        onClick={() => toggleAccordion(variantValue._id)}
                        className="d-flex align-items-center"
                      >
                        <b>Chosen More Option:&nbsp;</b>
                        {variantValue?.variant_options_selected?.length}
                        &nbsp;&nbsp;&nbsp;
                        <i
                          className={`text-highlight-color fas ${
                            activeAccordion === variantValue._id
                              ? "fa-chevron-up"
                              : "fa-chevron-down"
                          }`}
                        ></i>
                      </p>
                    )}
                  </div>
                </div>
                <Accordion
                  activeKey={
                    activeAccordion === variantValue._id
                      ? variantValue._id && variantValue._id.toString()
                      : ""
                  }
                  className="style-none"
                >
                  <Accordion.Item eventKey={variantValue._id && variantValue._id.toString()}>
                    <Accordion.Body>
                      <ul className="variant-option-list">
                        {fetchSelectedChoseVariantOptionInCart(
                          variantValue?.variant_options_selected
                        )}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </td>
            <td>
              <div>
                <VariantQtyPlusMinus
                  value={value}
                  variantValue={variantValue}
                  size="sm"
                />
              </div>
            </td>
            <td>
              <div>
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <b>
                    <sup>{JsonData.CURRENCY_ICON}</sup>
                    {variantValue?.variant_total?.toLocaleString("en-US")}
                  </b>
                </div>
              </div>
            </td>
            <td>
              <div>
                <div className="d-flex justify-content-center gap-2">
                  {productVariants && productVariants.length > 1 ? (
                    <VariantRemoveToCart
                      value={value}
                      variantValue={variantValue}
                      size="sm"
                    />
                  ) : (
                    <RemoveToCartBtn
                      cartItemID={value?._id}
                      type="REMOVE_CART_ICON_2"
                    />
                  )}
                </div>
              </div>
            </td>
          </tr>
        </React.Fragment>
      ))}
    </>
  );
}
