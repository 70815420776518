import { toast } from "react-toastify"; // Notification popup show
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { findWishlistItemAction } from "../../redux-service/actions/action";
import { useEffect } from "react";
import { WishlistService } from "../../services/WishlistService";
import { JsonData } from "../../../JsonData";
import secureLocalStorage from "react-secure-storage";

export default function WishListBtn({ productID, type }) {
  const navigate = useNavigate();
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const dispatch = useDispatch();

  const getWishlistData = useSelector(
    (state) => state.userWishlistReducer.userWishlistData
  );

  const filterWishlistData =
    getWishlistData && getWishlistData.filter((ele) => ele._id === productID);

  const addToWishlistHandler = async () => {
    if (!userAuthData) {
      return navigate("/user/login");
    }

    if (!productID) {
      return;
    }

    try {
      const payload = {
        user_wishlist_id: productID,
      };

      const response = await new WishlistService().updateWishlist(
        userID,
        payload
      );

      if (response?.data) {
        toast.error("Product has been added to the wishlist.", {
          icon: <i className="fa-brands fa-gratipay"></i>,
        });

        if (userID) {
          dispatch(findWishlistItemAction(userID));
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  const removeToWishlistHandler = async () => {
    if (!userAuthData) {
      return navigate("/user/login");
    }

    if (!productID) {
      return; // Exit early if cartData is undefined
    }

    try {
      const response = await new WishlistService().deleteWishlist(
        userID,
        productID
      );

      if (response?.data) {
        toast.error("Product has been removed to the wishlist.", {
          icon: <i className="fa-solid fa-xmark"></i>,
        });

        if (userID) {
          dispatch(findWishlistItemAction(userID)); // Dispatch the action to fetch user data
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  function WishlistBtnOption1() {
    return (
      <div className="fav-icon" onClick={addToWishlistHandler}>
        <i className="fa-regular fa-heart"></i>
      </div>
    );
  }

  function WishlistBtnOptionToggle() {
    const handleClick =
      filterWishlistData?.length > 0
        ? removeToWishlistHandler
        : addToWishlistHandler;

    const iconClass =
      filterWishlistData?.length > 0
        ? "fa-solid fa-heart"
        : "fa-regular fa-heart";

    return (
      <div className="fav-icon" onClick={handleClick}>
        <i className={iconClass}></i>
      </div>
    );
  }

  function WishlistBtnOption3() {
    return (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={removeToWishlistHandler}
      >
        Remove to Wishlist
      </button>
    );
  }

  return (
    <>
      {type && type === "ADD_WISHLIST_ICON"
        ? WishlistBtnOption1()
        : type === "REMOVE_WISHLIST"
        ? WishlistBtnOption3()
        : type === "REMOVE_WISHLIST_ICON"
        ? WishlistBtnOptionToggle()
        : type === "ADD_WISHLIST_TOGGLE"
        ? WishlistBtnOptionToggle()
        : WishlistBtnOption1()}
    </>
  );
}
