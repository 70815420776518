import React from "react";

export default function SectionHeading2(props) {
  const center = props.center;
  return (
    <div
      className={
        "row section-title-2 p-last-0 " +
        (center ? " justify-content-center text-center" : "")
      }
    >
      <div className="col-lg-8">
        <h2 className={"display-5 highlight-top " + (center ? " center" : "")}>
          {props.data.heading}
        </h2>
        {props.data.text}
      </div>
    </div>
  );
}
