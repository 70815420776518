import React from "react";
import { NavLink } from "react-router-dom";

export default function ContinueShoppingBtn() {
  return (
    <>
      <NavLink to="/" className="btn btn-primary">
        <i className="fa-solid fa-arrow-left-long"></i> Continue Shopping
      </NavLink>
    </>
  );
}