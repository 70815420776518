import React from "react";
import { SERVER_URL } from "../../config/Config";
import ProductSkel1 from "../skeleton/ProductSkel1";

export default function TeamCard({ teamData }) {
  if (!teamData) {
    return null;
  }

  return (
    <>
      {teamData ? (
        <div className="card team-card">
          <div className="card-image">
            <img
              src={`${SERVER_URL}${teamData.cmpBlock_team_image}`}
              alt={teamData.cmpBlock_team_name}
              className="img-fluid"
            />
            <div
              className="card-hover-content"
              dangerouslySetInnerHTML={{ __html: teamData.cmpBlock_team_about }}
            ></div>
          </div>
          <div className="card-body">
            <b>{teamData.cmpBlock_team_post}</b>
            <h3>{teamData.cmpBlock_team_name}</h3>
          </div>
        </div>
      ) : (
        <ProductSkel1 />
      )}
    </>
  );
}
