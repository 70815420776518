import React from "react";
import { ToastContainer } from "react-toastify";
import FooterCol1 from "./FooterCol1";
import FooterCol2 from "./FooterCol2";
import FooterCol3 from "./FooterCol3";
import FooterCol4 from "./FooterCol4";
import FooterCopyRight from "./FooterCopyRight";
import ContactUsBelt from "./ContactUsBelt";

export default function Footer() {
  return (
    <>
      <ContactUsBelt />
      <footer>
        <div className="custom-container">
          <div className="grid">
            <FooterCol1 />
            <FooterCol2 />
            <FooterCol3 />
            <FooterCol4 />
          </div>
          <FooterCopyRight />
        </div>
      </footer>
      <ToastContainer
        position="top-left"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
