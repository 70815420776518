export const APP_LIVE_URL = "https://store.passtooling.com";
export const APP_LOCAL_URL = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://store.passtooling.com:3000";
export const APP_URL = ['localhost', '192.168.0.157'].includes(window.location.hostname) ? APP_LOCAL_URL : APP_LIVE_URL;

export const SERVER_LIVE_URL = "https://store.passtooling.com:5000";
export const SERVER_LOCAL_URL = window.location.hostname === "localhost" ? "http://localhost:5000" : "https://store.passtooling.com:5000";
export const SERVER_URL = ['localhost', '192.168.0.157'].includes(window.location.hostname) ? SERVER_LOCAL_URL : SERVER_LIVE_URL;

export const baseurl = APP_URL;
export const imageBaseUrl = `${baseurl}/images`;
export const serverImageBaseUrl = `${SERVER_URL}/public/uploads`;

export const MAIN_APP_URL = "https://passtooling.com"