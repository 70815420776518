/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { PageService } from "../../services/PageService";
// import { JsonData } from "../../../JsonData";

export default function FooterCol4() {
  // const pageId = JsonData.cmpPageID.ContactUs;
  // const [inputData, setInputData] = useState();
  // const ContactData =
  //   inputData &&
  //   inputData?.filter(
  //     (ele) => ele.cmpBlock_id === "CMP_BLOCK_CONTACT_DETAILS"
  //   )[0];

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // function GetApiService() {
  //   if (pageId) {
  //     new PageService()
  //       .showPage(pageId)
  //       .then((response) => {
  //         const result = response.data.result[0];
  //         if (result) {
  //           setInputData(result?.page_component_list);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(JsonData?.messages?.errors?.read, error);
  //       });
  //   }
  // }

  // useEffect(() => {
  //   GetApiService();
  // }, []);

  return (
    <>
      <div className="grid-item">
        <ul className="footer-contact-info">
          <li className="phone">
            <a href={`tel:2623072209`}>262-307-2209</a>
            <div className="email">
              <a href={`mailto:sales@passtooling.com`}>sales@passtooling.com</a>
            </div>
          </li>
          <li>
            <address>
              Pass Tooling LLC, <br />
              5172 N 126th Street, <br />
              Butler, WI 53007
            </address>
          </li>
        </ul>
      </div>
    </>
  );
}
