import React, { useState } from "react";
import { SERVER_URL, imageBaseUrl } from "../../config/Config";
import { JsonData } from "../../../JsonData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerCartMenuCloseAction } from "../../redux-service/actions/action";
import CardVariantPrice from "../cards/helper/CardVariantPrice";
import { Accordion } from "react-bootstrap";
import {
  fetchSelectedChoseVariantOptionInCart,
  fetchSelectedMeasurementInCart,
} from "../helper/Helper";

export default function CartItemInfoSingle({ value }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeAccordion, setActiveAccordion] = useState(null);

  if (!value) {
    return null;
  }

  const productVariants = value?.cart_selected_product_variants;

  const goToproduct = (slug, id) => {
    navigate(`/${slug}/${id}`);
    dispatch(headerCartMenuCloseAction());
  };

  const toggleAccordion = (variantId) => {
    setActiveAccordion((prevId) => (prevId === variantId ? null : variantId));
  };

  return (
    <>
      {productVariants && productVariants.length === 0 && (
        <div className="cart-item" key={`normal_product_${value._id}`}>
          <div className="img">
            {value?.cart_product?.product_images[0] !== "" ? (
              <img
                src={`${SERVER_URL}${value?.cart_product?.product_images[0]}`}
                alt={value?.cart_product?.product_name}
                className="img-fluid"
              />
            ) : (
              <img
                src={`${imageBaseUrl}/default-image-150-100.png`}
                alt="Cart default"
                className="img-fluid"
              />
            )}
          </div>
          <div className="text">
            <b
              onClick={() =>
                goToproduct(
                  value?.cart_product?.product_slug,
                  value?.cart_product_id
                )
              }
            >
              {value?.cart_product?.product_name}
            </b>
            <p>
              <b>QTY: </b> {value?.cart_product_quantity}
            </p>
            <p>
              <b>Total: </b> <sup>{JsonData.CURRENCY_ICON}</sup>
              {value?.cart_product_total}
            </p>
          </div>
        </div>
      )}
      {productVariants && productVariants.length > 0 && (
        <>
          <div className="cart-item" key={`variable_product_${value._id}`}>
            <div className="img">
              {value?.cart_product?.product_images[0] !== "" ? (
                <img
                  src={`${SERVER_URL}${value?.cart_product?.product_images[0]}`}
                  alt={value?.cart_product?.product_name}
                  className="img-fluid"
                />
              ) : (
                <img
                  src={`${imageBaseUrl}/default-image-150-100.png`}
                  alt="Cart default"
                  className="img-fluid"
                />
              )}
            </div>
            <div className="text">
              <b
                onClick={() =>
                  goToproduct(
                    value?.cart_product?.product_slug,
                    value?.cart_product_id
                  )
                }
              >
                {value?.cart_product?.product_name}
              </b>
              <p>
                <b>Selected Options: </b> {productVariants?.length}
              </p>
              <p>
                <b>Net Total: </b> <sup>{JsonData.CURRENCY_ICON}</sup>
                <CardVariantPrice price={value?.cart_item_total} />
              </p>
            </div>
          </div>
          <b
            style={{
              borderBottom: "5px solid var(--border-yellow-color)",
              padding: "15px 0",
              margin: "0 0 15px",
              display: "block",
            }}
          >
            Selected Options
          </b>
          {productVariants &&
            productVariants.map((variantValue, _) => (
              <>
                <div
                  className="d-flex gap-2 cart-item mb-3"
                  key={`variable_product_option_${variantValue._id}`}
                >
                  <div className="cart-img">
                    {variantValue.variant_image !== "" ? (
                      <img
                        src={`${SERVER_URL}${variantValue.variant_image}`}
                        alt={variantValue.variant_name}
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={`${imageBaseUrl}/default-image-150-100.png`}
                        alt="Cart default"
                        className="img-fluid p-2"
                      />
                    )}
                  </div>
                  <div className="text">
                    <b>{variantValue.variant_name}</b>
                    <p>
                      {fetchSelectedMeasurementInCart(
                        variantValue?.variant_measurement_selected
                      )}
                    </p>
                    <p className="d-flex gap-2">
                      <span>
                        <b>QTY: </b> {variantValue?.variant_quantity}
                      </span>
                      <span>
                        <b>Total: </b> <sup>{JsonData.CURRENCY_ICON}</sup>
                        {variantValue?.variant_total}
                      </span>
                    </p>
                    {variantValue?.variant_options_selected?.length > 0 && (
                      <p
                        onClick={() => toggleAccordion(variantValue._id)}
                        className="d-flex align-items-center"
                      >
                        <b>Chosen More Option:&nbsp;</b>
                        {variantValue?.variant_options_selected?.length}
                        &nbsp;&nbsp;&nbsp;
                        <i
                          className={`text-highlight-color fas ${
                            activeAccordion === variantValue._id
                              ? "fa-chevron-up"
                              : "fa-chevron-down"
                          }`}
                        ></i>
                      </p>
                    )}
                  </div>
                </div>
                <Accordion
                  activeKey={
                    activeAccordion === variantValue._id
                      ? variantValue._id && variantValue._id.toString()
                      : ""
                  }
                  className="style-none"
                >
                  <Accordion.Item eventKey={variantValue._id && variantValue._id.toString()}>
                    <Accordion.Body>
                      <ul className="variant-option-list">
                        {fetchSelectedChoseVariantOptionInCart(
                          variantValue?.variant_options_selected
                        )}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            ))}
        </>
      )}
    </>
  );
}
