import React from "react";
import { baseurl, MAIN_APP_URL } from "../../config/Config";
import { NavLink } from "react-router-dom";

export default function Logo() {
  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <div className="header-logo">
          <NavLink to={MAIN_APP_URL}>
            <img
              src={baseurl + "/images/logo.png"}
              alt="Pass Tooling Black"
              className="img-fluid"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
}
