import React, { useState } from "react";
import { Accordion, Alert } from "react-bootstrap";
import VariantRow from "./VariantRow";
// import { useSelector } from "react-redux";
// import { getCartProductHelper } from "../../../../helper/Helper";

export default function VariantWrapper({ value }) {
  const [activeKey, setActiveKey] = useState(null);
  const [error, setError] = useState("");

  const handleAccordion = (key) => {
    if (activeKey === key) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  // const getCartData = useSelector(
  //   (state) => state.userCartReducer.userCartData
  // );

  // const getCartProduct = getCartProductHelper(getCartData, value?._id);
  // const getAllSelectedVariantIds =
  //   getCartProduct.data.cart_selected_product_variants &&
  //   getCartProduct.data.cart_selected_product_variants.map((ele) => ele._id);

  const productVariant = value?.product_variants;

  const productVariantSort =
    productVariant &&
    productVariant.slice().sort((a, b) => {
      if (a.variant_order !== b.variant_order) {
        return a.variant_order - b.variant_order;
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });

  // useEffect(() => {
  //   if (getCartProduct.isInCart) {
  //     setActiveKey(getAllSelectedVariantIds);
  //   } else {
  //     setActiveKey(null);
  //   }
  // }, [getAllSelectedVariantIds, getCartProduct.isInCart]);

  return (
    <>
      {error && error?.status ? (
        <Alert key="danger" variant="danger">
          <b>{error?.message}</b>
        </Alert>
      ) : null}
      <div className="product-variants">
        <div className="product-variant-head product-variant-grid desktop">
          <div className="grid-item">
            <span className="head-tag">Type</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Part No.</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Measurement (MM)</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Measurement (IN)</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Action</span>
          </div>
        </div>
        <div className="product-variant-head product-variant-grid mobile">
          <div className="grid-item">
            <span className="head-tag">Items</span>
          </div>
          <div className="grid-item">
            <span className="head-tag">Details</span>
          </div>
        </div>
        <Accordion activeKey={activeKey}>
          <div className="product-variant-body scrollbar-sm scrollbar-gray">
            {productVariantSort &&
              productVariantSort.map((variantValue, variantIndex) => (
                <VariantRow
                  value={value}
                  variantValue={variantValue}
                  variantIndex={variantIndex}
                  productVariant={productVariant}
                  activeKey={activeKey}
                  handleAccordion={handleAccordion}
                  error={error}
                  onError={(error) => {
                    setError(error);
                  }}
                />
              ))}
          </div>
        </Accordion>
      </div>
    </>
  );
}
