/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MultipleSelectCheckmarks from "../../../components/form/MultipleSelectCheckmarks";
import { toast } from "react-toastify";
import { imageBaseUrl, SERVER_URL } from "../../../config/Config";
import KeywordMultipleMachineMakeSelect from "../../../components/form/KeywordMultipleMachineMakeSelect";
import KeywordMultipleMachineModelSelect from "../../../components/form/KeywordMultipleMachineModelSelect";
import { CustomerService } from "../../../services/CustomerService";
import AuthService from "../../../services/AuthService";
import { JsonData } from "../../../../JsonData";
import secureLocalStorage from "react-secure-storage";

export default function UpdateProfile() {
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const userImgRef = useRef();

  // for redirect
  const navigate = useNavigate();
  // define state
  const [inputData, setInputData] = useState({
    user_name: "",
    user_email: "",
    user_profile: "",
    user_mobile_number: "",
    user_business_name: "",
    user_machine_make_ids: [],
    user_machine_model_ids: [],
    user_sheet_material: [],
    user_sheet_thickness: [],
    user_address: "",
  });
  const {
    user_name,
    user_email,
    user_profile,
    user_mobile_number,
    user_business_name,
    user_machine_make_ids,
    user_machine_model_ids,
    user_sheet_material,
    user_sheet_thickness,
    user_address,
  } = inputData;
  const [uploadImage, setUploadImage] = useState({
    user_profile: "",
  });

  // This function updates a specific property in the  `inputData` object
  function InputHandler(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  // Updates `value` with a child component `value`.
  function fetchChildHadnler(name, value) {
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  }

  // for file `add` handling - multiple
  function AddFileHandler(e) {
    const files = e.target.files;
    if (files) {
      const filePath = files[0];
      if (filePath) {
        setInputData({ ...inputData, user_profile: filePath });
        setUploadImage({ ...uploadImage, user_profile: filePath });
      }
    }
  }

  // for file `remove` handling - multiple
  function removeFileHandler() {
    setInputData({ ...inputData, user_profile: "" });
    setUploadImage({ user_profile: "" });
  }

  // Card - Default img
  const DefaultImgHtml = (id) => {
    return (
      <>
        <img
          src={`${imageBaseUrl}/avatar.png`}
          alt="default category"
          className="img-fluid"
        />
      </>
    );
  };

  // Card - Server Img and File Img
  const FileServerImgHtml = (id, value, status) => {
    return (
      <>
        {status ? (
          <img
            src={URL.createObjectURL(value)}
            alt="File"
            className="img-fluid"
          />
        ) : (
          <img
            src={`${SERVER_URL}${value}`}
            alt="Server"
            className="img-fluid"
          />
        )}
      </>
    );
  };

  // Show the image based on the condition.
  const getFileImage = (index, value) => {
    if (value === "null" || value === "" || value === null) {
      return DefaultImgHtml(index);
    } else if (value instanceof File) {
      return FileServerImgHtml(index, value, true); // if file
    } else {
      return FileServerImgHtml(index, value, false); // if server path
    }
  };

  // API - `profile update`
  const updateUserApiHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("user_name", user_name);
      formData.append("user_email", user_email);
      formData.append("user_profile", user_profile);
      formData.append("user_mobile_number", user_mobile_number);
      formData.append("user_business_name", user_business_name);
      if (user_machine_make_ids && user_machine_make_ids.length > 0) {
        user_machine_make_ids.map((value) =>
          formData.append("user_machine_make_ids", value)
        );
      }
      if (user_machine_model_ids && user_machine_model_ids.length > 0) {
        user_machine_model_ids.map((value) =>
          formData.append("user_machine_model_ids", value)
        );
      }
      if (user_sheet_material && user_sheet_material.length > 0) {
        user_sheet_material.map((value) =>
          formData.append("user_sheet_material", value)
        );
      }
      if (user_sheet_material && user_sheet_material.length > 0) {
        user_sheet_material.map((value) =>
          formData.append("user_sheet_thickness", value)
        );
      }
      formData.append("user_address", user_address);

      const resp = await new CustomerService().updateCustomer(userID, formData);
      if (resp.data.status) {
        toast.success(resp.data.message, {
          icon: <i className="fa-solid fa-edit"></i>,
        });
        AuthService.customerProfileUpdate(navigate);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.update + " " + error);
    }
  };

  // form Handler
  function formHandler(e) {
    e.preventDefault();
    updateUserApiHandler();
  }

  // API - `get user`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserApiHandler = async () => {
    try {
      const resp = await new CustomerService().showCustomer(userID);
      const result = resp.data;
      if (result.status === true) {
        const getUserData = result.result;
        setInputData({
          ...inputData,
          user_name: getUserData.user_name,
          user_email: getUserData.user_email,
          user_profile: getUserData.user_profile,
          user_mobile_number: getUserData.user_mobile_number,
          user_business_name: getUserData.user_business_name,
          user_machine_make_ids: getUserData.user_machine_make_ids,
          user_machine_model_ids: getUserData.user_machine_model_ids,
          user_sheet_material: getUserData.user_sheet_material,
          user_sheet_thickness: getUserData.user_sheet_thickness,
          user_address: getUserData.user_address,
        });
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  useEffect(() => {
    getUserApiHandler();
  }, [userID]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 col-lg-10 col-xl-11 col-xxl-10">
          <div className="form-box">
            <h1 className="h2 mb-25-50 text-center">Update Your Profile</h1>
            <form onSubmit={formHandler}>
              <div className="row">
                <div className="col-12">
                  <div className="user-profile-bio">
                    <div className="user-img">
                      <input
                        type="file"
                        name="user_profile"
                        ref={userImgRef}
                        accept="image/jpeg, image/jpg, image/png"
                        onChange={AddFileHandler}
                        hidden
                      />
                      {getFileImage(1, user_profile)}
                    </div>
                    <div className="user-text">
                      <div className="user-btn">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => userImgRef.current.click()}
                        >
                          Upload New Photo
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary"
                          onClick={removeFileHandler}
                        >
                          Reset
                        </button>
                      </div>
                      <p>Allowed PNG or JPEG. Max size of 800K.</p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 form-group">
                  <label htmlFor="user_name">
                    Name <sup>*</sup>
                  </label>
                  <input
                    value={user_name}
                    type="text"
                    id="user_name"
                    name="user_name"
                    className="form-control"
                    onChange={InputHandler}
                  />
                </div>
                <div className="col-lg-6 col-xl-4 form-group">
                  <label htmlFor="user_mobile_number">
                    Mobile Number <sup>*</sup>
                  </label>
                  <input
                    value={user_mobile_number}
                    type="tel"
                    id="user_mobile_number"
                    name="user_mobile_number"
                    className="form-control"
                    onChange={InputHandler}
                  />
                </div>
                <div className="col-lg-6 col-xl-4 form-group">
                  <label htmlFor="user_business_name">
                    Business Name <sup>*</sup>
                  </label>
                  <input
                    value={user_business_name}
                    id="user_business_name"
                    type="text"
                    name="user_business_name"
                    className="form-control"
                    onChange={InputHandler}
                  />
                </div>
                <div className="col-lg-6 col-xl-6 form-group">
                  <KeywordMultipleMachineMakeSelect
                    inputName="user_machine_make_ids"
                    fetchChildData={fetchChildHadnler}
                    selectedValues={user_machine_make_ids}
                    required
                  />
                </div>
                <div className="col-lg-6 col-xl-6 form-group">
                  <KeywordMultipleMachineModelSelect
                    inputName="user_machine_model_ids"
                    fetchChildData={fetchChildHadnler}
                    machineMakeIds={user_machine_make_ids}
                    selectedValues={user_machine_model_ids}
                    required
                  />
                </div>
                <div className="col-lg-6 col-xl-6 form-group">
                  <MultipleSelectCheckmarks
                    productDropdown_id="DROPDOWN_SHEET_MATERIAL"
                    inputName="user_sheet_material"
                    fetchChildData={fetchChildHadnler}
                    selectedValues={user_sheet_material}
                    required
                  />
                </div>
                <div className="col-lg-6 col-xl-6 form-group">
                  <MultipleSelectCheckmarks
                    productDropdown_id="DROPDOWN_SHEET_THICKNESS"
                    inputName="user_sheet_thickness"
                    fetchChildData={fetchChildHadnler}
                    selectedValues={user_sheet_thickness}
                    required
                  />
                </div>
                <div className="col-lg-12 form-group">
                  <label htmlFor="user_address">
                    Address <sup>*</sup>
                  </label>
                  <textarea
                    rows={4}
                    value={user_address}
                    className="form-control"
                    name="user_address"
                    onChange={InputHandler}
                  ></textarea>
                </div>
                <div className="col-12 d-flex justify-content-center flex-column flex-sm-row gap-3">
                  <button type="submit" className="btn btn-primary">
                    Update Profile
                  </button>
                  <NavLink
                    to="/user/profile"
                    className="btn btn-outline-primary"
                  >
                    Back
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
