import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";

export class OrderService {
  constructor() {
    this.endpoints = {
      findOrderItems: "/order/user/:id",
      create: "/order/create",
    };
    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async addOrder(payload) {
    try {
      const response = await axios.post(
        this.endpoints.create,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async findUserOrders(id) {
    try {
      const response = await axios.get(
        this.endpoints.findOrderItems.replace(":id", id)
      );
      return response;
    } catch (error) {
      console.error("findUserOrders", error)
      // handleApiError(error);
    }
  }
}
