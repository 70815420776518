import ContactForm from "../components/pages/contact-us/ContactForm";
import ContactDetailBlock from "../page-block/ContactDetailBlock";
import ContentBlock from "../page-block/ContentBlock";
import DownloadBlock from "../page-block/DownloadBlock";
import FaqTabbedAccordionBlock from "../page-block/FaqTabbedAccordionBlock";
import HeroBannerBlock from "../page-block/HeroBannerBlock";
import ImageBlock from "../page-block/ImageBlock";
import ImageContentBlock from "../page-block/ImageContentBlock";
import OverviewSliderBlock from "../page-block/OverviewSliderBlock";
import PageIntroBlock from "../page-block/PageIntroBlock";
import PageOverviewBlock from "../page-block/PageOverviewBlock";
import ProductCategoriesBlock from "../page-block/ProductCategoriesBlock";
import SeoMetaBlock from "../page-block/SeoMetaBlock";
import TeamGridBlock from "../page-block/TeamGridBlock";

export const CmpBlockData = [
  {
    cmp_block_id: "CMP_BLOCK_META",
    cmp_block_block: SeoMetaBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_HERO_BANNER",
    cmp_block_block: HeroBannerBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_INTRO",
    cmp_block_block: PageIntroBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_DOWNLOAD",
    cmp_block_block: DownloadBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_FULL_SCREEN_IMAGE",
    cmp_block_block: ImageBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_OVERVIEW",
    cmp_block_block: PageOverviewBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_PRODUCT_CATEGORIES",
    cmp_block_block: ProductCategoriesBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_OVERVIEW_SLIDER",
    cmp_block_block: OverviewSliderBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_TEAM_GRID",
    cmp_block_block: TeamGridBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_IMAGE_AND_CONTENT_1",
    cmp_block_block: ImageContentBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_IMAGE_AND_CONTENT_2",
    cmp_block_block: ImageContentBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_IMAGE_AND_CONTENT_3",
    cmp_block_block: ImageContentBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_IMAGE_AND_CONTENT_4",
    cmp_block_block: ImageContentBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_CONTENT_ONLY",
    cmp_block_block: ContentBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_CONTACT_DETAILS",
    cmp_block_block: ContactDetailBlock,
  },
  {
    cmp_block_id: "CMP_BLOCK_CONTACT_FORM",
    cmp_block_block: ContactForm,
  },
  {
    cmp_block_id: "CMP_BLOCK_FAQ_TABBED_ACCORDION",
    cmp_block_block: FaqTabbedAccordionBlock,
  },
];
