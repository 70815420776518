import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ProductService } from "../../../../services/ProductService";
import {
  mainProductList,
  mainProductListIsFilter,
  mainProductListLoader,
  mainProductListPagination,
} from "../../../../redux-service/actions/action";
import { useDispatch, useSelector } from "react-redux";
import TreeSkel from "../../../skeleton/TreeSkel";
import { JsonData } from "../../../../../JsonData";

export default function ProductTree() {
  const [treeDropdown, setTreeView] = useState([]);
  const dispatch = useDispatch();

  const getApiServiceHandler = async () => {
    try {
      const response = await new ProductService().getTreeView();
      const result = response?.data?.result;
      if (result) {
        setTreeView(result);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
      // Handle error or show user-friendly error message
    }
  };

  const productListPagiData = useSelector(
    (state) => state.productReducer.productMainListPagination
  );

  const getProductByCategoryId = async (categoryId) => {
    dispatch(mainProductListLoader(true));

    let query = "";
    if (productListPagiData) {
      query += `?page=${productListPagiData.currentPage}`;
    }

    if (categoryId) {
      let payload = {
        categoryId: categoryId,
      };

      try {
        const response = await new ProductService().filterProductList(
          query,
          payload
        );
        const getDataResult = response?.data?.result;
        const getPagiData = response?.data?.paginationInfo;
        dispatch(mainProductList(getDataResult));
        if (getPagiData.currentPage > getPagiData.totalPages) {
          dispatch(
            mainProductListPagination({
              ...getPagiData,
              currentPage: 1,
            })
          );
        } else {
          dispatch(mainProductListPagination(getPagiData));
        }
        dispatch(mainProductListIsFilter(true));
      } catch (error) {
        console.error(JsonData?.messages?.errors?.search + " " + error);
      } finally {
        dispatch(mainProductListLoader(false));
      }
    } else {
      dispatch(mainProductListLoader(false));
      dispatch(mainProductListIsFilter(false));
    }
  };

  useEffect(() => {
    getApiServiceHandler();
  }, []);

  return (
    <>
      <div className="sidebar-tree scrollbar-sm scrollbar-gray">
        <h5>Product Categories:-</h5>
        <Accordion defaultActiveKey="0">
          {treeDropdown && treeDropdown.length > 0 ? (
            treeDropdown.map((make, makeIndex) => (
              <Accordion.Item key={makeIndex} eventKey={make.id}>
                <Accordion.Header>
                  <div className="accordion-plus-minus-icon">
                    <i className="fa-solid fa-plus"></i>
                    <i className="fa-solid fa-minus"></i>
                  </div>
                  {make.name}
                </Accordion.Header>
                <Accordion.Body>
                  <Accordion defaultActiveKey="0">
                    {make.models &&
                      make.models.length > 0 &&
                      make.models.map((model, modelIndex) => (
                        <Accordion.Item key={modelIndex} eventKey={model.id}>
                          <Accordion.Header>
                            <div className="accordion-plus-minus-icon">
                              <i className="fa-solid fa-plus"></i>
                              <i className="fa-solid fa-minus"></i>
                            </div>
                            {model.name}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion defaultActiveKey="0">
                              {model.categories &&
                                model.categories.length > 0 &&
                                model.categories.map(
                                  (category, categoryIndex) => (
                                    <Accordion.Item
                                      key={categoryIndex}
                                      eventKey={category.id}
                                    >
                                      <Accordion.Header>
                                        <div className="accordion-plus-minus-icon">
                                          <i className="fa-solid fa-plus"></i>
                                          <i className="fa-solid fa-minus"></i>
                                        </div>
                                        {category.name}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {category.child_categories &&
                                        category.child_categories.length > 0 ? (
                                          <Accordion defaultActiveKey="0">
                                            {category.child_categories &&
                                              category.child_categories.length >
                                                0 &&
                                              category.child_categories.map(
                                                (
                                                  childCategory,
                                                  childCategoryIndex
                                                ) => (
                                                  <Accordion.Item
                                                    key={childCategoryIndex}
                                                    eventKey={childCategory.id}
                                                  >
                                                    <Accordion.Header>
                                                      <div className="accordion-plus-minus-icon">
                                                        <i className="fa-solid fa-plus"></i>
                                                        <i className="fa-solid fa-minus"></i>
                                                      </div>
                                                      {childCategory.name}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                      {childCategory.sub_child_categories &&
                                                      childCategory
                                                        .sub_child_categories
                                                        .length > 0 ? (
                                                        <ul>
                                                          {childCategory.sub_child_categories.map(
                                                            (
                                                              subChildCategory,
                                                              subChildCategoryIndex
                                                            ) => (
                                                              <li
                                                                key={
                                                                  subChildCategoryIndex
                                                                }
                                                                onClick={() => {
                                                                  getProductByCategoryId(
                                                                    subChildCategory.id
                                                                  );
                                                                }}
                                                              >
                                                                {
                                                                  subChildCategory.name
                                                                }
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      ) : (
                                                        <ul>
                                                          {category.child_categories.map(
                                                            (
                                                              subChildCategory,
                                                              subChildCategoryIndex
                                                            ) => (
                                                              <li
                                                                key={
                                                                  subChildCategoryIndex
                                                                }
                                                                onClick={() => {
                                                                  getProductByCategoryId(
                                                                    subChildCategory.id
                                                                  );
                                                                }}
                                                              >
                                                                {
                                                                  subChildCategory.name
                                                                }
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      )}
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                )
                                              )}
                                          </Accordion>
                                        ) : (
                                          <ul>
                                            {model.categories &&
                                              model.categories.length > 0 &&
                                              model.categories.map(
                                                (
                                                  subChildCategory,
                                                  subChildCategoryIndex
                                                ) => (
                                                  <li
                                                    key={subChildCategoryIndex}
                                                    onClick={() => {
                                                      getProductByCategoryId(
                                                        subChildCategory.id
                                                      );
                                                    }}
                                                  >
                                                    {subChildCategory.name}
                                                  </li>
                                                )
                                              )}
                                          </ul>
                                        )}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )
                                )}
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <TreeSkel />
          )}
        </Accordion>
      </div>
    </>
  );
}
