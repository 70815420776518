import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { findCartItemAction } from "../../redux-service/actions/action";
import IsAuthenticated from "../auth-check/IsAuthenticated";
import { CartService } from "../../services/CartService";
import { JsonData } from "../../../JsonData";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

export default function BuyNowBtn({ classText, value }) {
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addToCartHandler = async () => {
    if (!value) return;

    const NewCartData = {
      cart_customer_id: userID,
      cart_product_id: value?._id,
      cart_product_price: value?.product_price,
      cart_product_quantity: value?.product_quantity,
    };

    try {
      const getData = await new CartService().itemAddToCartList(NewCartData);
      const getDataResult = getData?.data;
      if (getDataResult) {
        toast.success(getDataResult.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });
        if (userID) {
          navigate("/order/checkout");
          dispatch(findCartItemAction(userID));
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.addToCart + " " + error);
    }
  };

  if (!value) {
    return null;
  }

  if (!IsAuthenticated()) {
    return null;
  }

  if (value?.product_variants_status) {
    return null;
  }

  return (
    <>
      <button
        title="Buy Now"
        className={`btn btn-outline-primary ${classText && classText}`}
        onClick={addToCartHandler}
      >
        Buy Now
      </button>
    </>
  );
}
