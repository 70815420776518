/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerService } from "../../../services/CustomerService";
import AuthService from "../../../services/AuthService";
import { JsonData } from "../../../../JsonData";

export default function ResetPassword() {
  // eslint-disable-next-line no-unused-vars
  const [searchParam, setSearchParam] = useSearchParams();
  const userAuthToken = searchParam.get("token");

  const navigate = useNavigate();
  // <!----- state define ----------------------------------------!>
  const [inputData, setInputData] = useState({
    user_new_password: "",
    user_confirm_password: "",
  });

  const { user_new_password, user_confirm_password } = inputData;
  const [formErrors, setFormErrors] = useState({
    emailError: { status: false, message: "" },
    passError: { status: false, message: "" },
    passConfirmError: { status: false, message: "" },
    isActiveBtn: false,
  });

  const { passError, passConfirmError, isActiveBtn } = formErrors;

  // <!----- input handler ----------------------------------------!>
  function PasswordHandler(e) {
    setInputData({ ...inputData, user_new_password: e.target.value });
    let passRegEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\w]).{6,}$/;
    if (!passRegEx.test(e.target.value)) {
      setFormErrors({
        ...formErrors,
        passError: {
          status: true,
          message:
            "The password should contain Minimum 6 and Maximum 12 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet, 1 Number and 1 Special Character:",
        },
        isActiveBtn: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        passError: { status: false, message: "" },
        isActiveBtn: false,
      });
    }
  }

  function ConfirmPasswordHandler(e, value) {
    setInputData({ ...inputData, user_confirm_password: e.target.value });
    if (user_new_password !== e.target.value) {
      setFormErrors({
        ...formErrors,
        passConfirmError: {
          status: true,
          message: "The Password Not Match With Confirm Password",
        },
        isActiveBtn: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        passConfirmError: { status: false, message: "" },
        isActiveBtn: false,
      });
    }
  }

  // <!----- api call ----------------------------------------!>
  const resetPassApiHandler = async () => {
    try {
      const resp = await new CustomerService().resetPasswordCustomer(
        userAuthToken,
        {
          user_new_password,
        }
      );
      if (resp.data.status === true) {
        toast.success(resp.data.message, {
          icon: <i className="fa-solid fa-check"></i>,
        });
        AuthService.loginNavigate(navigate);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.resetPass + " " + error);
    }
  };

  async function VerifyTokenHadnler() {
    try {
      const response = await new CustomerService().forgetTokenVerifyCustomer(
        userAuthToken
      );
      if (response?.data?.status) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-check"></i>,
        });
      } else {
        toast.warning(response?.data?.message, {
          icon: <i className="fa-solid fa-triangle-exclamation"></i>,
        });
        AuthService.forgetPasswordNavigate(navigate);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.verify + " " + error);
      AuthService.forgetPasswordNavigate(navigate);
    }
  }

  useEffect(() => {
    VerifyTokenHadnler();
  }, [userAuthToken]);

  // <!----- form handler ----------------------------------------!>
  function formHandler(e) {
    e.preventDefault();
    resetPassApiHandler();
  }

  return (
    <>
      <Helmet>
        <title>Reset Password - Pass Tooling</title>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-xl-4">
          <div className="form-box">
            <h1 className="h2 text-center mb-4">Reset your Password</h1>
            <form onSubmit={formHandler}>
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="new-password">New Password</label>
                  {passError.status ? (
                    <small className="error">{passError.message}</small>
                  ) : null}
                  <input
                    value={user_new_password}
                    name="user_new_password"
                    id="new-password"
                    className="form-control"
                    onChange={PasswordHandler}
                  />
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="comfirm-password">Confirm Password</label>
                  {passConfirmError.status ? (
                    <small className="error">{passConfirmError.message}</small>
                  ) : null}
                  <input
                    value={user_confirm_password}
                    name="user_confirm_password"
                    id="comfirm-password"
                    className="form-control"
                    onChange={ConfirmPasswordHandler}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center flex-column flex-sm-row gap-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isActiveBtn ? " " : ""}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
