/* eslint-disable array-callback-return */
import React from "react";
import { useDispatch } from "react-redux";
import RemoveToCartBtn from "../button/RemoveToCartBtn";
import { SERVER_URL, imageBaseUrl } from "../../config/Config";
import { useNavigate } from "react-router-dom";
import { headerCartMenuCloseAction } from "../../redux-service/actions/action";
import CartVariableTable from "./CartVariableTable";

export default function CartVariable({ value, index }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToproduct = (slug, id) => {
    navigate(`/${slug}/${id}`);
    dispatch(headerCartMenuCloseAction());
  };

  if (!value) {
    return null;
  }

  return (
    <>
      <tr className="remove-first" key={`cart_tr_space_${value?._id}`}>
        <td className="p-0" colSpan={10}></td>
      </tr>
      <tr
        key={`cart_${value?._id}`}
        style={{ borderBottom: 0 }}
        className="hover-unset"
      >
        <td colSpan={3} style={{ borderBottom: 0 }}>
          <div className="cart-item">
            <div className="img">
              {value?.cart_product?.product_images[0] !== "" ? (
                <img
                  src={`${SERVER_URL}${value?.cart_product?.product_images[0]}`}
                  alt={value?.cart_product?.product_name}
                  className="img-fluid"
                />
              ) : (
                <img
                  src={`${imageBaseUrl}/default-image-150-100.png`}
                  alt="Cart default"
                  className="img-fluid"
                />
              )}
            </div>
            <div className="text">
              <b
                className="title"
                onClick={() =>
                  goToproduct(
                    value?.cart_product?.product_slug,
                    value?.cart_product_id
                  )
                }
              >
                {value?.cart_product?.product_name}
              </b>
              <p>
                <b>Selected Options: </b>{" "}
                {value?.cart_selected_product_variants?.length}
              </p>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-center gap-2">
            <RemoveToCartBtn
              cartItemID={value?._id}
              type="REMOVE_CART_ICON_2"
            />
          </div>
        </td>
      </tr>
      <CartVariableTable value={value} index={index} />
    </>
  );
}
