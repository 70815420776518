import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { MachineModelService } from "../../services/MachineModelService";

export default function KeywordMultipleMachineModelSelect({
  inputName,
  fetchChildData,
  selectedValues,
  machineMakeIds,
  required,
  labelHide,
}) {
  // state define
  const [selectValue, setSelectValue] = useState([]);
  const [machineModelDropdown, setMachineModelDropdown] = useState([]);

  // input handling
  const inputHandler = (e) => {
    const { value } = e.target;
    setSelectValue(typeof value === "string" ? value.split(",") : value);
  };

  // send data to parent
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function sendDataToParent() {
    if (inputName) {
      fetchChildData(inputName, selectValue); // Use the inputName as the key
    }
  }

  // This useEffect is triggered whenever the 'selectValue' changes.
  useEffect(() => {
    sendDataToParent();
  }, [inputName, fetchChildData, sendDataToParent]);

  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      setSelectValue(selectedValues);
    }
  }, [selectedValues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getApiServiceHandler() {
    if (machineMakeIds && machineMakeIds.length > 0) {
      new MachineModelService()
        .getSelectedMachineMakeDropdown({ machineMakeIds: machineMakeIds })
        .then((response) => {
          const result = response.data.result;
          if (response) {
            setMachineModelDropdown(result);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setMachineModelDropdown([]);
      setSelectValue([]);
    }
  }

  useEffect(() => {
    getApiServiceHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineMakeIds?.length]);

  return (
    <>
      {machineModelDropdown && (
        <>
          {!labelHide && (
            <label>Machine Model {required && <sup>*</sup>}</label>
          )}
          <FormControl sx={{ m: 1, width: 300 }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name={inputName && inputName}
              multiple
              value={selectValue}
              onChange={inputHandler}
              renderValue={(selected) => {
                const displayedValues =
                  selected.length > 0
                    ? selected
                        .slice(0, 2)
                        .map(
                          (selectedValue) =>
                            machineModelDropdown.find(
                              (item) => item.value === selectedValue
                            )?.name
                        )
                        .join(", ")
                    : "--- select machine models ---";

                if (selected.length > 2) {
                  return `${displayedValues} ...`;
                }

                return displayedValues;
              }}
              className="form-control-mui"
            >
              {machineModelDropdown.length > 0 ? (
                machineModelDropdown.map((value, index) => (
                  <MenuItem key={index} value={value.value}>
                    <Checkbox checked={selectValue.indexOf(value.value) > -1} />
                    <ListItemText primary={value.name} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <ListItemText primary="No Data Found" />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </>
      )}
    </>
  );
}
