import React from "react";
import IsAuthenticated from "../../auth-check/IsAuthenticated";
import { JsonData } from "../../../../JsonData";
import { NavLink } from "react-router-dom";

export default function CardVariantOptionPrice({ price }) {
  if (!price) return null;

  if (!IsAuthenticated())
    return (
      <NavLink
        to="/user/login"
        className="variant-info-price"
        title="Login to view price."
      >
        LVP
      </NavLink>
    );

  return (
    <>
      <b>
        <sup>{JsonData.CURRENCY_ICON}</sup>
        {price}
      </b>
    </>
  );
}
