import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import MenuList from "./MenuList";
import HeaderSearchMenu from "./HeaderSearchMenu";
import UserWishlist from "./UserWishlist";
import UserProfile from "./UserProfile";
import { useLocation } from "react-router-dom";
import UserCartMenu from "./UserCartMenu";

export default function Header() {
  let [activeMenu, setActiveMenu] = useState(false);
  let [activeHeader, setActiveHeader] = useState(false);

  const param = useLocation();
  const pathname = param.pathname;

  function menuToggle() {
    setActiveMenu(!activeMenu);
  }

  function menuClose() {
    setActiveMenu(false);
  }

  useEffect(() => {
    if (activeMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [activeMenu]);

  // <!----- new page open scroll to top ----------------------------------------!>
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let activeClass = true;
      if (window.scrollY === 0) {
        activeClass = false;
      }
      setActiveHeader(activeClass);
    });
  }, [pathname]);

  return (
    <>
      <header
        className={
          (activeHeader ? " active" : "") + (activeMenu ? " active-menu" : "")
        }
      >
        <div className="header-container">
          <div className="header-left">
            <Logo menuToggle={menuToggle} />
            {/* <MenuList menuToggle={menuToggle} menuClose={menuClose} /> */}
          </div>
          <div className="header-right">
            <HeaderSearchMenu />
            <UserWishlist />
            <UserProfile activeHeader={activeHeader} />
            <UserCartMenu data={{ menuOpen: true }} />
          </div>
        </div>
        <div
          className={"overlay" + (activeMenu ? " active-menu" : "")}
          onClick={menuToggle}
        ></div>
        {/* <div className="menubar-icon">
          <div className="menubar" onClick={menuToggle}>
            <span></span>
            <span></span>
          </div>
        </div> */}
      </header>
    </>
  );
}
