import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { MachineMakeService } from "../../services/MachineMakeService";

export default function KeywordMultipleMachineMakeSelect({
  inputName,
  fetchChildData,
  selectedValues,
  required,
  labelHide,
}) {
  // state define
  const [selectValue, setSelectValue] = useState([]);
  const [machineMakeDropdown, setMachineMakeDropdown] = useState([]);

  // input handling
  const inputHandler = (e) => {
    const { value } = e.target;
    setSelectValue(typeof value === "string" ? value.split(",") : value);
  };

  // send data to parent
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function sendDataToParent() {
    if (inputName) {
      fetchChildData(inputName, selectValue); // Use the inputName as the key
    }
  }

  // This useEffect is triggered whenever the 'selectValue' changes.
  useEffect(() => {
    sendDataToParent();
  }, [inputName, fetchChildData, sendDataToParent]);

  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      setSelectValue(selectedValues);
    }
  }, [selectedValues]);

  function getApiServiceHandler() {
    new MachineMakeService()
      .getMachineMakeDropdown()
      .then((response) => {
        const result = response.data.result;
        if (response) {
          setMachineMakeDropdown(result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getApiServiceHandler();
  }, []);

  return (
    <>
      {machineMakeDropdown && (
        <>
          {!labelHide && <label>Machine Make {required && <sup>*</sup>}</label>}
          <FormControl sx={{ m: 1, width: 300 }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name={inputName && inputName}
              multiple
              value={selectValue}
              onChange={inputHandler}
              renderValue={(selected) => {
                const displayedValues =
                  selected.length > 0
                    ? selected
                        .slice(0, 2)
                        .map(
                          (selectedValue) =>
                            machineMakeDropdown.find(
                              (item) => item.value === selectedValue
                            )?.name
                        )
                        .join(", ")
                    : "--- select machine makes ---";

                if (selected.length > 2) {
                  return `${displayedValues} ...`;
                }

                return displayedValues;
              }}
              className="form-control-mui"
            >
              {machineMakeDropdown.length > 0 ? (
                machineMakeDropdown.map((value, index) => (
                  <MenuItem key={index} value={value.value}>
                    <Checkbox checked={selectValue.indexOf(value.value) > -1} />
                    <ListItemText primary={value.name} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <ListItemText primary="No Data Found" />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </>
      )}
    </>
  );
}
