import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { imageBaseUrl, SERVER_URL } from "../../config/Config";
import WishListBtn from "../button/WishListBtn";
import AddToCartBtn from "../button/AddToCartBtn";
import WishListSkel from "../skeleton/WishListSkel";
import BuyNowBtn from "../button/BuyNowBtn";
import CardSortText from "./helper/CardSortText";
import CardPrice from "./helper/CardPrice";

export default function WishListCard({ value }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <>
      {loaded ? (
        <div className="card card4 flex-row justify-content-between">
          <NavLink to={value.url}>
            <div className="card-image">
              <img
                src={
                  value.product_images[0] !== ""
                    ? `${SERVER_URL}${value.product_images[0]}`
                    : `${imageBaseUrl}/product-default-1.png`
                }
                alt={value.product_name}
                className="img-fluid"
              />
            </div>
          </NavLink>
          <div className="card-body d-flex flex-row justify-content-between">
            <div className="grid-item">
              <small className="tag">{value.product_sku}</small>
              <h2>{value.product_name}</h2>
              <CardSortText text={value.product_description} textLength={120} />
              <CardPrice value={value} />
            </div>
            <div className="grid-item d-flex flex-column gap-3">
              <BuyNowBtn
                cartData={{
                  cart_product_id: value._id,
                  cart_product_price: value.product_price,
                  cart_product_quantity: 1,
                }}
              />
              <AddToCartBtn
                value={value}
                type="ADD_CART_TOGGLE"
              />
              <WishListBtn productID={value._id} type="REMOVE_WISHLIST" />
            </div>
          </div>
        </div>
      ) : (
        <WishListSkel />
      )}
    </>
  );
}
