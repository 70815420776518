/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import { JsonData } from '../../JsonData';
import { PageService } from '../services/PageService';

export default function Block({ pageId, pageageType, customBlock }) {
  const [inputData, setInputData] = useState(null);
  const BlockLists = inputData && inputData.page_component_list;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function GetApiService() {
    if (pageId) {
      new PageService()
        .showPage(pageId)
        .then((response) => {
          const result = response.data.result[0];
          if (result) {
            setInputData(result);
          }
        })
        .catch((error) => {
          console.error(JsonData?.messages?.errors?.read, error);
        });
    }
  }

  useEffect(() => {
    GetApiService();
  }, [pageId]);

  return (
    <>
      <main id={pageageType}>
        {BlockLists &&
          BlockLists.sort((a, b) => a.cmp_block_order - b.cmp_block_order).map((cmpValue, cmpIndex) => {
            const matchingBlock = JsonData.cmpBlockData.find(
              (block) => block.cmp_block_id === cmpValue.cmpBlock_id
            );

            if (matchingBlock) {
              const BlockComponent = matchingBlock.cmp_block_block;
              return <BlockComponent key={cmpIndex} blockData={cmpValue} />;
            }

            return null;
          })}
      </main>
    </>
  );
}