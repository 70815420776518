import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";
import AuthService from "./AuthService";

export class WishlistService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();
    this.endpoints = {
      UPDATE_WISHLIST: "/wishlist/update/:userId",
      DELETE_WISHLIST: "/wishlist/:userId/:itemId",
      GET_USER_WISHLIST: "/wishlist/:userId",
    };
    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.token_config = {
      headers: {
        authorization: `${this.authorizationToken}`,
      },
    };
  }

  async updateWishlist(id, payload) {
    try {
      const response = await axios.put(
        this.endpoints.UPDATE_WISHLIST.replace(":userId", id),
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getUserWishlist(id) {
    try {
      const response = await axios.get(
        this.endpoints.GET_USER_WISHLIST.replace(":userId", id),
        this.token_config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async deleteWishlist(userId, productId) {
    try {
      const response = await axios.delete(
        this.endpoints.DELETE_WISHLIST.replace(":userId", userId).replace(
          ":itemId",
          productId
        )
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
