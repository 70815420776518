import React from "react";

export default function PageIntro(props) {
  const data = props.data;

  return (
    <>
      <section className="section-padding">
        <div className="custom-container inner-page-intro">
          <div className="grid-item">
            <h2 className="intro-heading highlight-top sm">
              {data && data.heading}
            </h2>
          </div>
          <div className="grid-item">{data && data.text}</div>
        </div>
      </section>
    </>
  );
}
