import React, { useEffect } from "react";
import NotificationMessage from "../../../components/cards/NotificationMessage";
import AuthService from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";

export default function RegisterSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      AuthService.loginNavigate(navigate);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NotificationMessage
        type="success"
        icon={<i className="fa-solid fa-envelope-circle-check"></i>}
        message="Verification email sent. Please check your email."
        redicate={true}
      />
    </>
  );
}
