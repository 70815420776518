import React from "react";
import { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SectionHeading1 from "../components/heading/SectionHeading1";
import ProductCard3 from "../components/cards/ProductCard3";
import NotFoundProduct from "../components/data-loader/NotFoundProduct";
import { NavLink } from "react-router-dom";
import { ProductCategoryService } from "../services/ProductCategoryService";
import { JsonData } from "../../JsonData";

export default function ProductCategoriesBlock({ blockData }) {
  const [productCateData, setProductCateData] = useState([]);

  const getApiHandler = async () => {
    try {
      const response = await new ProductCategoryService().getCategoryMainList();
      const result = response.data.result;
      
      if (result && result.length > 0) {
        setProductCateData(result);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  useEffect(() => {
    getApiHandler();
  }, []);

  if (!blockData) {
    return null;
  }

  return (
    <>
      <section className="section-padding bg-shadow-lr bg-shadow-white overflow-hidden">
        <div className="custom-container">
          <div className="row align-items-center mb-25-50">
            <div className="col-lg-8">
              <SectionHeading1
                className="mb-0"
                data={{
                  heading: (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blockData?.cmpBlock_product_categories_heading,
                      }}
                    ></div>
                  ),
                }}
              />
            </div>
            {blockData?.cmpBlock_product_categories_button_title && (
              <div className="col-lg-4 d-none d-lg-flex justify-content-end">
                <NavLink
                  to={blockData?.cmpBlock_product_categories_button_url}
                  className="btn btn-primary"
                >
                  {blockData?.cmpBlock_product_categories_button_title}
                </NavLink>
              </div>
            )}
          </div>
          {productCateData && productCateData.length > 0 ? (
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={15}
              breakpoints={{
                576: {
                  width: 576,
                  slidesPerView: 1.5,
                  spaceBetween: 15,
                },
                768: {
                  width: 768,
                  slidesPerView: 2.3,
                  spaceBetween: 25,
                },
                992: {
                  width: 992,
                  slidesPerView: 2.8,
                  spaceBetween: 25,
                },
                1200: {
                  width: 1200,
                  slidesPerView: 3.5,
                  spaceBetween: 50,
                },
                1400: {
                  width: 1400,
                  slidesPerView: 3.5,
                  spaceBetween: 50,
                },
                1680: {
                  width: 1680,
                  slidesPerView: 4.5,
                  spaceBetween: 50,
                },
              }}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
              id="product-swiper"
            >
              {productCateData &&
                productCateData.map((value, index) => (
                  <SwiperSlide key={index}>
                    <ProductCard3
                      path={`/product/${value.category_slug}/${value._id}`}
                      value={value}
                      index={index}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <NotFoundProduct data={{ heading: "No Categories" }} />
          )}
          {blockData?.cmpBlock_product_categories_button_title && (
            <div className="col-12 d-flex d-lg-none justify-content-end mt-4">
              <NavLink
                to={blockData?.cmpBlock_product_categories_button_url}
                className="btn btn-primary"
              >
                {blockData?.cmpBlock_product_categories_button_title}
              </NavLink>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
