import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";
import AuthService from "./AuthService";

export class MachineMakeService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();

    this.endpoints = {
      DROPDOWN: "/product/keyword/public/machine-make/dropdown", // public
    };
  }

  async getMachineMakeDropdown() {
    try {
      const response = await axios.get(this.endpoints.DROPDOWN);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
