import React from 'react'
import { imageBaseUrl } from '../../config/Config'

export default function PaymentMethodLoading() {
  return (
    <>
    <div className='d-flex justify-content-center py-4'>
        <img src={`${imageBaseUrl}/animation-loading.gif`} alt="" className='img-fluid' width={120} height={120} />
    </div>
    </>
  )
}
