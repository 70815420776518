import React, { useEffect, useState } from "react";
import { SERVER_URL, imageBaseUrl } from "../../../../../config/Config";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import CardVariantPrice from "../../../../cards/helper/CardVariantPrice";
import VariantAddToCart from "./helper/VariantAddToCart";
import VariantPopUpModal from "./helper/VariantPopUpModal";
import VariantSelector from "./VariantSelector";
import { useSelector } from "react-redux";
import {
  getCartVariantHelper,
  showMeasurement,
  sortTextHandler,
} from "../../../../helper/Helper";

export default function VariantRow({
  value,
  variantValue,
  variantIndex,
  productVariant,
  activeKey,
  handleAccordion,
  error,
  onError = () => {},
}) {
  // for button disabled
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isMeasurementDisabled, setIsMeasurementDisabled] = useState(false);
  const [isAccordionDisabled, setIsAccordionDisabled] = useState(false);

  // for img popUp
  const [isOpenImg, setIsOpenImg] = useState(false);
  const [isImg, setIsImg] = useState("");

  const handleImgToggle = () => {
    setIsImg("");
    setIsOpenImg(!isOpenImg);
  };

  // for filter product variant option
  const [variantOptionsList, setVariantOptionsList] = useState([]);

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const getCartVariant = getCartVariantHelper(
    getCartData,
    value?._id,
    variantValue?._id
  );

  useEffect(() => {
    const uniqueOptions = [];

    productVariant &&
      productVariant.forEach((variant) => {
        const options = variant.variant_options.map(
          (option) => option.variant_options_name
        );
        options.forEach((option) => {
          if (!uniqueOptions.includes(option)) {
            uniqueOptions.push(option);
          }
        });
      });

    setVariantOptionsList(uniqueOptions);
  }, [productVariant]);

  // for render image
  const renderImage = (variantValue) => {
    const imageUrl =
      variantValue?.variant_image !== ""
        ? `${SERVER_URL}${variantValue?.variant_image}`
        : `${imageBaseUrl}/default-image-150-100.png`;

    const imgClass = variantValue?.variant_image === "" && `default-img`;

    return (
      <div
        className={`product-info-box-small-image ${imgClass}`}
        onClick={() => {
          setIsOpenImg(true);
          setIsImg(imageUrl);
        }}
      >
        <img src={imageUrl} alt={value?.variant_name} className="img-fluid" />
      </div>
    );
  };

  // useEffect(() => {
  //   if (!getCartVariant.isInCart) {
  //     if (
  //       (variantValue._id === error.id && error.message) ||
  //       variantValue?.variant_measurement_selected === undefined
  //     ) {
  //       setIsBtnDisabled(true);
  //     } else {
  //       setIsBtnDisabled(false);
  //     }
  //   } else {
  //     setIsBtnDisabled(true);
  //   }
  // }, [
  //   error.id,
  //   error.message,
  //   getCartVariant.isInCart,
  //   variantValue._id,
  //   variantValue?.variant_measurement_selected,
  // ]);

  useEffect(() => {
    if (!getCartVariant.isInCart) {
      if (
        (variantValue._id === error.id && error.message) ||
        variantValue?.variant_measurement_selected === undefined
      ) {
        setIsBtnDisabled(true);
      } else {
        setIsBtnDisabled(false);
      }
    } else {
      setIsBtnDisabled(true);
    }
  }, [
    error.id,
    error.message,
    getCartVariant.isInCart,
    variantValue._id,
    variantValue?.variant_measurement_selected,
  ]);

  useEffect(() => {
    const { mm_status: mmStatus = false, in_status: inStatus = false } =
      variantValue.variant_measurement || {};

    const isMeasurementDisabled =
      !variantValue.variant_measurement || (!mmStatus && !inStatus);

    setIsMeasurementDisabled(isMeasurementDisabled);
    setIsBtnDisabled(!isMeasurementDisabled);
  }, [variantValue.variant_measurement, getCartVariant.isInCart]);

  useEffect(() => {
    const { mm_status: mmStatus = false, in_status: inStatus = false } =
      variantValue.variant_measurement || {};
    const isVariantOptions = variantValue.variant_options.length > 0;

    const isAccordionDisabled = !mmStatus && !inStatus && !isVariantOptions;

    setIsAccordionDisabled(isAccordionDisabled);
  }, [variantValue.variant_measurement, variantValue.variant_options, getCartVariant.isInCart]);

  return (
    <>
      {/* <pre>{JSON.stringify(variantValue, null, 1)}</pre> */}
      <div className="product-variant-group">
        <div className="product-variant-grid">
          <div className="grid-item mobile">
            <span className="body-tag">Type</span>
          </div>
          <div className="grid-item justify-content-start">
            <div className="product-info-box-small">
              {renderImage(variantValue)}
              <div className="product-info-box-small-text">
                <p>
                  <b>{sortTextHandler(variantValue?.variant_name, 12)}</b>
                </p>
                <CardVariantPrice price={variantValue?.variant_price} />
              </div>
            </div>
          </div>
          <div className="grid-item mobile">
            <span className="body-tag">Part No.</span>
          </div>
          <div className="grid-item">
            <p>{variantValue?.variant_sku}</p>
          </div>
          <div className="grid-item mobile">
            <span className="body-tag">Measurement (MM)</span>
          </div>
          <div className="grid-item">
            {showMeasurement(variantValue?.variant_measurement, "mm")}
          </div>
          <div className="grid-item mobile">
            <span className="body-tag">Measurement (IN)</span>
          </div>
          <div className="grid-item">
            {showMeasurement(variantValue?.variant_measurement, "in")}
          </div>
          <div className="grid-item mobile">
            <span className="body-tag">Action</span>
          </div>
          <div className="grid-item">
            <div className="product-variant-action-btn">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-view`}>
                    {isAccordionDisabled
                      ? "No option found"
                      : "Choose this option"}
                  </Tooltip>
                }
              >
                <button
                  type="button"
                  className={`sm-icon sm ${
                    activeKey === variantValue._id &&
                    variantValue._id.toString() &&
                    "active"
                  }`}
                  onClick={() =>
                    handleAccordion(
                      variantValue._id && variantValue._id.toString()
                    )
                  }
                  disabled={isAccordionDisabled}
                >
                  <i className="fa-solid fa-chevron-down"></i>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-arrow`}>View variant details</Tooltip>
                }
              >
                <VariantPopUpModal variantValue={variantValue} />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-plus`}>Add Variant to cart</Tooltip>
                }
              >
                <VariantAddToCart
                  value={value}
                  variantValue={variantValue}
                  isDisabled={isBtnDisabled}
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <Accordion.Item
          eventKey={variantValue._id && variantValue._id.toString()}
          className="product-variant-accordion"
        >
          <Accordion.Body>
            <VariantSelector
              variantOptionsList={variantOptionsList}
              value={value}
              variantValue={variantValue}
              variantIndex={variantIndex}
              isMeasurementDisabled={isMeasurementDisabled}
              error={error}
              onError={onError}
            />
          </Accordion.Body>
        </Accordion.Item>
      </div>
      {isOpenImg && (
        <div className="image-view-fixed">
          <div className="image-view-box">
            {isImg ? (
              <img src={isImg} alt="" className="img-fluid" />
            ) : (
              <img
                src={`${imageBaseUrl}/product-default-1.png`}
                alt=""
                className="img-fluid"
              />
            )}
            <button type="button" className="sm-icon" onClick={handleImgToggle}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="image-view-overlay" onClick={handleImgToggle}></div>
        </div>
      )}
    </>
  );
}
