import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardVariantOptionPrice from "../../../../../cards/helper/CardVariantOptionPrice";
import {
  getCartVariantHelper,
  getCartVariantOptionHelper,
  getDefaultVariantOptionHelper,
  showNameAndPriceForVariantOption,
} from "../../../../../helper/Helper";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function VariantOptionInputHandle({
  value,
  variantValue,
  variantOptionValue,
  isDisabled = false,
  onResult = () => {},
}) {
  const [variantOptionChildInput, setVariantOptionChildInput] = useState("");

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const getCartVariant = getCartVariantHelper(
    getCartData,
    value?._id,
    variantValue?._id
  );

  const getCartVariantOption = getCartVariantOptionHelper(
    getCartData,
    value?._id,
    variantValue?._id,
    variantOptionValue?.variant_options_id
  );

  const getDefaultVariantOption = getDefaultVariantOptionHelper(
    variantOptionValue.variant_options_lists
  );

  function inputHandler(e) {
    const { value } = e.target;

    if (value) {
      const [name, price] = value.split(" :- ");
      const nameAndPrice = `${name} :- ${price}`;

      setVariantOptionChildInput(nameAndPrice);

      const payload = {
        variant_options_id: variantOptionValue.variant_options_id,
        variant_options_name: variantOptionValue.variant_options_name,
        variant_options_child_name: name.trim(),
        variant_options_child_price: Number(price.trim()),
      };

      onResult(payload);
    } else {
      setVariantOptionChildInput("");
    }
  }

  useEffect(() => {
    if (getCartVariantOption?.isInCart) {
      const { variant_options_child_name, variant_options_child_price } =
        getCartVariantOption?.data;
      const nameAndPrice = `${variant_options_child_name} :- ${variant_options_child_price}`;
      setVariantOptionChildInput(nameAndPrice);
    } else if (getDefaultVariantOption?.isDefault) {
      const { variant_options_child_name, variant_options_child_price } =
        getDefaultVariantOption?.data;
      const nameAndPrice = `${variant_options_child_name} :- ${variant_options_child_price}`;
      setVariantOptionChildInput(nameAndPrice);
    } else {
      setVariantOptionChildInput("");
    }
  }, [
    getCartVariantOption?.data,
    getDefaultVariantOption?.isDefault,
    getCartVariantOption?.isInCart,
    getDefaultVariantOption?.data,
  ]);

  return (
    <>
      <label
        htmlFor={`variant_option_input_${variantOptionValue.variant_options_id}`}
      >
        {variantOptionValue.variant_options_link === 5 ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-view`}>
                  Click for more info: {variantOptionValue.variant_options_name}
                </Tooltip>
              }
            >
              <NavLink to={variantOptionValue.variant_options_link}>
                {variantOptionValue.variant_options_name}
                <i className="fa-solid fa-circle-info"></i>
              </NavLink>
            </OverlayTrigger>
          </>
        ) : (
          variantOptionValue.variant_options_name
        )}
      </label>
      <div className="select-wrapper fix-input-width">
        <select
          className="form-control"
          value={variantOptionChildInput}
          id={`variant_option_input_${variantOptionValue.variant_options_id}`}
          onChange={inputHandler}
          disabled={isDisabled}
        >
          <option value="">--- Select ---</option>
          {variantOptionValue.variant_options_lists &&
            variantOptionValue.variant_options_lists.map(
              (variantOptionListValue, index) => (
                <option
                  key={`option_${index}`}
                  value={`${variantOptionListValue.variant_options_child_name} :- ${variantOptionListValue.variant_options_child_price}`}
                >
                  {showNameAndPriceForVariantOption(
                    variantOptionListValue.variant_options_child_name,
                    variantOptionListValue.variant_options_child_price
                  )}
                </option>
              )
            )}
        </select>
      </div>
    </>
  );
}
