import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CustomerService } from "../../../services/CustomerService";
import AuthService from "../../../services/AuthService";
import { JsonData } from "../../../../JsonData";
import { useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    user_email: "",
  });

  const { user_email } = inputData;

  function inputHandler(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  async function forgetPassApiHandler() {
    try {
      const resp = await new CustomerService().forgetPasswordCustomer({
        user_email,
      });
      const resultData = resp?.data;
      if (resultData.status) {
        toast.success(resultData.message, {
          icon: <i className="fa-solid fa-paper-plane"></i>,
        });
        AuthService.loginNavigate(navigate);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.forgetPass + " " + error);
    }
  }

  function formHandler(e) {
    e.preventDefault();
    forgetPassApiHandler();
  }

  return (
    <>
      <Helmet>
        <title>Forget Password - ShotCart</title>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-xl-4">
          <div className="form-box">
            <h1 className="h2 text-center mb-4">Forget Password</h1>
            <form onSubmit={formHandler}>
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="user_email">Email</label>
                  <input
                    value={user_email}
                    name="user_email"
                    id="user_email"
                    className="form-control"
                    onChange={inputHandler}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center flex-column flex-sm-row gap-3">
                  <button type="submit" className="btn btn-primary">
                    Request Reset Link
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
