import React from "react";
import { NavLink } from "react-router-dom";
import { imageBaseUrl, SERVER_URL } from "../../config/Config";
import AddToCartBtn from "../button/AddToCartBtn";
import WishListBtn from "../button/WishListBtn";
import ProductSkel2 from "../skeleton/ProductSkel2";
import CardPrice from "./helper/CardPrice";
import CardSortText from "./helper/CardSortText";

export default function ProductCard2({ value }) {
  if (!value) {
    return <ProductSkel2 />;
  }

  const renderImage = () => {
    const imageUrl =
      value.product_images &&
      value.product_images.length > 0 &&
      value.product_images[0] !== ""
        ? `${SERVER_URL}${value.product_images[0]}`
        : `${imageBaseUrl}/product-default-1.png`;

    return (
      <img src={imageUrl} alt={value.product_name} className="img-fluid" />
    );
  };

  return (
    <div className="card product-card-2">
      <NavLink to={`/${value.product_slug}/${value._id}`}>
        <div className="card-image">{renderImage()}</div>
      </NavLink>
      <div className="card-body">
        <div>
          <div className="card-heading">
            <h3>{value.product_name}</h3>
            <CardPrice value={value} />
          </div>
          <CardSortText text={value.product_description} textLength={120} />
        </div>
        <AddToCartBtn classText="mt-3" value={value} type="ADD_CART_TOGGLE" />
      </div>
      <WishListBtn productID={value._id} type="ADD_WISHLIST_TOGGLE" />
    </div>
  );
}