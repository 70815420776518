import React, { useEffect, useState } from "react";
import { imageBaseUrl } from "../../config/Config";
import { useLocation } from "react-router-dom";

export default function PageLoader2() {
  const params = useLocation();
  const pathname = params.pathname;

  let [loader, setLoader] = useState(true);
  let [loaderActive, setLoaderActive] = useState(false);
  let [successAnimation, setSuccessAnimation] = useState(false);

  function loaderHandler() {
    setLoader(true);
    setLoaderActive(false);
    setSuccessAnimation(false);

    const activationDelay = 1000;
    const fadeOutDuration = 800;
    const successAnimationDelay = 500; // Add delay for the success animation

    setTimeout(() => {
      setLoaderActive(true);
      setTimeout(() => {
        setLoader(false);
        setTimeout(() => {
          setSuccessAnimation(true);
        }, successAnimationDelay);
      }, fadeOutDuration);
    }, activationDelay);
  }

  useEffect(() => {
    const onPageLoad = () => {
      loaderHandler();
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, [pathname]);

  if (successAnimation) {
    return null;
  }

  return (
    <div
      className={`page-loader-wrapper${loaderActive ? " active" : ""}${
        !loader ? " loading" : ""
      }`}
    >
      <div className={`page-loader-box2`}>
        <img
          src={`${imageBaseUrl}/logo.png`}
          alt="logo"
          className="img-fluid"
        />
      </div>
    </div>
  );
}
