import React from "react";
import CardSortText from "../../../cards/helper/CardSortText";
import CardPrice from "../../../cards/helper/CardPrice";
import AddToCartBtn from "../../../button/AddToCartBtn";
import BuyNowBtn from "../../../button/BuyNowBtn";
import IsAuthenticated from "../../../auth-check/IsAuthenticated";
import Accordion from "react-bootstrap/Accordion";
import VariantWrapper from "./variant-table/VariantWrapper";

export default function ProductDetailsCol({ singleProductData }) {
  return (
    <>
      <div className="product-details">
        <h2>{singleProductData?.product_name}</h2>
        <CardSortText text={singleProductData?.product_description} />
        <hr />
        <CardPrice value={singleProductData} className="price-lg" />
        {singleProductData?.product_variants_status && (
          <Accordion defaultActiveKey={["0", "1"]} className="accordion-1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Product Variants
                <div className="accordion-oc-icon">
                  <i className="fa-solid fa-plus"></i>
                  <i className="fa-solid fa-minus"></i>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <VariantWrapper value={singleProductData} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {!singleProductData?.product_variants_status && (
          <>
            <hr />
            <div className="d-flex gap-3">
              <AddToCartBtn value={singleProductData} type="ADD_CART_TOGGLE" />
              <BuyNowBtn value={singleProductData} />
            </div>
          </>
        )}
      </div>
    </>
  );
}
