import { toast } from "react-toastify"; // Notification popup show
import { useDispatch, useSelector } from "react-redux";
import { findCartItemAction } from "../../redux-service/actions/action";
import { useEffect, useState } from "react";
import { CartService } from "../../services/CartService";
import { JsonData } from "../../../JsonData";
import secureLocalStorage from "react-secure-storage";

export default function QtyPlusMinusBtn({ value, size }) {
  const [qty, setQty] = useState(1);
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const dispatch = useDispatch();
  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );
  const filteredCartData = getCartData.filter(
    (ele) =>
      ele.cart_customer_id === userID &&
      ele.cart_product_id === value.cart_product_id
  );

  const qtyPlusMinusCartHandler = async (newQty) => {
    if (!value) return;

    try {
      const payload = {
        cartId: value._id,
        cart_product_quantity: newQty,
      };

      const getData = await new CartService().itemQtyPlusMinusToCartList(payload);
      const getDataResult = getData?.data;

      if (getDataResult) {
        toast.success(getDataResult.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });

        if (userID) {
          dispatch(findCartItemAction(userID));
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.qtyPlusMinus + " " + error);
    }
  };

  const qtyIncreaseHandler = () => {
    const newQty = qty + 1;
    setQty(newQty);
    qtyPlusMinusCartHandler(newQty);
  };

  const qtyDecreaseHandler = () => {
    const newQty = qty - 1;
    if (qty > 1) {
      setQty(newQty);
      qtyPlusMinusCartHandler(newQty);
    }
  };

  useEffect(() => {
    if (filteredCartData && filteredCartData.length > 0) {
      setQty(filteredCartData[0].cart_product_quantity);
    }
  }, [value, filteredCartData]);

  return (
    <>
      <div className={`qty-button qty-button-${size}`}>
        <button title="Quantity Decrease" onClick={qtyDecreaseHandler}>
          <i className="fa-solid fa-minus"></i>
        </button>
        {qty}
        <button title="Quantity Increase" onClick={qtyIncreaseHandler}>
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
    </>
  );
}
