import React from "react";
import { MAIN_APP_URL } from "../../../config/Config";

export default function PrivacyPolicy() {
  return (
    <>
      <main className="custom-container">
        <section className="py-4">
          <h1 className="mb-4">Privacy Policy</h1>
          <p>
            We value your privacy at&nbsp;
            <a href={MAIN_APP_URL} target="_blank" rel="noreferrer">
              www.passtooling.com
            </a>
            . Here's a brief overview of how we handle your information:
          </p>
          <ul>
            <li>
              <b>Information We Collect:</b> We collect essential information
              like your name, email, billing address, and payment details to
              process your orders securely.
            </li>
            <li>
              <b>Use of Information:</b> Your data is used for order processing,
              customer support, and improving our services.
            </li>
            <li>
              <b>Data Security:</b> We employ robust security measures to
              protect your information from unauthorized access or misuse.
            </li>
            <li>
              <b>Third-Party Services:</b> We may use third-party services to
              enhance our website and services, ensuring they adhere to strict
              privacy standards.
            </li>
            <li>
              <b>Cookies:</b> We may use cookies to enhance your browsing
              experience, but you can adjust your browser settings to manage
              them.
            </li>
            <li>
              <b>Privacy Rights:</b> You have the right to access, update, or
              delete your personal information. Contact us for assistance.
            </li>
            <li>
              <b>Policy Changes:</b> We may update our Privacy Policy
              occasionally. Check back for any changes.
            </li>
          </ul>
          <p>Questions? Reach out to us using the contact form.</p>
          <p>Thank you for trusting us with your information.</p>
          <p>
            <b>Date:</b> 3/6/24 <br /> <b>Pass Tooling, LLC</b> <br />
            <a href={MAIN_APP_URL} target="_blank" rel="noreferrer">
              www.passtooling.com
            </a>
          </p>
        </section>
      </main>
    </>
  );
}
