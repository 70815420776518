import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/style.css";
import ScrollTop from "./components/ScrollTop";
import Header from "./components/header/Header";
import RoutesRoot from "./RoutesRoot";
import Footer from "./components/footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader2 from "./components/data-loader/PageLoader2";
import { CustomerService } from "./services/CustomerService";
import AuthService from "./services/AuthService";
import { JsonData } from "../JsonData";
import runInterceptor from "./interceptor";
import secureLocalStorage from "react-secure-storage";
import { useDispatch, useSelector } from "react-redux";
import {
  findWishlistItemAction,
  setUserDataAction,
} from "./redux-service/actions/action";

export default function MainIndex() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userReducer.userData);
  const userID = AuthService.getUserId();

  // Function to check if the user data exists, if not, log out
  const findUserhandler = async () => {
    try {
      const resp = await new CustomerService().showCustomer(userID);
      const result = resp?.data;
      if (result.result && result?.result?.length === 0) {
        AuthService.logOutNavigate(navigate);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  useEffect(() => {
    // Initialize the axios interceptor to hit on every AJAX call
    runInterceptor();
  }, []);

  // Effect to check user data when the component mounts or userID changes
  useEffect(() => {
    if (userID) {
      findUserhandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  useEffect(() => {
    if (userID) {
      dispatch(setUserDataAction(userID)); // Dispatch the action to fetch user data
      dispatch(findWishlistItemAction(userID));
    }
  }, [dispatch, userID, location.pathname]);

  return (
    <>
      <PageLoader2 />
      <ScrollTop />
      <Header />
      <RoutesRoot />
      <Footer />
    </>
  );
}
