import React, { useEffect, useState } from "react";
import OrderCheckoutTotalView from "../../components/pages/checkout/OrderCheckoutTotalView";
import { useDispatch, useSelector } from "react-redux";
import {
  headerCartMenuToggleAction,
  setUserDataAction,
} from "../../redux-service/actions/action";
import { Nav, Tab } from "react-bootstrap";
import AuthService from "../../services/AuthService";
import CustomerDetailsForm from "../../components/checkout/customer-details/CustomerDetailsForm";
import PaymentMethod from "../../components/checkout/payment-method/PaymentMethod";
import { useNavigate } from "react-router-dom";

export default function OrderCheckout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userID = AuthService.getUserId();
  const userData = useSelector((state) => state.userReducer.userData);
  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );
  const [isAddress, setIsAddress] = useState(false);

  const [activeKey, setActiveKey] = useState("checkout-tab-1");

  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTabNavigation = (tabNumber) => {
    handleTabSelect(`checkout-tab-${tabNumber}`);
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  const userCartToggle = () => {
    dispatch(headerCartMenuToggleAction());
  };

  useEffect(() => {
    dispatch(setUserDataAction(userID)); // Dispatch the action to fetch user data
  }, [dispatch, userID]);

  useEffect(() => {
    if (userData) {
      const userShipping = userData?.user_shipping?.shipment_address;

      if (userShipping) {
        setIsAddress(true);
        handleTabNavigation(2);
      } else {
        setIsAddress(false);
      }
    }
  }, [userData]);

  return (
    <>
      <main className="custom-container form-page-wrapper-container checkout-page">
        <div className="form-page-wrapper">
          <div className="row">
            <div className="col-lg-4 sticky-top">
              <div className="form-box">
                <h3 className="h4 mb-3">Order Total</h3>
                <OrderCheckoutTotalView />
                <button
                  type="button"
                  className="btn btn-primary mt-4"
                  onClick={userCartToggle}
                >
                  View Cart Items
                </button>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="form-box">
                <Tab.Container
                  id="checkout-tabs"
                  activeKey={activeKey}
                  onSelect={handleTabSelect}
                >
                  <Nav variant="pills" className="checkout-tab-navs">
                    <Nav.Item>
                      <Nav.Link eventKey="checkout-tab-1">
                        <span className={isAddress ? "active" : ""}>1</span>
                        Customer Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="checkout-tab-2" disabled={!isAddress}>
                        <span>2</span>
                        Payments Method
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="row gx-lg-5">
                    <div className="col-12">
                      <Tab.Content>
                        <Tab.Pane eventKey="checkout-tab-1">
                          <CustomerDetailsForm
                            tabChange={handleTabNavigation}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="checkout-tab-2">
                          <PaymentMethod tabChange={handleTabNavigation} />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
