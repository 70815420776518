import secureLocalStorage from "react-secure-storage";

const AuthService = {
  getUserId: function () {
    let user = secureLocalStorage.getItem("user_auth");
    if (user) {
      user = JSON.parse(user);
      return user._id;
    }
    return null;
  },
  getUser: function () {
    let user = secureLocalStorage.getItem("user_auth");
    if (user) {
      user = JSON.parse(user);
      return user;
    }
    return null;
  },
  logIn(responseData, navigate) {
    secureLocalStorage.setItem("user_auth", JSON.stringify(responseData?.user));
    secureLocalStorage.setItem("user_auth_token", responseData?.token);
    navigate("/");
  },
  logOut(navigate) {
    navigate("/user/login");
    secureLocalStorage.removeItem("user_auth");
    secureLocalStorage.removeItem("user_auth_token");
  },
  saveToken(token) {
    secureLocalStorage.setItem("user_auth_token", token);
  },

  logOutNavigate(navigate) {
    navigate("/user/logout");
  },

  forgetPasswordNavigate(navigate) {
    navigate("/user/forget-password");
  },
  loginNavigate(navigate) {
    setTimeout(() => {
      navigate("/user/login");
    }, 900);
  },
  customerProfileUpdate(navigate) {
    navigate("/user/profile");
  },
  registerSuccessNavigate(navigate) {
    navigate("/user/register/success");
  },
  getAuthToken() {
    const token = secureLocalStorage.getItem("user_auth_token");
    return token || null;
  },
  isCustomerLoggedIn() {
    let user = localStorage.getItem("user_auth");

    if (user) {
      return true;
    } else {
      return false;
    }
  },

  // resteAllCheckout() {
  //   secureLocalStorage.removeItem("checkout_status");
  //   secureLocalStorage.removeItem("checkout_customer_details");
  //   secureLocalStorage.removeItem("checkout_payments_method");
  // },
  // getCheckoutPaymentsMethod() {
  //   return secureLocalStorage.getItem("checkout_payments_method");
  // },
  // isCheckoutResetForOtherUser(responseData) {
  //   const getCheckoutCustomerDetails = JSON.parse(
  //     secureLocalStorage.getItem("checkout_customer_details")
  //   );

  //   if (getCheckoutCustomerDetails) {
  //     if (
  //       getCheckoutCustomerDetails?.customer_email !==
  //       responseData?.user?.user_email
  //     ) {
  //       // this.resteAllCheckout();
  //     }
  //   }
  // },

  OrderCheckoutStep1(tabChange) {
    secureLocalStorage.setItem("order_checkout_step", 2);
    tabChange(2);
  },
  GetOrderCheckoutStep() {
    return secureLocalStorage.getItem("order_checkout_step");
  },
  ClearOrderCheckoutStep() {
    secureLocalStorage.removeItem("order_checkout_step");
  },
};

export default AuthService;
