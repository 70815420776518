import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";
import AuthService from "./AuthService";

export class ProductService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();

    this.endpoints = {
      LIST: "/product/public/lists", // public
      FILTER: "/product/public/lists/filter", // public
      SHOW: "/product/public/:id", // public
      FIND_BY_ID: "/product/public/find-by-ids", // public
      SEARCH: "/product/public/search", // public
      FIND_BY_CATEGORY: "/product/public/find/many", // public
      TREE_VIEW: "/product/public/tree", // public
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async getProductsFindByIds(otherIDs) {
    try {
      const response = await axios.post(
        this.endpoints.FIND_BY_ID,
        otherIDs,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async filterProductList(query, payload) {
    try {
      let url;
      if (query) {
        url = this.endpoints.FILTER + query;
      } else {
        url = this.endpoints.FILTER;
      }
      const response = await axios.post(url, payload, this.config);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
  
  async getProductList(query) {
    try {
      let url;
      if (query) {
        url = this.endpoints.LIST + query;
      } else {
        url = this.endpoints.LIST;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getProductShow(id) {
    try {
      const response = await axios.get(this.endpoints.SHOW.replace(":id", id));
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getProductFilterBySearching(payload) {
    try {
      const response = await axios.post(
        this.endpoints.SEARCH,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getTreeView() {
    try {
      const response = await axios.get(this.endpoints.TREE_VIEW);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getProductsFindByCategory(payload) {
    try {
      const response = await axios.post(
        this.endpoints.FIND_BY_CATEGORY,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
