import React from "react";
import ContinueShoppingBtn from "../../button/ContinueShoppingBtn";
import ProceedToBuyBtn from "../../button/ProceedToBuyBtn";

export default function AddToCartFooterBtn(props) {
  const data = props.data;
  const className = data.className;

  return (
    <>
      <div className={className && className}>
        <div className="cart-heading-bottom d-flex flex-column flex-md-row justify-content-md-between gap-2">
          <ContinueShoppingBtn />
          <ProceedToBuyBtn />
        </div>
      </div>
    </>
  );
}
