import React from "react";
import AuthService from "../../../../../../services/AuthService";
import { CartService } from "../../../../../../services/CartService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { findCartItemAction } from "../../../../../../redux-service/actions/action";

export default function VariantRemoveToCart({ value, variantValue }) {
  const userId = AuthService.getUserId();
  const dispatch = useDispatch();

  async function removeToCartHandler() {
    if (!value || !variantValue) {
      console.error("Cart value is missing.");
      return;
    }

    try {
      const payload = {
        userId: userId,
        cartId: value._id,
        variantId: variantValue._id,
      };

      const response = await new CartService().itemVariantRemoveToCartList(
        payload
      );

      if (response?.data?.status) {
        toast.error(response?.data?.message, {
          icon: (
            <i className="fa-solid fa-trash" style={{ fontSize: "14px" }}></i>
          ),
        });
        if (userId) {
          dispatch(findCartItemAction(userId));
          // dispatch(headerCartMenuToggleAction());
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <button
        title="Item Delete"
        type="button"
        className="btn-item-remove-icon"
        onClick={removeToCartHandler}
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
    </>
  );
}
