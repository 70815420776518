import React from "react";
import { imageBaseUrl, SERVER_URL } from "../../config/Config";
import ProductSkel1 from "../skeleton/ProductSkel1";
import { useNavigate } from "react-router-dom";

export default function ProductCard3({ path, value }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  const renderImage = () => {
    const imageUrl =
      value.category_image !== ""
        ? `${SERVER_URL}${value.category_image}`
        : `${imageBaseUrl}/product-default-1.png`;
    return (
      <img src={imageUrl} alt={value.category_name} className="img-fluid" />
    );
  };

  return (
    <>
      {value ? (
        <div
          className="card product-card-1"
          onClick={handleClick}
          title={`Explore our ${value.category_name} categories`}
        >
          <div className="card-image p-0">{renderImage()}</div>
          <div className="card-body">
            <div className="card-heading">
              <h3>{value.category_name}</h3>
            </div>
          </div>
        </div>
      ) : (
        <ProductSkel1 />
      )}
    </>
  );
}