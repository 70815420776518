import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import { Person } from "react-bootstrap-icons";
import secureLocalStorage from "react-secure-storage";

export default function UserProfile({ activeHeader }) {
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));

  // <!----- menu popup open on mui ----------------------------------------!>
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="header-user-profile">
        {
          <Person
            size={27}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
        }
        <Menu
          id="basic-menu"
          className={activeHeader ? "basic-menu-active" : null}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {userAuthData
            ? [
                <MenuItem key="hi" className="active">
                  <i className="fa-solid fa-hands"></i>
                  {`Hi, ${userAuthData.user_name}`}
                </MenuItem>,
                <MenuItem key="profile" onClick={handleClose}>
                  <i className="fa-solid fa-user"></i>
                  <NavLink to="/user/profile">Profile</NavLink>
                </MenuItem>,
                <MenuItem key="profile" onClick={handleClose}>
                <i className="fa-solid fa-user"></i>
                <NavLink to="/user/order">Your Order</NavLink>
              </MenuItem>,
                <MenuItem key="logout" onClick={handleClose}>
                  <i className="fa-solid fa-right-from-bracket"></i>
                  <NavLink to="/user/logout">LogOut</NavLink>
                </MenuItem>,
              ]
            : [
                <MenuItem key="login" onClick={handleClose}>
                  <i className="fa-regular fa-user"></i>
                  <NavLink to="/user/login">LogIn</NavLink>
                </MenuItem>,
                <MenuItem key="register" onClick={handleClose}>
                  <i className="fa-regular fa-file-lines"></i>
                  <NavLink to="/user/register">Register</NavLink>
                </MenuItem>,
              ]}
        </Menu>
      </div>
    </div>
  );
}
