import React from "react";
import SectionHeading2 from "../components/heading/SectionHeading2";
import { SERVER_URL } from "../config/Config";

export default function PageOverviewBlock({ blockData }) {
  if (!blockData) {
    return null;
  }

  return (
    <>
      <section className="bg-dark section-padding">
        <div className="custom-container">
          <SectionHeading2
            data={{
              heading: (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blockData?.cmpBlock_overview_heading,
                    }}
                  ></div>
                </>
              ),
              text: <p>{blockData?.cmpBlock_overview_text}</p>,
            }}
          />
        </div>
        <div className="about_img_grid mb-25-50">
          <div className="grid-item rounded-sm-right">
            <img
              src={`${SERVER_URL}${blockData?.cmpBlock_overview_image_left}`}
              alt="About us left"
              className="img-fluid"
            />
          </div>
          <div className="grid-item rounded-sm">
            <img
              src={`${SERVER_URL}${blockData?.cmpBlock_overview_image_center}`}
              alt="About us center"
              className="img-fluid"
            />
          </div>
          <div className="grid-item rounded-sm-left">
            <img
              src={`${SERVER_URL}${blockData?.cmpBlock_overview_image_right}`}
              alt="About us right"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="custom-container">
          <div className="row">
            <div
              className="col-lg-12"
              dangerouslySetInnerHTML={{
                __html: blockData?.cmpBlock_overview_content,
              }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
}
