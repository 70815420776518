import React from "react";
import { MAIN_APP_URL } from "../../../config/Config";

export default function EndUserLicenseAgreement() {
  return (
    <>
      <main className="custom-container">
        <section className="py-4">
          <h1 className="mb-4">End User License Agreement</h1>
          <p>
            Thank you for choosing Pass Tooling services provided by&nbsp;
            <a href={MAIN_APP_URL} target="_blank" rel="noreferrer">
              www.passtooling.com
            </a>
            . By using our services, you agree to the following terms:
          </p>
          <ul>
            <li>
              <b>License:</b> Pass Tooling grants you a limited,
              non-transferable license to use our services for personal or
              business purposes.
            </li>
            <li>
              <b>Restrictions:</b> You may not modify, distribute, sublicense,
              or reverse engineer any part of our services without prior written
              consent from Pass Tooling.
            </li>
            <li>
              <b>Intellectual Property:</b> All intellectual property rights
              related to Pass Tooling services are owned by Pass Tooling.
            </li>
            <li>
              <b>Limitation of Liability:</b> Pass Tooling shall not be liable
              for any damages arising out of the use or inability to use our
              services.
            </li>
            <li>
              <b>Termination:</b> Pass Tooling reserves the right to terminate
              this Agreement at any time for non-compliance.
            </li>
            <li>
              <b>Governing Law:</b> This Agreement is governed by the laws of
              [Your Jurisdiction].
            </li>
          </ul>
          <p>
            By using Pass Tooling services, you agree to abide by this
            Agreement. If you disagree with any part of this Agreement, please
            refrain from using our services. For inquiries, please contact us
            using the contact form.
          </p>
          <p>
            <b>Date:</b> 3/6/24 <br /> <b>Pass Tooling, LLC</b> <br />
            <a href={MAIN_APP_URL} target="_blank" rel="noreferrer">
              www.passtooling.com
            </a>
          </p>
        </section>
      </main>
    </>
  );
}
