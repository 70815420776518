import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

export default class ProductSkel2 extends Component {
  render() {
    return (
      <>
        <Skeleton height={250} style={{ borderRadius: "10px" }} />
        <Skeleton height={30} />
        <Skeleton count={3} />
      </>
    );
  }
}
