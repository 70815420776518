import React from "react";
import Badge from "@mui/material/Badge";
import { Heart } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UserWishlist() {
  const navigate = useNavigate();
  const getWishList = useSelector(
    (state) => state.userWishlistReducer.userWishlistData
  );

  return (
    <div className="header-user-wishlist">
      <Badge
        badgeContent={getWishList && getWishList.length}
        style={{ cursor: "pointer" }}
        color="primary"
        onClick={() => navigate("/user/wishlist")}
        className="mt-1"
      >
        <Heart size={22} />
      </Badge>
    </div>
  );
}
