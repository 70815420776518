import { JsonData } from "../../../JsonData";
import CardVariantOptionPrice from "../cards/helper/CardVariantOptionPrice";

export function getCartProductHelper(cartData, productId) {
  const cartProduct = cartData.find(
    (item) => item?.cart_product_id === productId
  );

  return {
    data: cartProduct || null,
    isInCart: !!cartProduct,
  };
}

export function getCartVariantHelper(cartData, productId, variantId) {
  const cartProduct = cartData.find(
    (item) => item?.cart_product_id === productId
  );

  const cartProductVariant = cartProduct?.cart_selected_product_variants.find(
    (variant) => variant?._id === variantId
  );

  return {
    data: cartProductVariant || null,
    isInCart: !!cartProductVariant,
  };
}

export function getCartVariantOptionHelper(
  cartData,
  productId,
  variantId,
  optionId
) {
  const cartProduct = cartData.find(
    (item) => item?.cart_product_id === productId
  );

  const cartProductVariant = cartProduct?.cart_selected_product_variants.find(
    (variant) => variant?._id === variantId
  );

  const cartOption = cartProductVariant?.variant_options_selected.find(
    (option) => option?.variant_options_id === optionId
  );

  return {
    data: cartOption || null,
    isInCart: !!cartOption,
  };
}

export function getDefaultVariantOptionHelper(variantOptionData) {
  const getDefaultVal = variantOptionData.find(
    (ele) => ele.variant_options_child_is_default === true
  );

  return {
    data: getDefaultVal || null,
    isDefault: !!getDefaultVal,
  };
}

export function getVariantDefaultOptionHelper(variantOptionData) {
  if (!variantOptionData || variantOptionData.length === 0) {
    return [];
  }

  const defaultOptions = variantOptionData.map((option) => {
    const { variant_options_id, variant_options_name, variant_options_lists } =
      option;

    if (!variant_options_lists || variant_options_lists.length === 0) {
      return [];
    }

    const defaultOptionsList = variant_options_lists.filter(
      (child) => child.variant_options_child_is_default === true
    );

    return defaultOptionsList.map((child) => ({
      variant_options_id,
      variant_options_name,
      variant_options_child_name: child.variant_options_child_name,
      variant_options_child_price: child.variant_options_child_price,
    }));
  });

  const defaultOptionData = defaultOptions.flat();

  return {
    data: defaultOptionData.flat(),
    isDefault: !!defaultOptionData,
  };
}

export function fetchSelectedMeasurementInCart(measuremenData) {
  if (!measuremenData) {
    return null;
  }

  return (
    <>
      Chosen Measurement:&nbsp;
      {measuremenData?.value}
      &nbsp;
      {measuremenData?.type}
    </>
  );
}

export function fetchSelectedChoseVariantOptionInCart(variantOptionData) {
  if (!variantOptionData && variantOptionData?.length === 0) {
    return null;
  }

  return (
    <>
      {variantOptionData
        ?.slice()
        .sort(
          (a, b) =>
            a.variant_options_child_price - b.variant_options_child_price
        )
        .map((optionValue, optionIndex) => (
          <li key={optionIndex}>
            <span>
              {optionValue.variant_options_name}
              :-&nbsp;&nbsp;&nbsp;
              <b>{optionValue?.variant_options_child_name}</b>
            </span>
            <b>
              {optionValue?.variant_options_child_price && (
                <>
                  &nbsp;+
                  {JsonData.CURRENCY_ICON}
                  {optionValue?.variant_options_child_price}
                </>
              )}
            </b>
          </li>
        ))}
    </>
  );
}

export function showNameAndPriceForVariantOption(name, price) {
  return (
    <>
      {name}
      {price && (
        <>
          :-&nbsp;
          <CardVariantOptionPrice price={price} />
        </>
      )}
    </>
  );
}

export function sortTextHandler(text, textLength) {
  return (
    <>
      {text && textLength && text.length > textLength
        ? `${textLength ? text.slice(0, textLength) : text}...`
        : text}
    </>
  );
}

export function showMeasurement(measuremenValue, type) {
  return (
    <>
      <div>
        <p className="mb-0">
          <b style={{ fontWeight: "var(--fw-bold)" }}>Min: </b>
          {measuremenValue[type].min
            ? `${measuremenValue[type].min} ${type}`
            : "N/A"}
        </p>
        <p className="mb-0">
          <b style={{ fontWeight: "var(--fw-bold)" }}>Max: </b>
          {measuremenValue[type].max
            ? `${measuremenValue[type].max} ${type}`
            : "N/A"}
        </p>
      </div>
    </>
  );
}