import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Banner(props) {
  let [activeFadeUp, setActiveFadeUp] = useState(false);
  const pathName = useLocation().pathname;
  const className = props.className;

  function setActive() {
    setActiveFadeUp(false);
    setTimeout(() => {
      setActiveFadeUp(true);
    }, 1000);
  }

  useEffect(() => {
    setActive();
  }, [pathName]);

  return (
    <>
      <section
        className={
          "hero-banner-1 " + className + (activeFadeUp ? " active" : "")
        }
      >
        <div
          className="banner-img"
          style={{ backgroundImage: `url(${props.data.image})` }}
        ></div>
        <div className="banner-content section-padding">
          <div className="fade-up">
            <h1 className="banner-heading mb-0">{props.data.heading}</h1>
          </div>
        </div>
      </section>
    </>
  );
}
