import { toast } from "react-toastify";
import { StripeService } from "../../../services/payment/stripe/StripeService";

export async function checkoutWithCreditCard(orderId) {
  try {
    const getData = await new StripeService(orderId).checkoutWithCreditCard(
      orderId
    );
    const getDataResult = getData?.data;

    if (getDataResult) {
      toast.success(getDataResult.message, {
        icon: <i className="fa-solid fa-plus"></i>,
      });
      window.location.href = getDataResult.url;
      return true;
    } else {
      toast.error("Failed to complete credit card checkout.", {
        icon: (
          <i
            className="fa-solid fa-exclamation-triangle"
            style={{ fontSize: "14px" }}
          ></i>
        ),
      });
    }
  } catch (error) {
    console.error("Error during credit card checkout:", error);
    return false;
  }
}

export async function checkoutWithNet30Payment(orderId) {
  try {
    const getData = await new StripeService(orderId).createNet30Invoice(
      orderId
    );
    const getDataResult = getData?.data;
    const { success } = getDataResult;

    if (success) {
      toast.success(getDataResult.message, {
        icon: <i className="fa-solid fa-plus"></i>,
      });
      return true;
    } else {
      toast.error("Failed to create Net 30 payment invoice", {
        icon: (
          <i
            className="fa-solid fa-exclamation-triangle"
            style={{ fontSize: "14px" }}
          ></i>
        ),
      });
    }
  } catch (error) {
    console.error("Error during net 30 payment checkout:", error);
    return false;
  }
}

export async function checkoutWithBankTransferPayment(orderId) {
  try {
    const getData = await new StripeService(orderId).createBankTransfer(
      orderId
    );
    const getDataResult = getData?.data;
    const { success } = getDataResult;

    if (success) {
      toast.success(getDataResult.message, {
        icon: <i className="fa-solid fa-plus"></i>,
      });
      window.location.href = getDataResult.url;
      return true;
    } else {
      toast.error("Failed to complete bank transfer checkout.", {
        icon: (
          <i
            className="fa-solid fa-exclamation-triangle"
            style={{ fontSize: "14px" }}
          ></i>
        ),
      });
    }
  } catch (error) {
    console.error("Error during bank transfer checkout:", error);
    return false;
  }
}
