import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SERVER_URL, imageBaseUrl } from "../../../../../../config/Config";
import CardSortText from "../../../../../cards/helper/CardSortText";
import { JsonData } from "../../../../../../../JsonData";
import {
  showMeasurement,
  showNameAndPriceForVariantOption,
} from "../../../../../helper/Helper";

export default function VariantPopUpModal({ variantValue }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        title="Item View"
        type="type"
        className="sm-icon sm"
        onClick={handleShow}
      >
        <i className="fa-solid fa-eye"></i>
      </button>
      <Modal
        className="variant-details-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <h5 className="mb-0">{variantValue?.variant_name}</h5>
          <button type="button" className="btn-icon" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <div className="popup-model-img">
                {variantValue?.variant_image !== "" ? (
                  <img
                    src={`${SERVER_URL}${variantValue?.variant_image}`}
                    alt={variantValue?.variant_name}
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={`${imageBaseUrl}/product-default-1.png`}
                    alt="Default"
                    className="img-fluid p-2"
                  />
                )}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="table-responsive scrollbar-sm scrollbar-gray">
                <table className="table table-view">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Variant Name</th>
                      <td>{variantValue?.variant_name}</td>
                    </tr>
                    <tr>
                      <th>Variant SKU</th>
                      <td>{variantValue?.variant_sku}</td>
                    </tr>
                    <tr>
                      <th>Variant Price</th>
                      <td>{variantValue?.variant_price}</td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Variant Measurement</th>
                    </tr>
                    <tr>
                      <th>Measurement (MM)</th>
                      <td>
                        {showMeasurement(
                          variantValue?.variant_measurement,
                          "mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Measurement (IN)</th>
                      <td>
                        {showMeasurement(
                          variantValue?.variant_measurement,
                          "in"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="table-responsive scrollbar-sm scrollbar-gray">
                <table className="table table-view">
                  <tbody>
                    <tr>
                      <th colSpan={2}>Variant Options</th>
                    </tr>
                    {variantValue?.variant_options.map(
                      (variantOptionValue, variantOptionIndex) => (
                        <tr key={`variant_option_${variantOptionIndex}`}>
                          <th>{variantOptionValue?.variant_options_name}</th>
                          <td>
                            <ol>
                              {variantOptionValue?.variant_options_lists.map(
                                (
                                  variantOptionChildValue,
                                  variantOptionChildIndex
                                ) => (
                                  <li
                                    className={`variant_option_child_${variantOptionChildIndex}`}
                                  >
                                    {showNameAndPriceForVariantOption(
                                      variantOptionChildValue?.variant_options_child_name,
                                      variantOptionChildValue?.variant_options_child_price
                                    )}
                                  </li>
                                )
                              )}
                            </ol>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <th colSpan={2}>Variant Description</th>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <CardSortText
                          text={variantValue?.variant_description}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
