import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";
import AuthService from "./AuthService";
import { SERVER_URL } from "../config/Config";

export class UPSservice {
  constructor() {
    this.baseURL = `${SERVER_URL}/api/v1/`;
    this.authorizationToken = AuthService.getAuthToken();
    this.endpoints = {
      SHIPPING_CHARGE: "ups/shipping/charge",
      SHIPPING_ADDRESS: "ups/shipping/address/:userId",
    };
    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async calculateShippingCharge(payload) {
    try {
      const response = await axios.post(
        this.endpoints.SHIPPING_CHARGE,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async createShippingAddress(userId, payload) {
    try {
      const response = await axios.post(
        this.endpoints.SHIPPING_ADDRESS.replace(":userId", userId),
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
