import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import KeywordMultipleMachineMakeSelect from "../../form/KeywordMultipleMachineMakeSelect";
import KeywordMultipleMachineModelSelect from "../../form/KeywordMultipleMachineModelSelect";
import { ProductCategoryService } from "../../../services/ProductCategoryService";
import { ProductService } from "../../../services/ProductService";
import {
  mainProductList,
  mainProductListIsFilter,
  mainProductListLoader,
  mainProductListPagination,
} from "../../../redux-service/actions/action";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { JsonData } from "../../../../JsonData";

export default function ProductTopFilterBelt() {
  const [keywordInputData, setKeywordInputData] = useState({
    product_category_id: "",
    machine_make_ids: [],
    machine_model_ids: [],
  });

  const [productCategoryDropdown, setProductCategoryDropdown] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const { product_category_id, machine_make_ids, machine_model_ids } =
    keywordInputData;

  const dispatch = useDispatch();

  const productListPagiData = useSelector(
    (state) => state.productReducer.productMainListPagination
  );
  const IsFilter = useSelector(
    (state) => state.productReducer.productMainListIsFilter
  );

  useEffect(() => {
    new ProductCategoryService()
      .getCategoryDropdown()
      .then((response) => {
        const result = response.data.result;
        if (result && result.length > 0) {
          setProductCategoryDropdown(result);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  async function getFilteredProductsHandler() {
    dispatch(mainProductListLoader(true));

    let query = "";
    if (productListPagiData) {
      query += `?page=${productListPagiData.currentPage}`;
    }

    let payload = {};

    if (searchInput) {
      payload.product_name = searchInput;
    }

    if (product_category_id) {
      payload.categoryId = product_category_id;
    }

    if (machine_make_ids.length > 0) {
      payload.machineMakeIds = machine_make_ids;
    }

    if (machine_model_ids.length > 0) {
      payload.machineModelIds = machine_model_ids;
    }

    if (
      searchInput ||
      product_category_id ||
      machine_make_ids.length > 0 ||
      machine_model_ids.length > 0
    ) {
      try {
        const response = await new ProductService().filterProductList(
          query,
          payload
        );
        const getDataResult = response?.data?.result;
        const getPagiData = response?.data?.paginationInfo;
        dispatch(mainProductList(getDataResult));
        if (getPagiData.currentPage > getPagiData.totalPages) {
          dispatch(
            mainProductListPagination({
              ...getPagiData,
              currentPage: 1,
            })
          );
        } else {
          dispatch(mainProductListPagination(getPagiData));
        }
        dispatch(mainProductListIsFilter(true));
      } catch (error) {
        console.error(JsonData?.messages?.errors?.search + " " + error);
      } finally {
        dispatch(mainProductListLoader(false));
      }
    } else {
      dispatch(mainProductListLoader(false));
      dispatch(mainProductListIsFilter(false));
    }
  }

  const getApiHandler = async () => {
    dispatch(mainProductListLoader(true));

    try {
      const query = `?page=1`;
      const response = await new ProductService().getProductList(query);
      const getDataResult = response?.data?.result;
      const getPagiData = response?.data?.paginationInfo;

      dispatch(mainProductList(getDataResult));
      dispatch(
        mainProductListPagination({
          ...getPagiData,
          currentPage: 1,
        })
      );
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    } finally {
      dispatch(mainProductListLoader(false));
    }
  };

  function resetProductFilter(e) {
    e.stopPropagation();
    dispatch(mainProductListIsFilter(false));
    getApiHandler();
    setKeywordInputData({
      product_category_id: "",
      machine_make_ids: [],
      machine_model_ids: [],
    });
  }

  function fetchChildHandler(name, value) {
    setKeywordInputData((prevData) => ({ ...prevData, [name]: value }));
  }

  useEffect(() => {
    getFilteredProductsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchInput,
    product_category_id,
    machine_make_ids.length,
    machine_model_ids.length,
    productListPagiData?.currentPage,
  ]);

  return (
    <Accordion className="remove-style">
      <Accordion.Item eventKey="0" className="reverse-on-mobile">
        <Accordion.Header>
          <div className="row mb-4">
            <div className="col-lg-6">
              {IsFilter && (
                <h4 className="mb-0 d-none d-lg-block">
                  Found Result:-&nbsp;
                  <span className="text-highlight-color">
                    {productListPagiData?.totalCount}
                  </span>
                </h4>
              )}
            </div>
            <div className="col-lg-6 d-flex flex-column flex-lg-row justify-content-end gap-2">
              {IsFilter && (
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={resetProductFilter}
                >
                  Reset Product
                </button>
              )}
              <div className="btn btn-primary">
                Filter Product
                <div className="accordion-plus-minus-icon">
                  <i className="fa-solid fa-plus"></i>
                  <i className="fa-solid fa-minus"></i>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="filter-top-belt-grid">
            <div className="grid-item">
              <label>Product Category</label>
              <div className="select-wrapper">
                <select
                  id="productCategory"
                  name="productCategory"
                  className="form-control"
                  value={product_category_id}
                  onChange={(e) => {
                    setKeywordInputData({
                      ...keywordInputData,
                      product_category_id: e.target.value,
                    });
                  }}
                >
                  <option hidden></option>
                  <option value="">None</option>
                  {Array.isArray(productCategoryDropdown) &&
                    productCategoryDropdown.map((value) => (
                      <option key={value.value} value={value.value}>
                        {value.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="grid-item">
              <KeywordMultipleMachineMakeSelect
                inputName="machine_make_ids"
                fetchChildData={fetchChildHandler}
              />
            </div>
            <div className="grid-item">
              <KeywordMultipleMachineModelSelect
                inputName="machine_model_ids"
                fetchChildData={fetchChildHandler}
                machineMakeIds={machine_make_ids}
              />
            </div>
            <div className="grid-item">
              <div className="search-wrapper">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onBlur={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
                {!searchInput && (
                  <div className="icon-group">
                    <Icon.Search size={15} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}