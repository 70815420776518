import React from "react";
import { NavLink } from "react-router-dom";
import { MAIN_APP_URL } from "../../config/Config";

export default function FooterCol1() {
  return (
    <>
      <div className="grid-item">
        <ul className="footer-menu">
          <li>
            <h4>
              <NavLink to={`${MAIN_APP_URL}/`}>Home</NavLink>
            </h4>
          </li>
          <li>
            <h4>
              <NavLink to={`${MAIN_APP_URL}/about`}>About Us</NavLink>
            </h4>
          </li>
          <li>
            <h4>
              <NavLink to={`${MAIN_APP_URL}/contact`}>Contact Us</NavLink>
            </h4>
          </li>
          <li>
            <h4>
              <NavLink to={`${MAIN_APP_URL}/downloads`}>Downloads</NavLink>
            </h4>
          </li>
        </ul>
      </div>
    </>
  );
}
