import React, { Component } from "react";
import { imageBaseUrl } from "../../config/Config";
import { NavLink } from "react-router-dom";

export default class PageNotFound extends Component {
  render() {
    return (
      <>
        <section className="section-padding">
          <div className="custom-container d-flex flex-column align-items-center justify-content-center">
            <img
              src={`${imageBaseUrl}/404-page-not-found.png`}
              alt="404 page not found"
              className="img-fluid mb-15-25"
            />
            <NavLink to="/" className="btn btn-primary">
              Go Back to Home
            </NavLink>
          </div>
        </section>
      </>
    );
  }
}
