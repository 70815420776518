import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { CustomerService } from "../../../services/CustomerService";
import AuthService from "../../../services/AuthService";
import { JsonData } from "../../../../JsonData";

export default function Login() {
  const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    user_email: "",
    user_password: "",
    remember_me: false,
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { user_email, user_password, remember_me } = inputData;

  function inputHandler(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  const loginUserApiHandler = async () => {
    try {
      const payload = {
        user_email,
        user_password,
        remember_me,
      };

      const response = await new CustomerService().loginCustomer(payload);
      const result = response.data;

      if (result.status === true) {
        toast.success(result.message, {
          icon: <i className="fa-solid fa-user-check"></i>,
        });

        const setLoginData = {
          user: result?.user,
          token: result?.authToken,
        };

        AuthService.logIn(setLoginData, navigate);
        // AuthService.isCheckoutResetForOtherUser(setLoginData);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.login + " " + error);
    }
  };

  function formHandler(e) {
    e.preventDefault();
    if (!user_email || !user_password) {
      toast.warning("Please fill all the fields.", {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      });
    } else {
      loginUserApiHandler();
    }
  }

  function isPasswordVisibleHandler() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-xl-4">
          <div className="form-box">
            <h1 className="h2 text-center mb-4">Login</h1>
            <form onSubmit={formHandler}>
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="user_email">Email</label>
                  <input
                    value={user_email}
                    name="user_email"
                    id="user_email"
                    className="form-control"
                    onChange={inputHandler}
                  />
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="user_password">Password</label>
                  <div className="input-icon-group">
                    <input
                      value={user_password}
                      type={isPasswordVisible ? "text" : "password"}
                      name="user_password"
                      id="user_password"
                      className="form-control"
                      onChange={inputHandler}
                    />
                    <div
                      className="input-icon"
                      onClick={isPasswordVisibleHandler}
                    >
                      {isPasswordVisible ? (
                        <Icon.EyeSlash title="Hide Password" size={20} />
                      ) : (
                        <Icon.Eye size={20} title="View Password" />
                      )}
                    </div>
                  </div>
                  <div className="mt-3 d-flex justify-content-between">
                    <label>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setInputData({
                            ...inputData,
                            remember_me: e.target.checked,
                          });
                        }}
                      />
                      <span>
                        &nbsp; Remember Me
                        {remember_me && (
                          <>
                            <br />
                            <small className="text-success fw-bold">
                              Valid for 1 week.
                            </small>
                          </>
                        )}
                      </span>
                    </label>
                    <NavLink to="/user/forget-password" className="link">
                      Forget Password ?
                    </NavLink>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center flex-column gap-3 mt-3">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  <NavLink to="/user/register" className="link text-center">
                    Register
                  </NavLink>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
