import { CmpBlockData } from "./main/json-data/CmpBlockData";
import { CmsPageId } from "./main/json-data/CmsPageId";

export const JsonData = {
  testMode: false,
  cmpBlockData: CmpBlockData,
  cmpPageID: CmsPageId,
  paymentMethod: ["COD", "QUICKBOOKS"],
  paymentMethodOptions: [
    {
      label: "COD",
      value: "COD",
      text: "Cash on Delivery",
    },
    {
      label: "Credit Card",
      value: "Credit Card (via Stripe)",
      text: "Pay securely with your credit card",
    },
    {
      label: "Net 30",
      value: "Net 30 (via Stripe)",
      text: "Pay within 30 days",
    },
    {
      label: "Bank Transfer",
      value: "Bank Transfer (via Stripe)",
      text: "Transfer payment from your bank account",
    },
  ],
  paymentOption: ["QBO - Invoice", "QBO - Payment"],
  messages: {
    errors: {
      create: "Error during creation operation:",
      variantPlusMinus: "Error during Variant Plus & Minus operation:",
      search: "Error during search operation:",
      update: "Error during update operation:",
      read: "Error during read operation:",
      addToCart: "Error during add to cart operation:",
      removeToCart: "Error during remove to cart operation:",
      qtyPlusMinus: "Error during qty plus & minus operation:",
      login: "Error during login operation:",
      register: "Error during register operation:",
      forgetPass: "Error during forget password operation:",
      resetPass: "Error during reset password operation:",
      verify: "Error during verify operation:",
      changePass: "Error during change password operation:",
      delete: "Error during deletion  operation:",
    },
  },
  ORDER_SHIPPING_STATUS: {
    Pending: "Pending",
    Shipped: "Shipped",
    Delivered: "Delivered",
  },
  ORDER_PAYMENT_STATUS: {
    Pending: "Pending",
    Paid: "Paid",
    unpaid: "unpaid",
    Overdue: "Overdue",
    AwaitingPayment: "Awaiting Payment",
    Failed: "Failed",
  },
  ORDER_STATUS: {
    Pending: "Pending",
    Draft: "Draft",
    Processing: "Processing",
    Delivered: "Delivered",
    Cancelled: "Cancelled",
  },
  CURRENCY_ICON: "$",
};
