import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

export default class ProductSkel1 extends Component {
  render() {
    return (
      <>
        <Skeleton height={350} />
        <Skeleton height={40} />
      </>
    );
  }
}
