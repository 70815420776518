import React from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  findCartItemAction,
  headerCartMenuToggleAction,
} from "../../redux-service/actions/action";
import IsAuthenticated from "../auth-check/IsAuthenticated";
import { CartService } from "../../services/CartService";
import { JsonData } from "../../../JsonData";
import secureLocalStorage from "react-secure-storage";
import { NavLink } from "react-router-dom";

export default function AddToCartBtn({ classText, value, type }) {
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const dispatch = useDispatch();

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const filterCartData =
    getCartData &&
    getCartData.find((ele) => ele.cart_product_id === value?._id);

  const addToCartHandler = async () => {
    if (!value) return;

    const NewCartData = {
      cart_customer_id: userID,
      cart_product_id: value?._id,
      cart_product_price: value?.product_price,
      cart_product_quantity: value?.product_quantity ? value?.product_quantity : 1,
    };

    try {
      const getData = await new CartService().itemAddToCartList(NewCartData);
      const getDataResult = getData?.data;
      if (getDataResult) {
        toast.success(getDataResult.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });
        if (userID) {
          dispatch(findCartItemAction(userID));
          dispatch(headerCartMenuToggleAction());
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.addToCart + " " + error);
    }
  };

  function AddToCartToggleBtnHtml() {
    return (
      <>
        {filterCartData ? (
          <button
            title="Item added to your cart"
            className={`btn btn-primary ${classText && classText}`}
            onClick={addToCartHandler}
          >
            Item added to your cart
          </button>
        ) : (
          <button
            title="Add to Cart"
            className={`btn btn-outline-primary ${classText && classText}`}
            onClick={addToCartHandler}
          >
            Add to Cart
          </button>
        )}
      </>
    );
  }

  function isNotLoginBtn() {
    return (
      <NavLink
        to="/user/login"
        title="View Pricing - Login"
        className={`btn btn-outline-primary ${classText && classText}`}
      >
        View Pricing - Login
      </NavLink>
    );
  }

  if (!value) {
    return null;
  }

  if (!IsAuthenticated()) {
    return isNotLoginBtn();
  }

  if (value?.product_variants_status) {
    return null;
  }

  return <>{type && type === "ADD_CART_TOGGLE" && AddToCartToggleBtnHtml()}</>;
}
