import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import InnerPageWrapper from "./wrapper/InnerPageWrapper";
import FormWrapper from "./wrapper/FormWrapper";

import Login from "./pages/static/user-auth/Login";
import Register from "./pages/static/user-auth/Register";
import RegisterVerify from "./pages/static/user-auth/RegisterVerify";
import ChangePassword from "./pages/static/user-auth/ChangePassword";
import ForgetPassword from "./pages/static/user-auth/ForgetPassword";
import ResetPassword from "./pages/static/user-auth/ResetPassword";
import LogOut from "./pages/static/user-auth/LogOut";

import UpdateProfile from "./pages/static/user-auth/UpdateProfile";
import UserProfile from "./pages/static/user-auth/UserProfile";
import UserOrder from "./pages/static/UserOrder";
import UserAddToCart from "./pages/static/UserAddToCart";
import UserWishlist from "./pages/static/UserWishlist";

import CategoryList from "./components/pages/products/CategoryList";
import ProductList from "./components/pages/products/ProductList";
import ProductMainList from "./components/pages/products/ProductMainList";
// import ProductDetails from "./components/pages/products/ProductDetails";

import AuthenticatedRoute from "./components/auth-check/AuthenticatedRoute";
import RedirectIfLoggedIn from "./components/auth-check/RedirectIfLoggedIn";
import PageNotFound from "./pages/static/PageNotFound";

import OrderSuccess from "./components/checkout/order/OrderSuccess";

import { PageService } from "./services/PageService";
import { JsonData } from "../JsonData";
import Block from "./page-block/Block";
import RegisterSuccess from "./pages/static/user-auth/RegisterSuccess";
import ProductDetailsDemoHtml from "./components/pages/products/single/variation-demo/ProductDetailsDemoHtml";
import ProductSingle from "./components/pages/products/single/ProductSingle";
import Faqs from "./pages/static/Faqs";
import PrivacyPolicy from "./pages/static/policies/PrivacyPolicy";
import EndUserLicenseAgreement from "./pages/static/policies/EndUserLicenseAgreement";
import OrderCheckout from "./pages/static/OrderCheckout";
import OrderFail from "./components/checkout/order/OrderFail";
import OrderPaymentSuccess from "./components/checkout/order/OrderPaymentSuccess";
import OrderPaymentFail from "./components/checkout/order/OrderPaymentFail";

export default function RoutesRoot() {
  const [pageList, setPageList] = useState([]);

  useEffect(() => {
    new PageService()
      .getPageDropdown()
      .then((response) => {
        const result = response.data.result;
        if (result) {
          setPageList(result);
        }
      })
      .catch((error) => {
        console.error(JsonData?.messages?.errors?.read, error);
      });
  }, []);

  return (
    <>
      <Routes>
        {pageList &&
          pageList.map((value, index) => (
            <Route
              key={`page_router${index}`}
              path={value.slug}
              element={<Block pageId={value.value} pageageType={value.slug} />}
            />
          ))}
        <Route
          path="/user/wishlist"
          element={
            <InnerPageWrapper
              cmpUnder={UserWishlist}
              data={{
                title: "User Wishlist - Pass Tooling",
                heading: (
                  <>
                    User <span>Wishlist</span>
                  </>
                ),
                image: "wishlist-banner.jpg",
              }}
            />
          }
        />
        <Route
          path="/user/order"
          element={
            <InnerPageWrapper
              cmpOuter={UserOrder}
              data={{
                title: "Your Order - Pass Tooling",
                heading: (
                  <>
                    Your <span>Order</span>
                  </>
                ),
                image: "your-order-banner.jpg",
              }}
            />
          }
        />
        <Route
          path="/user/add-to-cart"
          element={
            <InnerPageWrapper
              cmpUnder={UserAddToCart}
              data={{
                title: "Your Cart - Pass Tooling",
                heading: (
                  <>
                    Your <span>Cart</span>
                  </>
                ),
                image: "add-to-cart-banner.jpg",
              }}
            />
          }
        />
        <Route path="/user/" element={<FormWrapper />}>
          <Route path="login" element={<RedirectIfLoggedIn cmp={Login} />} />
          <Route path="logout" element={<AuthenticatedRoute cmp={LogOut} />} />
          <Route
            path="register"
            element={<RedirectIfLoggedIn cmp={Register} />}
          />
          <Route
            path="register/success"
            element={<RedirectIfLoggedIn cmp={RegisterSuccess} />}
          />
          <Route
            path="verify/register"
            element={<RedirectIfLoggedIn cmp={RegisterVerify} />}
          />
          <Route
            path="forget-password"
            element={<RedirectIfLoggedIn cmp={ForgetPassword} />}
          />
          <Route
            path="change-password"
            element={<AuthenticatedRoute cmp={ChangePassword} />}
          />
          <Route
            path="reset-password"
            element={<RedirectIfLoggedIn cmp={ResetPassword} />}
          />
          <Route
            path="profile/update"
            element={<AuthenticatedRoute cmp={UpdateProfile} />}
          />
          <Route
            path="profile"
            element={<AuthenticatedRoute cmp={UserProfile} />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/product-variant-example"
          element={<ProductDetailsDemoHtml />}
        />
        <Route
          path="/"
          element={
            <InnerPageWrapper
              cmpOuter={ProductMainList}
              data={{
                title: "Store - Pass Tooling",
                heading: (
                  <>
                    Our <span>Store</span>
                  </>
                ),
                image: "banner-2.jpg",
              }}
            />
          }
        />
        <Route path="/product/:category/:id" element={<CategoryList />} />
        <Route path="/product/:category/:name/:id" element={<ProductList />} />
        <Route path=":name/:id" element={<ProductSingle />} />
        <Route
          path="/order/checkout"
          element={<AuthenticatedRoute cmp={OrderCheckout} />}
        />
        {/* <Route path="/testing" element={<Testing />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/end-user-license-agreement"
          element={<EndUserLicenseAgreement />}
        />

        <Route path="/order/process/success" element={<OrderSuccess />} />
        <Route path="/order/process/faild" element={<OrderFail />} />
        <Route
          path="/order/payment/success"
          element={<OrderPaymentSuccess />}
        />
        <Route path="/order/payment/faild" element={<OrderPaymentFail />} />
        {/* <Route path="/stripe/test" element={<TestStripe />} /> */}
      </Routes>
    </>
  );
}
