import { handleApiError } from "../components/core/CommonFunction";
import axios from "../config/NodeAxios";
import AuthService from "./AuthService";

export class MachineModelService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();

    this.endpoints = {
      SELECTED_DROPDOWN: "/product/keyword/public/machine-model/dropdown/selected", // public
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async getSelectedMachineMakeDropdown(IDs) {
    try {
      const response = await axios.post(
        this.endpoints.SELECTED_DROPDOWN,
        IDs,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
