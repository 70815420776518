import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import { Cart } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import {
  findCartItemAction,
  headerCartMenuToggleAction,
} from "../../redux-service/actions/action";
import secureLocalStorage from "react-secure-storage";
import CartDropdownIndex from "../cart/CartDropdownIndex";

export default function UserCartMenu(props) {
  const dispatch = useDispatch();
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );
  const cartOpenToggle = useSelector(
    (state) => state.menuHandleReducer.cartMenuToggle
  );

  useEffect(() => {
    document.body.style.overflow = cartOpenToggle ? "hidden" : "unset";
  }, [cartOpenToggle]);

  useEffect(() => {
    if (userID) {
      dispatch(findCartItemAction(userID));
    }
  }, [dispatch, userID]);

  const userCartToggle = () => {
    dispatch(headerCartMenuToggleAction());
  };

  return (
    <>
      <Badge badgeContent={getCartData.length} color="primary">
        <Cart size={25} onClick={userCartToggle} />
      </Badge>
      <div id="user-cart-aside" className={cartOpenToggle ? "active" : ""}>
        <div className="user-cart-aside scrollbar-sm">
          <div className="user-cart-heading">
            <div className="sm-icon user-cart-close" onClick={userCartToggle}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            <h2 className="mb-0 h3">Your Cart</h2>
          </div>
          <CartDropdownIndex />
        </div>
        {getCartData && getCartData.length > 0 && (
          <div className="user-cart-footer">
            <NavLink
              to="/user/add-to-cart"
              className="btn btn-white"
              onClick={userCartToggle}
            >
              View Cart
            </NavLink>
            <NavLink
              to="/order/checkout"
              className="btn btn-primary"
              onClick={userCartToggle}
            >
              Proceed to Buy
            </NavLink>
          </div>
        )}
        <div className="user-cart-overlay" onClick={userCartToggle}></div>
      </div>
    </>
  );
}
