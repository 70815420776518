import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NotFoundCart from "../data-loader/NotFoundCart";
import { headerCartMenuToggleAction } from "../../redux-service/actions/action";
import CartNormal from "./CartNormal";
import CartVariable from "./CartVariable";
import { JsonData } from "../../../JsonData";
import OrderCheckoutCalc from "../pages/checkout/OrderCheckoutCalc";

export default function CartDropdownIndex({
  HideThead = false,
  HideTfoot = false,
}) {
  const dispatch = useDispatch();

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const userCartToggle = () => {
    dispatch(headerCartMenuToggleAction());
  };

  return (
    <>
      {getCartData && getCartData.length > 0 ? (
        <>
          <div className="table-responsive scrollbar-sm scrollbar-gray">
            <table className="cart-table">
              {!HideThead && (
                <thead>
                  <tr>
                    <th>
                      <span>Item</span>
                    </th>
                    <th>
                      <span>QTY</span>
                    </th>
                    <th>
                      <span>Total</span>
                    </th>
                    <th>
                      <span>#</span>
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {getCartData.map((value, index) => {
                  if (value?.cart_selected_product_variants?.length === 0) {
                    return <CartNormal value={value} index={index} />;
                  } else if (
                    value?.cart_selected_product_variants?.length > 0
                  ) {
                    return <CartVariable value={value} index={index} />;
                  } else {
                    return null;
                  }
                })}
              </tbody>
              {!HideTfoot && (
                <tfoot>
                  <tr>
                    <th colSpan={2}>
                      <span>Sub Total</span>
                    </th>
                    <th colSpan={2} className="cart-total">
                      <span>
                        <sup>{JsonData.CURRENCY_ICON}</sup>
                        <OrderCheckoutCalc chargeType="subTotal" />
                      </span>
                    </th>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
          <div className="user-cart-footer-gap"></div>
        </>
      ) : (
        <div onClick={userCartToggle}>
          <NotFoundCart />
        </div>
      )}
    </>
  );
}
