/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import { imageBaseUrl, SERVER_URL } from "../../config/Config";
import IsAuthenticated from "../auth-check/IsAuthenticated";
import { JsonData } from "../../../JsonData";
import { useNavigate } from "react-router-dom";
import { ProductService } from "../../services/ProductService";
import { CalculatePriceRange } from "../cards/helper/CalculatePriceRange";

export default function HeaderSearchMenu() {
  const navigate = useNavigate();

  let [data, setData] = useState([]);

  // <!----- state ----------------------------------------!>
  let [toggle, setToggle] = useState(false);
  let [searchInput, setSearchInput] = useState("");

  async function searchResultHandler() {
    try {
      const payload = {
        search: searchInput,
      };

      const response = await new ProductService().getProductFilterBySearching(
        payload
      );
      const result = response?.data?.result;
      setData(result);
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  }

  const goToproduct = (slug, id) => {
    navigate(`/${slug}/${id}`);
    setToggle(false);
  };

  useEffect(() => {
    if (searchInput !== "") {
      searchResultHandler();
    }
  }, [searchInput]);

  return (
    <>
      <div className={"search-megamenu megamenu" + (toggle ? " show" : "")}>
        <div className="search-wrapper d-none d-lg-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onFocus={() => setToggle(true)}
            // onBlur={() => setToggle(false)}
          />
          {searchInput === "" ? (
            <div className="icon-group">
              <Icon.Search size={15} />
            </div>
          ) : null}
        </div>
        {searchInput !== "" ? (
          <div className="search-dropdown-menu">
            <div className="product-dropdown-menu scrollbar-sm">
              <div className="grid-wrapper d-flex flex-column gap-3">
                {data && data.length > 0 ? (
                  data.map((value) => (
                    <div
                      className="cart-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        goToproduct(value?.product_slug, value?._id)
                      }
                      key={`search_item_menu_${value?._id}`}
                    >
                      <div className="img">
                        {value?.product_images[0] !== "" ? (
                          <img
                            src={`${SERVER_URL}${value?.product_images[0]}`}
                            alt={value?.product_name}
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={`${imageBaseUrl}/default-image-150-100.png`}
                            alt="Cart default"
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div className="text">
                        <b className="title">{value?.product_name}</b>
                        <p>
                          {IsAuthenticated() && (
                            <>
                              Price:
                              {value?.product_variants?.length === 0 ? (
                                <b>
                                  <sup>{JsonData.CURRENCY_ICON}</sup>
                                  {value?.product_price}
                                </b>
                              ) : (
                                <b>
                                  {CalculatePriceRange(
                                    value?.product_variants
                                  )}
                                </b>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="product-item">
                    <b className="d-block text-center py-2 w-100">
                      No Products
                    </b>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
