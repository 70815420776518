import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import {
  findCartItemAction,
  setUserDataAction,
} from "../../../redux-service/actions/action";
import { JsonData } from "../../../../JsonData";
import { OrderService } from "../../../services/OrderService";
import AuthService from "../../../services/AuthService";
import PaymentMethodLoading from "../../data-loader/PaymentMethodLoading";
import {
  checkoutWithBankTransferPayment,
  checkoutWithCreditCard,
  checkoutWithNet30Payment,
} from "./PaymentMethodHelper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function PaymentMethod({ tabChange }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userID = JSON.parse(secureLocalStorage.getItem("user_auth"))?._id;
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    dispatch(setUserDataAction(userID)); // Fetch user data on component mount
  }, [dispatch, userID]);

  const userData = useSelector((state) => state.userReducer.userData);
  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );
  const getUserCartCharges = useSelector(
    (state) => state.userCartReducer.userCartCharges
  );

  const [paymentMethod, setPaymentMethod] = useState(JsonData.paymentMethod[0]);
  const [orderCreated, setOrderCreated] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const filteredCartData = getCartData.map((cartData) => ({
    cart_product_id: cartData.cart_product_id,
    cart_product_quantity: cartData.cart_product_quantity,
    cart_product_price: cartData.cart_product_price,
    cart_product_total: cartData.cart_product_total,
    cart_selected_product_variants: cartData.cart_selected_product_variants,
    cart_item_total: cartData.cart_item_total,
  }));

  const createOrderHandler = async () => {
    try {
      if (getCartData && getCartData?.lenght === 0) {
        return;
      }

      if (!filteredCartData || filteredCartData.length === 0) {
        toast.error("Please add items to your cart before proceeding.", {
          icon: <i className="fa-exclamation"></i>,
        });
        return;
      }

      if (orderCreated) {
        return;
      }

      setOrderCreated(true);
      setIsLoader(true);

      const payload = {
        customer_id: userID,
        customer_order_list: filteredCartData,
        customer_payment: { type: paymentMethod },
        order_charges: getUserCartCharges,
      };

      const response = await new OrderService().addOrder(payload);

      const { success, message, result } = response.data;

      if (success) {
        toast.success(message, { icon: <i className="fa fa-cart-plus"></i> });
        setOrderId(result.order_id);
        if (result.payment_method_type === "COD") {
          setTimeout(() => {
            navigate("/order/process/success");
            setIsLoader(false);
          }, 1500);
        } else if (result.payment_method_type === "Credit Card (via Stripe)") {
          const checkoutoResp = await checkoutWithCreditCard(result.order_id);
          setTimeout(() => {
            if (checkoutoResp) {
              dispatch(findCartItemAction(userID));
              setOrderId(null);
            }
            setIsLoader(false);
          }, 1500);
        } else if (result.payment_method_type === "Net 30 (via Stripe)") {
          const checkoutoResp = await checkoutWithNet30Payment(result.order_id);
          setTimeout(() => {
            if (checkoutoResp) {
              dispatch(findCartItemAction(userID));
              navigate("/order/process/success");
              setOrderId(null);
            }
            setIsLoader(false);
          }, 1500);
        } else if (
          result.payment_method_type === "Bank Transfer (via Stripe)"
        ) {
          const checkoutoResp = await checkoutWithBankTransferPayment(
            result.order_id
          );
          setTimeout(() => {
            if (checkoutoResp) {
              dispatch(findCartItemAction(userID));
              setOrderId(null);
            }
            setIsLoader(false);
          }, 1500);
        }
      }
    } catch (error) {
      navigate("/order/process/faild");
      console.error("Error creating order:", error);
      toast.error(
        "Failed to create the order. Please try again: " + error.message,
        {
          icon: <i className="fa-exclamation"></i>,
        }
      );
    } finally {
      setTimeout(() => {
        setOrderCreated(false);
        dispatch(findCartItemAction(userID));
        AuthService.ClearOrderCheckoutStep();
      }, 1500);
    }
  };

  const handlePaymentMethodSubmit = async () => {
    if (paymentMethod === "Credit Card (via Stripe)") {
      await checkoutWithCreditCardHandler();
    } else if (paymentMethod === "Net 30 (via Stripe)") {
      await checkoutWithNet30PaymentHandler();
    } else if (paymentMethod === "Bank Transfer (via Stripe)") {
      await checkoutWithBankTransferPaymentHandler();
    }
  };

  async function checkoutWithCreditCardHandler() {
    const checkoutoResp = await checkoutWithCreditCard(orderId);
    if (checkoutoResp) {
      setTimeout(() => {
        dispatch(findCartItemAction(userID));
        setIsLoader(false);
        setOrderId(null);
      }, 1500);
    }
  }

  async function checkoutWithNet30PaymentHandler() {
    const checkoutoResp = await checkoutWithNet30Payment(orderId);
    if (checkoutoResp) {
      setTimeout(() => {
        dispatch(findCartItemAction(userID));
        navigate("/order/process/success");
        setIsLoader(false);
        setOrderId(null);
      }, 1500);
    }
  }

  async function checkoutWithBankTransferPaymentHandler() {
    const checkoutoResp = await checkoutWithBankTransferPayment(orderId);
    if (checkoutoResp) {
      setTimeout(() => {
        dispatch(findCartItemAction(userID));
        setIsLoader(false);
        setOrderId(null);
      }, 1500);
    }
  }

  function showPaymentBtn() {
    if (orderId) {
      return (
        <div className="col-12 d-flex justify-content-end gap-3 mt-3">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => tabChange(1)}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handlePaymentMethodSubmit}
          >
            {paymentMethod === "Credit Card (via Stripe)"
              ? "Pay with credit card"
              : paymentMethod === "Net 30 (via Stripe)"
              ? "Pay with net 30"
              : paymentMethod === "Bank Transfer (via Stripe)"
              ? "Pay with bank transfer"
              : "None"}
          </button>
        </div>
      );
    }
  }

  return (
    <div className="row form-group">
      {isLoader ? (
        <div className="col-12">
          <PaymentMethodLoading />
        </div>
      ) : (
        <div className="col-12">
          <h3>Payments Method</h3>
          <label htmlFor="paymentMethod">
            Select Payment Method <sup>*</sup>
          </label>
          <div className="payment_method_option">
            {JsonData.paymentMethodOptions.map(
              ({ value, label, text }, index) => (
                <div
                  key={`payment_method_option_${index}`}
                  className="form-check"
                >
                  <input
                    type="radio"
                    id={`payment_method_option_${index}`}
                    name="paymentMethod"
                    value={value}
                    checked={paymentMethod === value}
                    className="form-check-input"
                    onChange={handlePaymentMethodChange}
                  />
                  <label
                    htmlFor={`payment_method_option_${index}`}
                    className="form-check-label d-flex align-items-center gap-1"
                  >
                    {label}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`${index}_payment_method_text`}>
                          {text}
                        </Tooltip>
                      }
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </OverlayTrigger>
                  </label>
                </div>
              )
            )}
          </div>
          {orderId ? (
            showPaymentBtn()
          ) : (
            <div className="col-12 d-flex justify-content-end gap-3 mt-3">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => tabChange(1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={createOrderHandler}
              >
                Process to order
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
