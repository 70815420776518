import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import UserWishlistLayout1 from "./UserWishlistLayout1";
import UserWishlistLayout2 from "./UserWishlistLayout2";
import NotFoundWishlist from "../../data-loader/NotFoundWishlist";
import { findWishlistItemAction } from "../../../redux-service/actions/action";
import secureLocalStorage from "react-secure-storage";

export default function UserWishlistIndex() {
  // <!----- props data ----------------------------------------!>
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const dispatch = useDispatch();

  // <!----- state define ----------------------------------------!>
  let [listView, setListView] = useState(false);

  const getWishList = useSelector(
    (state) => state.userWishlistReducer.userWishlistData
  );

  useEffect(() => {
    if (userID) {
      dispatch(findWishlistItemAction(userID)); // Dispatch the action to fetch user data
    }
  }, [dispatch, userID]);

  return (
    <>
      <div className="section-padding">
        {getWishList && getWishList.length > 0 ? (
          <>
            <div className="d-flex flex-row gap-3 justify-content-between align-items-center mb-4">
              <div className="d-flex align-items-center gap-2">
                <div
                  className={"sm-icon" + (listView ? "" : " active")}
                  onClick={() => setListView(false)}
                >
                  <Icon.ListTask size={20} />
                </div>
                <div
                  className={"sm-icon" + (listView ? " active" : "")}
                  onClick={() => setListView(true)}
                >
                  <Icon.UiChecksGrid size={18} />
                </div>
              </div>
            </div>
            {listView ? (
              <UserWishlistLayout1 wishListData={getWishList} />
            ) : (
              <>
                <UserWishlistLayout2 wishListDataL2={getWishList} />
              </>
            )}
          </>
        ) : (
          <NotFoundWishlist />
        )}
      </div>
    </>
  );
}
