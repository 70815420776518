import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

export default function FormLoaderBtn({
  btnTitle,
  btnLoaderStatus = 0,
  isBtnLoader = false,
  onBtnLoaderStatus = () => {},
}) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onBtnLoaderStatus(0);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isBtnLoader, onBtnLoaderStatus]);

  let btnClass = "btn btn-primary";
  let iconClass = "";
  let disabled = false;

  if (btnLoaderStatus === 1) {
    btnClass = "btn btn-success";
    iconClass = "fa-regular fa-circle-check";
    disabled = true;
  } else if (btnLoaderStatus === 2) {
    btnClass = "btn btn-danger";
    iconClass = "fa-regular fa-circle-xmark";
    disabled = true;
  }

  return (
    <>
      <button
        type="submit"
        className={btnClass}
        disabled={isBtnLoader || disabled}
      >
        {!isBtnLoader ? (
          <>
            {btnTitle || "No title"}
            {iconClass && <i className={iconClass}></i>}
          </>
        ) : (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </button>
    </>
  );
}
