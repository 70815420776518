import React, { useState } from "react";
import { Helmet } from "react-helmet";

export default function ChangePassword() {
  // <!----- state define ----------------------------------------!>
  const [inputData, setInputData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const { newPassword, confirmPassword } = inputData;

  // <!----- input handler ----------------------------------------!>
  function inputHandler(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  // <!----- form handler ----------------------------------------!>
  function formHandler(e) {
    e.preventDefault();
  }

  return (
    <>
      <Helmet>
        <title>Change Password - ShotCart</title>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-xl-4">
          <div className="form-box">
            <h1 className="h2 text-center mb-4">Change Password</h1>
            <form onSubmit={formHandler}>
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="new-password">New Password</label>
                  <input
                    value={newPassword}
                    name="newPassword"
                    id="new-password"
                    className="form-control"
                    onChange={inputHandler}
                  />
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="comfirm-password">Confirm Password</label>
                  <input
                    value={confirmPassword}
                    name="confirmPassword"
                    id="comfirm-password"
                    className="form-control"
                    onChange={inputHandler}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center flex-column flex-sm-row gap-3">
                  <button type="submit" className="btn btn-primary">
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
