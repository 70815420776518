import { handleApiError } from "../components/core/CommonFunction";
import axios from "../config/NodeAxios";
import AuthService from "./AuthService";

export class KeywordService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();

    this.endpoints = {
      FIND_BY_DROPDOWN_ID: "/product/keyword/public/dropdown/find/:id", // public
    };
  }

  async getKeywordByDropdownId(id) {
    try {
      const response = await axios.get(
        this.endpoints.FIND_BY_DROPDOWN_ID.replace(":id", id)
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
