import { JsonData } from "../../../JsonData";
import { CartService } from "../../services/CartService";
import { CustomerService } from "../../services/CustomerService";
import { WishlistService } from "../../services/WishlistService";
import {
  SET_USER_DATA,
  USER_CART_LIST,
  CLEAR_USER_CART_LIST,
  CLEAR_USER_WISHLIST,
  USER_WISHLIST,
  SEARCH_PRODUCT_RESULTS,
  SEARCH_PRODUCT_RESULTS_RESETALL,
  HEADER_CART_MENU_TOGGLE,
  HEADER_CART_MENU_CLOSE,
  USER_CART_CHARGES,
  MAIN_PRODUCT_LIST,
  MAIN_PRODUCT_LIST_PAGINATION,
  MAIN_PRODUCT_LIST_LOADER,
  MAIN_PRODUCT_LIST_IS_FILTER,
} from "../constants"; // constants define

// <!----- user data save - if user id action call ----------------------------------------!>
export const setUserDataAction = (item) => {
  return async (dispatch) => {
    try {
      const resp = await new CustomerService().showCustomer(item);
      const result = resp?.data;
      if (result.status === true) {
        const getUserData = result.result;
        dispatch({
          type: SET_USER_DATA,
          data: getUserData,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_USER_DATA,
        data: error.message,
      });
    }
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const findCartItemAction = (id) => {
  return async (dispatch) => {
    try {
      const resp = await new CartService().getCustomerCartList(id);
      const result = resp?.data;
      if (result) {
        const getUserCartData = result.result;
        dispatch({
          type: USER_CART_LIST,
          data: getUserCartData,
        });
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };
};

export const setUserCartCharges = (payload) => {
  return {
    type: USER_CART_CHARGES,
    data: payload,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const clearCartItemAction = () => {
  return {
    type: CLEAR_USER_CART_LIST,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const findWishlistItemAction = (userID) => {
  return async (dispatch) => {
    try {
      const resp = await new WishlistService().getUserWishlist(userID);
      const result = resp.data;
      if (resp?.data) {
        const getUserWishlistData = result.result;
        dispatch({
          type: USER_WISHLIST,
          data: getUserWishlistData,
        });
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const clearWishlistItemAction = () => {
  return {
    type: CLEAR_USER_WISHLIST,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const searchProductResultsAction = (item) => {
  return {
    type: SEARCH_PRODUCT_RESULTS,
    data: item,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const searchProductResetAllAction = () => {
  return {
    type: SEARCH_PRODUCT_RESULTS_RESETALL,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const headerCartMenuToggleAction = () => {
  return {
    type: HEADER_CART_MENU_TOGGLE,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const headerCartMenuCloseAction = () => {
  return {
    type: HEADER_CART_MENU_CLOSE,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const mainProductList = (item) => {
  return {
    type: MAIN_PRODUCT_LIST,
    data: item,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const mainProductListPagination = (item) => {
  return {
    type: MAIN_PRODUCT_LIST_PAGINATION,
    data: item,
  };
};

// <!----- user data save - if user id action call ----------------------------------------!>
export const mainProductListLoader = (item) => {
  return {
    type: MAIN_PRODUCT_LIST_LOADER,
    data: item,
  };
};

export const mainProductListIsFilter = (item) => {
  return {
    type: MAIN_PRODUCT_LIST_IS_FILTER,
    data: item,
  };
};
