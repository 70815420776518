export function DateAndTimeFormat(date) {
  const createdAtDate = new Date(date);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true, // Use 24-hour format
  };

  return createdAtDate.toLocaleDateString("en-US", options);
}

export function UPSDateFormat(date) {
  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);

  const dateObject = new Date(`${year}-${month}-${day}`);

  const formattedDate = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
}

export function UPSTimeFormat(time) {
  const hours = time.substr(0, 2);
  const minutes = time.substr(2, 2);
  const seconds = time.substr(4, 2);

  const timeObject = new Date();
  timeObject.setHours(parseInt(hours));
  timeObject.setMinutes(parseInt(minutes));
  timeObject.setSeconds(parseInt(seconds));

  const formattedTime = timeObject.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  });

  return formattedTime;
}
