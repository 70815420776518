import React from "react";
import { NavLink } from "react-router-dom";

export default function ProceedToBuyBtn() {
  return (
    <>
      <NavLink to="/order/checkout" className="btn btn-primary">
        Proceed to Buy
        <i className="fa-solid fa-arrow-right-long"></i>
      </NavLink>
    </>
  );
}