import secureLocalStorage from "react-secure-storage";
import { JsonData } from "../../../JsonData";

export default function IsAuthenticated() {
  if (JsonData.testMode) {
    return true;
  }

  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;

  return !!userID;
}
