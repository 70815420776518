import React from "react";
import { NavLink } from "react-router-dom";
import { MAIN_APP_URL } from "../../config/Config";

export default function ContactUsBelt() {
  return (
    <>
      <div
        className="overflow-hidden"
        style={{ zIndex: 1, position: "relative" }}
      >
        <section className="custom-container footer-half-color">
          <div className="contact-us-belt d-flex flex-column align-items-start flex-lg-row justify-content-lg-between">
            <h3 className="mb-0">Ready to get started? Talk to us today</h3>
            <NavLink to={`${MAIN_APP_URL}/contact`} className="btn btn-white">
              Get Started
            </NavLink>
          </div>
        </section>
      </div>
    </>
  );
}
