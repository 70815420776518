import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AuthService from "../../../services/AuthService";
import { setUserDataAction } from "../../../redux-service/actions/action";
import { UPSservice } from "../../../services/UPSservice";
import FormLoaderBtn from "../../button/FormLoaderBtn";
import { countriesDropdown, statesDropdown } from "../../../json-data/Dropdown";

export default function CustomerDetailsForm({ tabChange }) {
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const [inputData, setInputData] = useState({
    customer_email: "",
    customer_name: "",
    customer_phone_number: "",
    customer_company: "",
    customer_address: "",
    customer_secondary_address: "",
    customer_city: "",
    customer_country: "",
    customer_state: "",
    customer_zip_code: "",
  });

  const {
    customer_email,
    customer_name,
    customer_phone_number,
    customer_company,
    customer_address,
    customer_secondary_address,
    customer_city,
    customer_country,
    customer_state,
    customer_zip_code,
  } = inputData;

  const [countryListDropdown, setCountryListDropdown] = useState([]);
  const [stateListDropdown, setStateListDropdown] = useState([]);

  const [btnLoaderStatus, setBtnLoaderStatus] = useState(0);
  const [isBtnLoader, setIsBtnLoader] = useState(false);

  const userID = AuthService.getUserId();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer.userData);

  async function formHandler(data) {
    try {
      setIsBtnLoader(true);

      const payload = {
        customer_email: customer_email,
        customer_name: data.customer_name,
        customer_phone_number: customer_phone_number,
        customer_company: data.customer_company,
        customer_address: data.customer_address,
        customer_secondary_address: customer_secondary_address,
        customer_city: data.customer_city,
        customer_country: data.customer_country,
        customer_state: data.customer_state,
        customer_zip_code: data.customer_zip_code,
      };

      const response = await new UPSservice().createShippingAddress(
        userID,
        payload
      );

      if (response.data) {
        const { success, message } = response.data;

        if (success) {
          toast.success(message, {
            icon: <i className="fa-solid fa-check"></i>,
          });
          setIsBtnLoader(false);
          setBtnLoaderStatus(1);
          dispatch(setUserDataAction(userID));
          setTimeout(() => {
            tabChange(2);
          }, 1000);
        } else {
          toast.error(message, {
            icon: <i className="fa-solid fa-exclamation-triangle"></i>,
          });
          setIsBtnLoader(false);
          setBtnLoaderStatus(2);
        }
      }
    } catch (error) {
      console.error("Address validation error:", error.message);
      setIsBtnLoader(false);
      setBtnLoaderStatus(2);
    }
  }

  useState(() => {
    setCountryListDropdown(countriesDropdown);
    setStateListDropdown(statesDropdown);
  }, [countriesDropdown.lenght, statesDropdown.length]);

  useEffect(() => {
    dispatch(setUserDataAction(userID)); // Dispatch the action to fetch user data
  }, [dispatch, userID]);

  const userShipping = userData?.user_shipping?.shipment_address;
  useEffect(() => {
    if (userData) {
      if (!userShipping) {
        const getData = {
          ...inputData,
          customer_name: userData?.user_name,
          customer_email: userData?.user_email,
          customer_phone_number: userData?.user_mobile_number,
        };
        setInputData(getData);
        setValue("customer_name", userData?.user_name);
        setValue("customer_email", userData?.user_email);
        setValue("customer_phone_number", userData?.user_mobile_number);
      } else {
        const userShipping = userData?.user_shipping?.shipment_address;

        const getData = {
          ...inputData,
          customer_name: userShipping?.customer_name,
          customer_email: userData?.user_email,
          customer_phone_number: userShipping?.customer_phone_number,
          customer_address: userShipping?.customer_address,
          customer_secondary_address: userShipping?.customer_secondary_address,
          customer_company: userShipping?.customer_company,
          customer_city: userShipping?.customer_city,
          customer_country: userShipping?.customer_country,
          customer_state: userShipping?.customer_state,
          customer_zip_code: userShipping?.customer_zip_code,
        };
        setInputData(getData);
        setValue("customer_name", userShipping?.customer_name);
        setValue("customer_email", userData?.user_email);
        setValue("customer_phone_number", userShipping?.customer_phone_number);
        setValue("customer_address", userShipping?.customer_address);
        setValue(
          "customer_secondary_address",
          userShipping?.customer_secondary_address
        );
        setValue("customer_company", userShipping?.customer_company);
        setValue("customer_city", userShipping?.customer_city);
        setValue("customer_country", userShipping?.customer_country);
        setValue("customer_state", userShipping?.customer_state);
        setValue("customer_zip_code", userShipping?.customer_zip_code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.user_email, userData]);

  return (
    <>
      <h4 className="mb-4">Customer Details</h4>
      <form onSubmit={handleSubmit(formHandler)}>
        <div className="row">
          <div className="col-lg-12 form-group">
            <label htmlFor="customer_email">
              <b>Contact Information</b>
            </label>
            {errors.customer_email && (
              <small className="error">{errors.customer_email.message}</small>
            )}
            <input
              type="text"
              className="form-control"
              id="customer_email"
              placeholder="Enter your email"
              defaultValue={customer_email}
              {...register("customer_email", {
                required: "Email is required",
              })}
              aria-invalid={errors.customer_email ? "true" : "false"}
              disabled
            />
          </div>
          <div className="col-12 mb-4">
            <b style={{ fontSize: "22px" }}>Shipping Address</b>
          </div>
          <div className="col-lg-6 form-group">
            <label htmlFor="customer_name">
              Full Name <sup>*</sup>
            </label>
            {errors.customer_name && (
              <small className="error">{errors.customer_name.message}</small>
            )}
            <input
              type="text"
              className="form-control"
              id="customer_name"
              placeholder="Enter your full name"
              defaultValue={customer_name}
              {...register("customer_name", {
                required: "Name is required",
              })}
              aria-invalid={errors.customer_name ? "true" : "false"}
            />
          </div>
          <div className="col-lg-6 form-group">
            <label htmlFor="customer_phone_number">
              Phone Number (optional)
            </label>
            <input
              type="text"
              className="form-control"
              id="customer_phone_number"
              placeholder="Enter your phone number"
              value={customer_phone_number}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  customer_phone_number: e.target.value,
                })
              }
            />
          </div>
          <div className="col-lg-12 form-group">
            <label htmlFor="customer_company">
              Company <sup>*</sup>
            </label>
            {errors.customer_company && (
              <small className="error">{errors.customer_company.message}</small>
            )}
            <input
              type="text"
              className="form-control"
              id="customer_company"
              placeholder="Enter your company name"
              defaultValue={customer_company}
              {...register("customer_company", {
                required: "Company required for business addresses",
              })}
              aria-invalid={errors.customer_company ? "true" : "false"}
            />
          </div>
          <div className="col-12 form-group">
            <label htmlFor="customer_address">
              Address <sup>*</sup>
            </label>
            {errors.customer_address && (
              <small className="error">{errors.customer_address.message}</small>
            )}
            <input
              type="text"
              className="form-control"
              id="customer_address"
              placeholder="Enter your address"
              defaultValue={customer_address}
              {...register("customer_address", {
                required: "Address is required",
              })}
              aria-invalid={errors.customer_address ? "true" : "false"}
            />
          </div>
          <div className="col-lg-12 form-group">
            <label htmlFor="customer_secondary_address">
              Apartment, suite, etc. (optional)
            </label>
            <input
              type="text"
              className="form-control"
              id="customer_secondary_address"
              placeholder="Enter your apartment, suite, etc."
              value={customer_secondary_address}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  customer_secondary_address: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12 form-group">
            <label htmlFor="customer_city">
              City <sup>*</sup>
            </label>
            {errors.customer_city && (
              <small className="error">{errors.customer_city.message}</small>
            )}
            <input
              type="text"
              className="form-control"
              id="customer_city"
              placeholder="Enter your city"
              defaultValue={customer_city}
              {...register("customer_city", {
                required: "City is required",
              })}
              aria-invalid={errors.customer_city ? "true" : "false"}
            />
          </div>
          <div className="col-lg-4 form-group">
            <label htmlFor="customer_country">
              Country/region <sup>*</sup>
            </label>
            {errors.customer_country && (
              <small className="error">{errors.customer_country.message}</small>
            )}
            <div className="select-wrapper">
              <select
                name="customer_country"
                id="customer_country"
                className="form-control"
                defaultValue={customer_country}
                {...register("customer_country", {
                  required: "Country is required",
                })}
                aria-invalid={errors.customer_country ? "true" : "false"}
              >
                <option value="" hidden>
                  -- select --
                </option>
                {countryListDropdown?.length > 0 &&
                  countryListDropdown.map((eleVal, eleIndex) => (
                    <option key={`country_dropdown_${eleIndex}`} value={eleVal}>
                      {eleVal}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-lg-4 form-group">
            <label htmlFor="customer_state">
              State <sup>*</sup>
            </label>
            {errors.customer_state && (
              <small className="error">{errors.customer_state.message}</small>
            )}
            <div className="select-wrapper">
              <select
                name="customer_state"
                id="customer_state"
                className="form-control"
                defaultValue={customer_state}
                {...register("customer_state", {
                  required: "State is required",
                })}
                aria-invalid={errors.customer_state ? "true" : "false"}
              >
                <option value="" hidden>
                  -- select --
                </option>
                {stateListDropdown?.length > 0 &&
                  stateListDropdown.map((eleVal, eleIndex) => (
                    <option key={`state_dropdown_${eleIndex}`} value={eleVal}>
                      {eleVal}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-lg-4 form-group">
            <label htmlFor="customer_zip_code">
              Zip Code <sup>*</sup>
            </label>
            {errors.customer_zip_code && (
              <small className="error">
                {errors.customer_zip_code.message}
              </small>
            )}
            <input
              type="text"
              className="form-control"
              id="customer_zip_code"
              placeholder="Enter your zip code"
              defaultValue={customer_zip_code}
              {...register("customer_zip_code", {
                required: "Zip Code is required",
              })}
              aria-invalid={errors.customer_zip_code ? "true" : "false"}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            {userShipping ? (
              <>
                <button type="submit" className="btn btn-primary me-3">
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => tabChange(2)}
                >
                  Next
                </button>
              </>
            ) : (
              <FormLoaderBtn
                btnTitle="Go to payment"
                btnLoaderStatus={btnLoaderStatus}
                isBtnLoader={isBtnLoader}
                onBtnLoaderStatus={(eleStatus) => {
                  setBtnLoaderStatus(eleStatus);
                }}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
}
