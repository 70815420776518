export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_TO_CART = "REMOVE_TO_CART";
export const DECREMENT_ITEM = "DECREMENT_ITEM";
export const PRODUCT_CATEGORY_WISH_FILTER = "PRODUCT_CATEGORY_WISH_FILTER";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_TO_WISHLIST = "REMOVE_TO_WISHLIST";
export const SET_USER_DATA = "SET_USER_DATA";
export const USER_CART_LIST = "USER_CART_LIST";
export const USER_CART_CHARGES = "USER_CART_CHARGES";
export const CLEAR_USER_CART_LIST = "CLEAR_USER_CART_LIST";
export const USER_WISHLIST = "USER_WISHLIST";
export const CLEAR_USER_WISHLIST = "CLEAR_USER_WISHLIST";
export const SEARCH_PRODUCT_RESULTS = "SEARCH_PRODUCT_RESULTS";
export const SEARCH_PRODUCT_RESULTS_RESETALL =
  "SEARCH_PRODUCT_RESULTS_RESETALL";
export const HEADER_CART_MENU_TOGGLE = "HEADER_CART_MENU_TOGGLE";
export const HEADER_CART_MENU_CLOSE = "HEADER_CART_MENU_CLOSE";
export const MAIN_PRODUCT_LIST = "MAIN_PRODUCT_LIST";
export const MAIN_PRODUCT_LIST_PAGINATION = "MAIN_PRODUCT_LIST_PAGINATION";
export const MAIN_PRODUCT_LIST_LOADER = "MAIN_PRODUCT_LIST_LOADER";
export const MAIN_PRODUCT_LIST_IS_FILTER = "MAIN_PRODUCT_LIST_IS_FILTER";
