import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { KeywordService } from "../../services/KeywordService";
import { JsonData } from "../../../JsonData";

export default function MultipleSelectCheckmarks({
  productDropdown_id,
  inputName,
  fetchChildData,
  selectedValues,
  required,
}) {
  // state define
  const [selectValue, setSelectValue] = useState([]);
  const [data, setData] = useState(null);

  // input handling
  const inputHandler = (e) => {
    const { value } = e.target;
    setSelectValue(typeof value === "string" ? value.split(",") : value);
  };

  // send data to parent
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function sendDataToParent() {
    if (inputName) {
      fetchChildData(inputName, selectValue); // Use the inputName as the key
    }
  }

  // This useEffect is triggered whenever the 'selectValue' changes.
  useEffect(() => {
    sendDataToParent();
  }, [inputName, fetchChildData, sendDataToParent]);

  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      setSelectValue(selectedValues);
    }
  }, [selectedValues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getApiServiceHandler() {
    if (productDropdown_id) {
      try {
        const response = await new KeywordService().getKeywordByDropdownId(
          productDropdown_id
        );

        if (response?.data?.result) {
          setData(response?.data?.result);
        }
      } catch (error) {
        console.error(JsonData?.messages?.errors?.read + " " + error);
      }
    }
  }

  useEffect(() => {
    getApiServiceHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <div>
      <label>
        {data.productDropdown_name} {required && <sup>*</sup>}
      </label>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          name={inputName && inputName}
          multiple
          value={selectValue}
          onChange={inputHandler}
          renderValue={(selected) => selected.join(", ")}
          className="form-control-mui"
        >
          {data.productDropdown_options &&
          data.productDropdown_options.length > 0 ? (
            data.productDropdown_options.map((value, index) => (
              <MenuItem key={index} value={value}>
                <Checkbox checked={selectValue.indexOf(value) > -1} />
                <ListItemText primary={value} />
              </MenuItem>
            ))
          ) : (
            <div>
              <MenuItem hidden>
                <ListItemText primary="no data found" />
              </MenuItem>
            </div>
          )}
        </Select>
      </FormControl>
    </div>
  ) : null;
}
