import React from 'react'
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { SERVER_URL } from '../config/Config';

export default function OverviewSliderBlock({ blockData }) {

  if (!blockData) {
    return null;
  }

  const images = blockData?.cmpBlock_overview_slider_images;

  return (
    <>
      <section className="bg-dark section-padding overflow-hidden">
        <div className="custom-container">
          <Swiper
            slidesPerView={1.1}
            speed={600}
            spaceBetween={15}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 1.2,
                spaceBetween: 25,
              },
              1200: {
                slidesPerView: 1.4,
                spaceBetween: 50,
              },
            }}
            modules={[Navigation, Autoplay]}
            centeredSlides
            loop
            id="gallery-swiper"
            className="bg-shadow-lr bg-shadow-dark"
          >
            {images &&
              images.map((value, index) => (
                <SwiperSlide key={`${blockData?.cmpBlock_id}_${index}`}>
                  <div className="gallery-box">
                    <img src={`${SERVER_URL}${value}`} alt={`gallery ${index+1}`} className="img-fluid" />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </section>
    </>
  )
}
