import React from "react";

export default function NotFoundProduct({ data }) {
  return (
    <>
      <div className="d-flex flex-column align-items-center gap-3 text-center bg-loader">
        <h3 className="mb-0">
          {data && data.heading ? data.heading : "No Products"}
        </h3>
      </div>
    </>
  );
}
