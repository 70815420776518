import { handleApiError } from "../../../components/core/CommonFunction";
import axios from "../../../config/NodeAxios";

export class StripeService {
  constructor() {
    this.endpoints = {
      CHECKOUT: "payment/stripe/checkout/:orderId",
      NET_30: "payment/stripe/net-30/create/:orderId",
      BANK_TRANSFER: "payment/stripe/bank-transfer/create/:orderId",
    };

    this.config = {};
    this.config.headers = {
      "content-type": "application/json",
    };
  }

  async checkoutWithCreditCard(orderId) {
    try {
      const response = await axios.post(
        this.endpoints.CHECKOUT?.replace(":orderId", orderId),
        {},
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async createNet30Invoice(orderId) {
    try {
      const response = await axios.post(
        this.endpoints.NET_30?.replace(":orderId", orderId),
        {},
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async createBankTransfer(orderId) {
    try {
      const response = await axios.post(
        this.endpoints.BANK_TRANSFER?.replace(":orderId", orderId),
        {},
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
