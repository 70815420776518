import React from "react";
import { imageBaseUrl } from "../../config/Config";

export default function NotFoundOrder() {
  return (
    <>
      <div className="d-flex flex-column align-items-center gap-3 text-center bg-loader">
        <img
          src={`${imageBaseUrl}/icons/empty-cart.svg`}
          alt="Empty Wishlists"
          className="img-fluid not-found-img"
        />
       <h3 className="h4">No Order Found</h3>
      </div>
    </>
  );
}
