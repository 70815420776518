/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearCartItemAction,
  clearWishlistItemAction,
} from "../../../redux-service/actions/action";
import AuthService from "../../../services/AuthService";
import secureLocalStorage from "react-secure-storage";

export default function LogOut() {
  const navigate = useNavigate();
  const userAuth = secureLocalStorage.getItem("user_auth");

  const dispatch = useDispatch();

  function logOutHandler() {
    toast.success("You have been successfully logged out.", {
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
    });
    AuthService.logOut(navigate);
  }

  useEffect(() => {
    if (window.location.pathname === "/user/logout" && userAuth) {
      logOutHandler();
      dispatch(clearCartItemAction());
      dispatch(clearWishlistItemAction());
    }
  }, [dispatch, userAuth]);

  return null;
}
