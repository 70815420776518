import AuthService from "./services/AuthService";
import axios from "./config/NodeAxios";
import { CustomerService } from "./services/CustomerService";

function runInterceptor() {
  axios.interceptors.request.use(
    function (config) {
      const getUser = AuthService.getUser();
      const authorizationToken = AuthService.getAuthToken();
      if (authorizationToken) {
        config.headers = {
          ...config.headers,
          authorization: `${authorizationToken}`,
        };
      }

      if (getUser) {
        const payload = {
          name: getUser?.user_name,
          email: getUser?.user_email,
        };

        let Requestby = encodeURIComponent(
          btoa(`${payload?.name || ""} (${payload.email || ""})`)
        );

        // send user info to the server as request header
        config.headers = {
          ...config.headers,
          Requestby,
        };
      }

      let Requesturl = encodeURIComponent(btoa(getCurrentUrl()));

      // send user info to the server as request header
      config.headers = {
        ...config.headers,
        Requesturl,
      };

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  let isRefreshing = false;
  let refreshPromise = null;

  axios.interceptors.response.use(
    function (response) {
      return response;
    },

    async function (error) {
      const getUser = AuthService.getUser();
      
      if(!getUser){
        return;
      }
      
      if (error?.response?.status === 403) {
        const originalRequest = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          // Create a new promise to handle the asynchronous token refresh
          refreshPromise = new Promise((resolve, reject) => {
            const userId = AuthService.getUserId();
            new CustomerService()
              .CustomerTokenRefresh(userId)
              .then((response) => {
                if (response?.data?.success) {
                  AuthService.saveToken(response?.data?.user?.token);
                  resolve(response?.data?.user?.token);
                } else {
                  reject();
                }
              })
              .catch((error) => {
                reject(error);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });

          // Background refresh
          try {
            const newToken = await refreshPromise;
            // Retry the original request with the new token
            originalRequest.headers["authorization"] = `${newToken}`;
            return await axios(originalRequest);
          } catch {
            return await Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

function getCurrentUrl() {
  const pathname = window.location.pathname;
  return pathname;
}

export default runInterceptor;
