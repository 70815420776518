import React from "react";
import ProductSkel2 from "../skeleton/ProductSkel2";

export default function MainProductLoader({ limit }) {
  return (
    <>
      <div className="grid-item">
        <div className="product-card-grid-2">
          {Array.from({ length: limit }, (_, index) => (
            <div className="grid-item" key={index}>
              <ProductSkel2 key={index} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
