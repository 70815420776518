import React from "react";
import SectionHeading2 from "../components/heading/SectionHeading2";
import TeamCard from "../components/cards/TeamCard";

export default function TeamGridBlock({ blockData }) {
  if (!blockData) {
    return null;
  }

  return (
    <>
      <section className="section-padding overflow-hidden">
        <div className="custom-container">
          <SectionHeading2
            data={{
              heading: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: blockData?.cmpBlock_team_heading,
                  }}
                ></div>
              ),
            }}
            center
          />
          <div className="row gx-xl-5 gy-4 gy-xl-0 justify-content-center">
            {blockData?.cmpBlock_team_lists &&
              blockData?.cmpBlock_team_lists.length > 0 &&
              blockData?.cmpBlock_team_lists.map((value, index) => (
                <div className="col-sm-6 col-md-4 col-xxl-3">
                  <TeamCard teamData={{ ...value }} />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
