import { handleApiError } from "../components/core/CommonFunction";
import axios from "../config/NodeAxios";
import AuthService from "./AuthService";

export class PageService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();

    this.endpoints = {
      LIST: "/page/public/lists", // public
      SHOW: "/page/public/:id", // public
      DROPDOWN: "/page/public/dropdown", // public
    };
  }

  async getAllPages() {
    try {
      const response = await axios.get(this.endpoints.LIST);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getPageDropdown() {
    try {
      const response = await axios.get(this.endpoints.DROPDOWN);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async showPage(id) {
    try {
      const response = await axios.get(this.endpoints.SHOW.replace(":id", id));
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
