import React, { useState } from "react";
import { Accordion, Dropdown, Nav, Tab } from "react-bootstrap";
import SectionHeading1 from "../components/heading/SectionHeading1";

export default function FaqTabbedAccordionBlock({ blockData }) {
  const [tabList, setTabList] = useState([
    "Product Insights",
    "Order Queries",
    "General Inquiries",
  ]);

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <section className="section-padding">
        <div className="custom-container">
          <SectionHeading1 data={{ heading: "FAQS" }} />
          <div className="faqs-tab">
            <Tab.Container defaultActiveKey="0">
              <Dropdown className="mobile-tab-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {tabList[currentTab]}
                  <i className="fa-solid fa-chevron-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tabList &&
                    tabList.map((navValue, navIndex) => (
                      <Nav.Link
                        eventKey={navIndex}
                        onClick={() => setCurrentTab(navIndex)}
                      >
                        {navValue}
                      </Nav.Link>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Nav className="d-none d-lg-flex">
                {tabList &&
                  tabList.map((navValue, navIndex) => (
                    <Nav.Item>
                      <Nav.Link eventKey={navIndex}>{navValue}</Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="0">
                  <div className="tab-body">
                    <Accordion
                      defaultActiveKey={["0"]}
                      className="faq-accordion"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          What is Shape ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What is Type ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          How to use Shape?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="1">
                  <div className="tab-body">
                    <Accordion
                      defaultActiveKey={["0"]}
                      className="faq-accordion"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          What is Shape ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What is Type ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          What is Coating What is Coating What is Coating What
                          is Coating What is Coating ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div className="tab-body">
                    <Accordion
                      defaultActiveKey={["0"]}
                      className="faq-accordion"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          What is Shape ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          What is Type ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          What is Coating What is Coating What is Coating What
                          is Coating What is Coating ?
                          <i className="fa-solid fa-chevron-down accordion-icon"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
}
