import {
  CLEAR_USER_CART_LIST,
  CLEAR_USER_WISHLIST,
  HEADER_CART_MENU_CLOSE,
  HEADER_CART_MENU_TOGGLE,
  MAIN_PRODUCT_LIST,
  MAIN_PRODUCT_LIST_IS_FILTER,
  MAIN_PRODUCT_LIST_LOADER,
  MAIN_PRODUCT_LIST_PAGINATION,
  SEARCH_PRODUCT_RESULTS,
  SEARCH_PRODUCT_RESULTS_RESETALL,
  SET_USER_DATA,
  USER_CART_CHARGES,
  USER_CART_LIST,
  USER_WISHLIST,
} from "../constants";

const initialState = {
  userData: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };
    default:
      return state;
  }
};

const cartInitialState = {
  userCartData: [],
  userCartCharges: {},
};

export const userCartReducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case USER_CART_LIST:
      return {
        ...state,
        userCartData: action.data,
      };

    case USER_CART_CHARGES:
      return {
        ...state,
        userCartCharges: action.data,
      };

    case CLEAR_USER_CART_LIST:
      return {
        ...state,
        userCartData: [],
      };

    default:
      return state;
  }
};

const wishlistInitialState = {
  userWishlistData: [],
};

export const userWishlistReducer = (state = wishlistInitialState, action) => {
  switch (action.type) {
    case USER_WISHLIST:
      return {
        ...state,
        userWishlistData: action.data,
      };

    case CLEAR_USER_WISHLIST:
      return {
        ...state,
        userWishlistData: [],
      };

    default:
      return state;
  }
};

const productInitialState = {
  productSearchData: [],
  productSearchDataStatus: false,
  productMainList: [],
  productMainListPagination: null,
  productMainListLoader: false,
  productMainListIsFilter: false,
};

export const productReducer = (state = productInitialState, action) => {
  switch (action.type) {
    case SEARCH_PRODUCT_RESULTS:
      return {
        ...state,
        productSearchData: action.data,
        productSearchDataStatus: true,
      };

    case SEARCH_PRODUCT_RESULTS_RESETALL:
      return {
        ...state,
        productSearchData: [],
        productSearchDataStatus: false,
      };

    case MAIN_PRODUCT_LIST:
      return {
        ...state,
        productMainList: action.data,
      };

    case MAIN_PRODUCT_LIST_PAGINATION:
      return {
        ...state,
        productMainListPagination: action.data,
      };

    case MAIN_PRODUCT_LIST_LOADER:
      return {
        ...state,
        productMainListLoader: action.data,
      };

    case MAIN_PRODUCT_LIST_IS_FILTER:
      return {
        ...state,
        productMainListIsFilter: action.data,
      };

    default:
      return state;
  }
};

const menuInitialState = {
  cartMenuToggle: false,
};

export const menuHandleReducer = (state = menuInitialState, action) => {
  switch (action.type) {
    case HEADER_CART_MENU_TOGGLE:
      return {
        ...state,
        cartMenuToggle: !state.cartMenuToggle,
      };

    case HEADER_CART_MENU_CLOSE:
      return {
        ...state,
        cartMenuToggle: false,
      };

    default:
      return state;
  }
};
