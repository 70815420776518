import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";
import AuthService from "./AuthService";

export class CartService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();
    this.endpoints = {
      CUSTOMER_CART_LIST: "/cart/customer-cart-list/:id",
      ADD_TO_CART: "/cart/add-to-cart",
      QTY_PLUS_MINUS: "/cart/qty-plus-minus",
      REMOVE_TO_CART: "/cart/remove-to-cart/:userId/:cartId",

      VARIANT_ADD_TO_CART: "/cart/variant-add-to-cart",
      VARIANT_QTY_PLUS_MINUS: "/cart/variant-qty-plus-minus",
      VARIANT_REMOVE_TO_CART: "/cart/variant-remove-to-cart",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.token_config = {
      headers: {
        authorization: this.authorizationToken,
      },
    };
  }

  async getCustomerCartList(id) {
    try {
      const response = await axios.get(
        this.endpoints.CUSTOMER_CART_LIST.replace(":id", id),
        this.token_config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async itemAddToCartList(payload) {
    try {
      const response = await axios.post(
        this.endpoints.ADD_TO_CART,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async itemQtyPlusMinusToCartList(payload) {
    try {
      const response = await axios.post(
        this.endpoints.QTY_PLUS_MINUS,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async itemRemoveToCartList(userId, cartId) {
    try {
      const response = await axios.delete(
        this.endpoints.REMOVE_TO_CART.replace(":userId", userId).replace(
          ":cartId",
          cartId
        )
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async itemVariantAddToCartList(payload) {
    try {
      const response = await axios.post(
        this.endpoints.VARIANT_ADD_TO_CART,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async itemVariantQtyPlusMinusToCartList(payload) {
    try {
      const response = await axios.post(
        this.endpoints.VARIANT_QTY_PLUS_MINUS,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async itemVariantRemoveToCartList(payload) {
    try {
      const response = await axios.post(
        this.endpoints.VARIANT_REMOVE_TO_CART,
        payload,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
