/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import MultipleSelectCheckmarks from "../../../components/form/MultipleSelectCheckmarks";
import KeywordMultipleMachineMakeSelect from "../../../components/form/KeywordMultipleMachineMakeSelect";
import KeywordMultipleMachineModelSelect from "../../../components/form/KeywordMultipleMachineModelSelect";
import { CustomerService } from "../../../services/CustomerService";
import AuthService from "../../../services/AuthService";
import { JsonData } from "../../../../JsonData";

export default function Register() {
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    user_name: "",
    user_email: "",
    user_mobile_number: "",
    user_business_name: "",
    user_password: "",
    user_confirm_password: "",
    user_machine_make_ids: [],
    user_machine_model_ids: [],
    user_sheet_material: [],
    user_sheet_thickness: [],
    user_address: "",
  });

  const {
    user_name,
    user_email,
    user_mobile_number,
    user_business_name,
    user_password,
    user_confirm_password,
    user_address,
    user_machine_make_ids,
  } = inputData;

  const [formErrors, setFormErrors] = useState({
    emailError: { status: false, message: "" },
    passError: { status: false, message: "" },
    passConfirmError: { status: false, message: "" },
    isActiveBtn: false,
  });

  const { emailError, passError, passConfirmError, isActiveBtn } = formErrors;

  function InputHandler(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  function fetchChildHadnler(name, value) {
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  }

  function emailHandler(e) {
    setInputData({ ...inputData, user_email: e.target.value });
    let emailRegEx = /^([a-z0-9.]+)@([a-z]+)(\.[a-z]+)([.a-z]+)?$/;
    if (!emailRegEx.test(e.target.value)) {
      setFormErrors({
        ...formErrors,
        emailError: { status: true, message: "Email is invalid" },
        isActiveBtn: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        emailError: { status: false, message: "" },
        isActiveBtn: false,
      });
    }
  }

  function passwordHandler(e) {
    setInputData({ ...inputData, user_password: e.target.value });
    let passRegEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\w]).{6,}$/;
    if (!passRegEx.test(e.target.value)) {
      setFormErrors({
        ...formErrors,
        passError: {
          status: true,
          message:
            "The password should contain Minimum 6 and Maximum 12 characters at least 1 Uppercase Alphabet, 1 Lowercase Alphabet, 1 Number and 1 Special Character:",
        },
        isActiveBtn: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        passError: { status: false, message: "" },
        isActiveBtn: false,
      });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function confirmpasswordHandler(e, value) {
    const eventValue = value ? value : e.target.value;
    setInputData({ ...inputData, user_confirm_password: eventValue });
    if (user_password !== eventValue) {
      setFormErrors({
        ...formErrors,
        passConfirmError: {
          status: true,
          message: "The Password Not Match With Confirm Password",
        },
        isActiveBtn: true,
      });
    } else {
      setFormErrors({
        ...formErrors,
        passConfirmError: { status: false, message: "" },
        isActiveBtn: false,
      });
    }
  }

  // match user_password with user_confirm_password
  useEffect(() => {
    if (user_password !== "" && user_confirm_password !== "") {
      confirmpasswordHandler(null, user_confirm_password);
    }
  }, [user_password, user_confirm_password]);

  const registerUserApiHandler = async () => {
    try {
      const resp = await new CustomerService().addCustomer(inputData);

      if (resp?.data?.status) {
        toast.success(resp?.data?.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });
        AuthService.registerSuccessNavigate(navigate);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.register + " " + error);
    }
  };

  function formHandler(e) {
    e.preventDefault();
    registerUserApiHandler();
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-11 col-lg-10 col-xl-11 col-xxl-10">
        <div className="form-box">
          <h1 className="h2 mb-25-50 text-center">User Registration</h1>
          <form onSubmit={formHandler}>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-4 form-group">
                <label htmlFor="user_name">
                  Name <sup>*</sup>
                </label>
                <input
                  value={user_name}
                  type="text"
                  id="user_name"
                  name="user_name"
                  className="form-control"
                  onChange={InputHandler}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4 form-group">
                <label htmlFor="user_email">
                  Email <sup>*</sup>
                </label>
                {emailError.status ? (
                  <small className="error">{emailError.message}</small>
                ) : null}
                <input
                  value={user_email}
                  type="user_email"
                  id="user_email"
                  name="user_email"
                  className="form-control"
                  onChange={emailHandler}
                />
              </div>
              <div className="col-lg-6 col-xl-4 form-group">
                <label htmlFor="user_mobile_number">
                  Mobile Number <sup>*</sup>
                </label>
                <input
                  value={user_mobile_number}
                  type="tel"
                  id="user_mobile_number"
                  name="user_mobile_number"
                  className="form-control"
                  onChange={InputHandler}
                />
              </div>
              <div className="col-lg-6 col-xl-4 form-group">
                <label htmlFor="user_business_name">
                  Business Name <sup>*</sup>
                </label>
                <input
                  value={user_business_name}
                  id="user_business_name"
                  type="text"
                  name="user_business_name"
                  className="form-control"
                  onChange={InputHandler}
                />
              </div>
              <div className="col-lg-6 col-xl-4 form-group">
                <KeywordMultipleMachineMakeSelect
                  inputName="user_machine_make_ids"
                  fetchChildData={fetchChildHadnler}
                />
              </div>
              <div className="col-lg-6 col-xl-4 form-group">
                <KeywordMultipleMachineModelSelect
                  inputName="user_machine_model_ids"
                  fetchChildData={fetchChildHadnler}
                  machineMakeIds={user_machine_make_ids}
                />
              </div>
              <div className="col-lg-6 col-xl-6 form-group">
                <MultipleSelectCheckmarks
                  productDropdown_id="DROPDOWN_SHEET_MATERIAL"
                  inputName="user_sheet_material"
                  inputOption={["one", "two", "three"]}
                  fetchChildData={fetchChildHadnler}
                />
              </div>
              <div className="col-lg-6 col-xl-6 form-group">
                <MultipleSelectCheckmarks
                  productDropdown_id="DROPDOWN_SHEET_THICKNESS"
                  inputName="user_sheet_thickness"
                  inputOption={["one", "two", "three"]}
                  fetchChildData={fetchChildHadnler}
                />
              </div>
              <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="user_password">
                  Password <sup>*</sup>
                </label>
                {passError.status ? (
                  <small className="error">{passError.message}</small>
                ) : null}
                <input
                  value={user_password}
                  id="user_password"
                  name="user_password"
                  className="form-control"
                  onChange={passwordHandler}
                />
              </div>
              <div className="col-md-6 col-lg-6 form-group">
                <label htmlFor="user_confirm_password">
                  Confirm Password <sup>*</sup>
                </label>
                {passConfirmError.status ? (
                  <small className="error">{passConfirmError.message}</small>
                ) : null}
                <input
                  value={user_confirm_password}
                  id="user_confirm_password"
                  name="user_confirm_password"
                  className="form-control"
                  onChange={confirmpasswordHandler}
                />
              </div>
              <div className="col-lg-12 form-group">
                <label htmlFor="user_address">
                  Address <sup>*</sup>
                </label>
                <textarea
                  rows={4}
                  value={user_address}
                  className="form-control"
                  name="user_address"
                  onChange={InputHandler}
                ></textarea>
              </div>
              <div className="col-12 d-flex justify-content-center flex-column flex-sm-row gap-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isActiveBtn ? " " : ""}
                >
                  Register
                </button>
                <NavLink to="/user/login" className="btn btn-outline-primary">
                  Login
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}