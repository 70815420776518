import React from "react";
import { SERVER_URL } from "../config/Config";

export default function ImageContentBlock({ blockData }) {
  if (!blockData) {
    return null;
  }

  return (
    <>
      <section className="section-padding p-last-0">
        <div className="custom-container">
          <div
            className={`row p-last-0 gy-4 gy-lg-0 gx-xl-5 align-items-center ${
              blockData?.cmpBlock_image_and_content_position_right
                ? "flex-lg-row-reverse"
                : ""
            }`}
          >
            <div className="col-lg-6">
              <div
                className={`rounded-sm ${
                  blockData?.cmpBlock_image_and_content_border
                    ? "image-and-content-image-border"
                    : ""
                }`}
              >
                <img
                  src={`${SERVER_URL}${blockData?.cmpBlock_image_and_content_image}`}
                  alt={`${blockData?.cmpBlock_image_and_content_heading}`}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              {blockData && blockData?.cmpBlock_image_and_content_heading && (
                <h2 className="highlight-top">
                  {blockData?.cmpBlock_image_and_content_heading}
                </h2>
              )}
              {blockData &&
                blockData?.cmpBlock_image_and_content_sub_heading && (
                  <p className="fs-4">
                    {blockData?.cmpBlock_image_and_content_sub_heading}
                  </p>
                )}
              <div
                dangerouslySetInnerHTML={{
                  __html: blockData?.cmpBlock_image_and_content_content,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
