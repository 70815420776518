import React from "react";

export default function CardSortText({ text, textLength = 0 }) {
  if (!text) {
    return "N/A";
  }

  return (
    <p>
      {text && textLength && text.length > textLength
        ? `${textLength ? text.slice(0, textLength) : text}...`
        : text}
    </p>
  );
}
