import React from "react";

export default function SectionHeading1(props) {
  return (
    <>
      <div className={"section-title-1 " + (props.class && props.class)}>
        <h2 className="display-6 highlight-top">{props.data.heading}</h2>
      </div>
    </>
  );
}