import React from "react";
import IsAuthenticated from "../../auth-check/IsAuthenticated";
import { JsonData } from "../../../../JsonData";
import { CalculatePriceRange } from "./CalculatePriceRange";

export default function CardPrice({ value, className }) {
  if (!value) return null;

  if (!IsAuthenticated())
    return (
      <span className={'warning ' + (className ? className : "price")}>
        Login to view price.
      </span>
    );

  if (value?.product_variants_status) {
    return (
      <>
        <span className={className ? className : "price"}>
          {CalculatePriceRange(value?.product_variants)}
        </span>
      </>
    );
  }

  return (
    <>
      <span className={className ? className : "price"}>
        <sup>{JsonData.CURRENCY_ICON}</sup>
        {value?.product_price}
      </span>
    </>
  );
}
