/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPSservice } from "../../../services/UPSservice";
import { setUserCartCharges } from "../../../redux-service/actions/action";

export default function OrderCheckoutCalc({ chargeType }) {
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);

  const dispatch = useDispatch();

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const subTotalHandler = () => {
    let price = 0;
    getCartData.forEach((value) => {
      price += value.cart_item_total;
    });
    setSubTotal(price);
  };

  async function fetchShippingCharge() {
    try {
      if (subTotal) {
        const payload = {
          orderSubTotal: subTotal,
        };

        const response = await new UPSservice().calculateShippingCharge(
          payload
        );

        if (response.data) {
          const { result } = response.data;

          setShippingCharge(result.shippingCharges);

          console.log("UPS shipping charge:", response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching UPS shipping charge:", error);
    }
  }

  useEffect(() => {
    if (getCartData.length > 0) {
      subTotalHandler();
    }
  }, [getCartData]);

  useEffect(() => {
    fetchShippingCharge();
  }, [subTotal]);

  useEffect(() => {
    let checkoutTotal = 0;
    let userCartCharges = {};

    if (subTotal) {
      checkoutTotal += Number(subTotal);
      userCartCharges.subTotal = Number(subTotal);
    }

    if (shippingCharge) {
      checkoutTotal += Number(shippingCharge);
      userCartCharges.shippingCharge = Number(shippingCharge);
    }
    
    userCartCharges.total = checkoutTotal;
    
    setTotal(checkoutTotal);
    dispatch(setUserCartCharges(userCartCharges));
  }, [shippingCharge, subTotal]);

  switch (chargeType) {
    case "subTotal":
      return subTotal?.toLocaleString(undefined, { minimumFractionDigits: 2 });
    case "shipping":
      return shippingCharge;
    case "total":
      return total?.toLocaleString(undefined, { minimumFractionDigits: 2 });
    default:
      return null;
  }
}
