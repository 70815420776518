import React from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";

export default function FormWrapper() {
  const param = useLocation();
  const title = param.pathname.replaceAll("/user", "").replaceAll("/", " ");

  return (
    <>
      <Helmet>
        <title>{title ? title : "Title not set"}</title>
      </Helmet>
      <main className="custom-container form-page-wrapper-container">
        <div className="form-page-wrapper">
          <Outlet />
        </div>
      </main>
    </>
  );
}
