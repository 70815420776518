import React from "react";
import WishListCard from "../../cards/WishListCard";

export default function UserWishlistLayout2({ wishListDataL2 }) {
  return (
    <>
      <div className="table-responsive scrollbar-sm scrollbar-gray">
        <table className="wishlist-table">
          <tbody>
            {wishListDataL2 &&
              wishListDataL2.map((value, index) => (
                <tr key={index}>
                  <td>
                    <WishListCard value={value} index={index} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
