import React, { Component } from "react";
import AddToCartIndex from "../../components/pages/add-to-cart/AddToCartIndex";
import AuthenticatedRoute from "../../components/auth-check/AuthenticatedRoute";

export default class UserAddToCart extends Component {
  render() {
    return (
      <>
        <AuthenticatedRoute cmp={AddToCartIndex} />
      </>
    );
  }
}
