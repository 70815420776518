import { handleApiError } from "../components/core/CommonFunction";
import axios from "../config/NodeAxios";
import AuthService from "./AuthService";

export class CustomerService {
  constructor() {
    this.authorizationToken = AuthService.getAuthToken();
    this.endpoints = {
      SHOW: "/customer/:id",
      LOGIN: "/customer/login",
      REGISTER: "/customer/register",
      REGISTER_VERIFY: "/customer/verify/register?token=",
      UPDATE: "/customer/update/:id",
      REFRESH_TOKEN: "/customer/refresh_token/:id",
      FORGET_PASSWORD: "/customer/forget-password",
      RESET_PASSWORD: "/customer/reset-password?token=",
      FORGET_TOKNE_VERIFY: "/customer/verify/reset-password?token=",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.config2 = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    this.token_config = {
      headers: {
        authorization: `${this.authorizationToken}`,
      },
    };
  }

  async addCustomer(payload) {
    try {
      return await axios.post(this.endpoints.REGISTER, payload, this.config);
    } catch (error) {
      handleApiError(error);
    }
  }

  async CustomerRegisterVerify(token) {
    try {
      return await axios.get(this.endpoints.REGISTER_VERIFY + token);
    } catch (error) {
      handleApiError(error);
    }
  }

  async loginCustomer(payload) {
    try {
      return await axios.post(this.endpoints.LOGIN, payload, this.config);
    } catch (error) {
      handleApiError(error);
    }
  }

  async forgetPasswordCustomer(payload) {
    try {
      return await axios.post(
        this.endpoints.FORGET_PASSWORD,
        payload,
        this.config
      );
    } catch (error) {
      handleApiError(error);
    }
  }

  async resetPasswordCustomer(token, payload) {
    try {
      return await axios.post(
        this.endpoints.RESET_PASSWORD + token,
        payload,
        this.config
      );
    } catch (error) {
      handleApiError(error);
    }
  }

  async forgetTokenVerifyCustomer(token) {
    try {
      return await axios.get(this.endpoints.FORGET_TOKNE_VERIFY + token);
    } catch (error) {
      handleApiError(error);
    }
  }

  async showCustomer(id) {
    try {
      return await axios.get(
        this.endpoints.SHOW.replace(":id", id),
        this.token_config
      );
    } catch (error) {
      handleApiError(error);
    }
  }

  async updateCustomer(id, payload) {
    try {
      return await axios.put(
        this.endpoints.UPDATE.replace(":id", id),
        payload,
        this.config2
      );
    } catch (error) {
      handleApiError(error);
    }
  }

  async CustomerTokenRefresh(id) {
    try {
      const response = await axios.get(
        this.endpoints.REFRESH_TOKEN.replace(":id", id)
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
