import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SectionHeading1 from "../../heading/SectionHeading1";
import ProductCard3 from "../../cards/ProductCard3";
import SimilarItemsSlider from "./SimilarItemsSlider";
import NotFoundProduct from "../../data-loader/NotFoundProduct";
import { ProductCategoryService } from "../../../services/ProductCategoryService";
import { JsonData } from "../../../../JsonData";

// Create a function to fetch product data by category ID
const fetchProductCateSingle = async (itemID) => {
  try {
    const response = await new ProductCategoryService().getCategorySingle(
      itemID
    );

    if (response.data && response.data.result.length > 0) {
      return response.data.result[0];
    } else {
      console.error("Invalid response format:", response);
      return []; // or throw an error, depending on your requirements
    }
  } catch (error) {
    console.error(JsonData?.messages?.errors?.read + " " + error);
    throw error;
  }
};

const fetchProductCateList = async (itemID) => {
  try {
    const response =
      await new ProductCategoryService().getProductCategoryFindByCategory(
        itemID
      );

    if (response.data && response.data.result) {
      return response.data.result;
    } else {
      console.error("Invalid response format:", response);
      return []; // or throw an error, depending on your requirements
    }
  } catch (error) {
    console.error(JsonData?.messages?.errors?.read + " " + error);
    throw error;
  }
};

// Create a function to fetch similar products
const fetchProductSimilarList = async (parentCategory) => {
  try {
    const response =
      await new ProductCategoryService().getProductCategoryFindByCategory(
        parentCategory
      );

    if (response.data && response.data.result) {
      return response.data.result;
    } else {
      console.error("Invalid response format:" + response);
      return []; // or throw an error, depending on your requirements
    }
  } catch (error) {
    console.error(JsonData?.messages?.errors?.read + " " + error);
    throw error;
  }
};

export default function CategoryList() {
  // get navigate data
  const params = useParams();
  const itemID = params.id;

  // define state
  const [productCateSingle, setProductCateSingle] = useState(null);
  const [productCateList, setProductCateList] = useState([]);
  const [productSimilarList, setProductSimilarList] = useState([]);

  // API - `get product category`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getApiHandler = async () => {
    try {
      const productCateSingleResult = await fetchProductCateSingle(itemID);
      setProductCateSingle(productCateSingleResult);

      const productCateListResult = await fetchProductCateList(itemID);
      setProductCateList(productCateListResult);

      if (productCateSingleResult) {
        const productSimilarListResult = await fetchProductSimilarList(
          productCateSingleResult.parent_category
        );
        const filteredProductSimilarList = productSimilarListResult.filter(
          (ele) => ele._id !== itemID
        );
        setProductSimilarList(filteredProductSimilarList);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  // Use an effect to call the API functions when itemData changes
  useEffect(() => {
    getApiHandler(); // Fetch product data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemID]);

  return (
    <>
      <main className="custom-container">
        <section className="section-padding-sm pb-0">
          <SectionHeading1
            data={{
              heading: productCateSingle && productCateSingle.category_name,
            }}
          />
          <div className="row gy-4 gx-xxl-5 gy-xxl-5">
            {productCateList && productCateList.length > 0 ? (
              productCateList.map((value, index) => (
                <div className="col-md-6 col-lg-3" key={index}>
                  <ProductCard3
                    path={`/product/${productCateSingle.category_name}/${value.category_slug}/${value._id}`}
                    value={value}
                  />
                </div>
              ))
            ) : (
              <div className="col-12">
                <NotFoundProduct />
              </div>
            )}
          </div>
        </section>
        <SimilarItemsSlider
          path={`/product`}
          sliderHeading="Product Similar Items"
          sliderData={productSimilarList}
        />
      </main>
    </>
  );
}
