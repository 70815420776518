import React from "react";
import PageIntro from "../components/pages/PageIntro";

export default function PageIntroBlock({ blockData }) {
  return (
    <>
      <PageIntro
        data={{
          heading: blockData && blockData?.cmpBlock_intro_heading,
          text: (
            <>
              <p>{blockData && blockData?.cmpBlock_intro_sub_heading}</p>
              {blockData && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: blockData?.cmpBlock_intro_content,
                  }}
                ></div>
              )}
            </>
          ),
        }}
      />
    </>
  );
}
