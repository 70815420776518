import React, { useEffect, useState } from "react";
import { imageBaseUrl, SERVER_URL } from "../../config/Config";
import { NavLink } from "react-router-dom";
import AddToCartBtn from "../button/AddToCartBtn";
import WishListBtn from "../button/WishListBtn";
import ProductSkel1 from "../skeleton/ProductSkel1";
import CardPrice from "./helper/CardPrice";

export default function ProductCard1({ value: propsValue }) {
  const [value, setValue] = useState(propsValue);

  useEffect(() => {
    if (propsValue) {
      setTimeout(() => {
        setValue(propsValue);
      }, 500);
    }
  }, [propsValue]);

  const renderImage = () => {
    const imageUrl =
      value.product_images[0] !== ""
        ? `${SERVER_URL}${value.product_images[0]}`
        : `${imageBaseUrl}/product-default-1.png`;
    return (
      <img src={imageUrl} alt={value.product_name} className="img-fluid" />
    );
  };

  const renderProductName = () => {
    return value.product_name.length > 28
      ? `${value.product_name.slice(0, 28)}...`
      : value.product_name;
  };

  return (
    <>
      {value ? (
        <div className="card product-card-1">
          <NavLink to={"/"}>
            <div className="card-image">{renderImage()}</div>
          </NavLink>
          <div className="card-body">
            <div className="card-heading">
              <h3>{renderProductName()}</h3>
              <CardPrice value={value} />
            </div>
            <AddToCartBtn
              classText="mt-3"
              value={value}
              type="ADD_CART_TOGGLE"
            />
          </div>
          <WishListBtn productID={value._id} type="REMOVE_WISHLIST_ICON" />
        </div>
      ) : (
        <ProductSkel1 />
      )}
    </>
  );
}
