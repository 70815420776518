import React from "react";
import { toast } from "react-toastify";
import { findCartItemAction } from "../../redux-service/actions/action";
import { useDispatch } from "react-redux";
import { CartService } from "../../services/CartService";
import { JsonData } from "../../../JsonData";
import secureLocalStorage from "react-secure-storage";

export default function RemoveToCartBtn({ cartItemID, type }) {
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const dispatch = useDispatch();

  const removeToCartHandler = async () => {
    if (!cartItemID) {
      return toast.error("please provide id", {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      });
    }

    try {
      const getData = await new CartService().itemRemoveToCartList(
        userID,
        cartItemID
      );
      const getDataResult = getData.data;

      if (getDataResult) {
        toast.error(getDataResult.message, {
          icon: (
            <i className="fa-solid fa-trash" style={{ fontSize: "14px" }}></i>
          ),
        });
        if (userID) {
          dispatch(findCartItemAction(userID));
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.removeToCart + " " + error);
    }
  };

  const RemoveCartBtnHtml1 = () => {
    return (
      <button
        title="Item Delete"
        type="button"
        className="btn-trash-icon"
        onClick={removeToCartHandler}
      >
        <i className="fa-solid fa-trash"></i>
      </button>
    );
  };

  const RemoveCartBtnHtml2 = () => {
    return (
      <button
        title="Item Delete"
        type="button"
        className="btn-item-remove-icon"
        onClick={removeToCartHandler}
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
    );
  };

  return (
    <>
      {type === "REMOVE_CART_ICON_1" && RemoveCartBtnHtml1(cartItemID)}
      {type === "REMOVE_CART_ICON_2" && RemoveCartBtnHtml2(cartItemID)}
    </>
  );
}
