import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

export default class TreeSkel extends Component {
  render() {
    return (
      <>
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
          <li>
            <Skeleton />
            <ul
              style={{
                listStyleType: "none",
                margin: 0,
                padding: "0 0 0 20px",
              }}
            >
              <li>
                <Skeleton />
                <ul
                  style={{
                    listStyleType: "none",
                    margin: 0,
                    padding: "0 0 0 20px",
                  }}
                >
                  <li>
                    <Skeleton />
                    <ul
                      style={{
                        listStyleType: "none",
                        margin: 0,
                        padding: "0 0 0 20px",
                      }}
                    >
                      <li>
                        <Skeleton />
                      </li>
                      <li>
                        <Skeleton />
                      </li>
                      <li>
                        <Skeleton />
                      </li>
                      <li>
                        <Skeleton />
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Skeleton />
                  </li>
                  <li>
                    <Skeleton />
                  </li>
                  <li>
                    <Skeleton />
                  </li>
                </ul>
              </li>
              <li>
                <Skeleton />
              </li>
              <li>
                <Skeleton />
              </li>
              <li>
                <Skeleton />
              </li>
            </ul>
          </li>
          <li>
            <Skeleton />
          </li>
          <li>
            <Skeleton />
          </li>
        </ul>
      </>
    );
  }
}
