import React from 'react'

export default function ContentBlock({ blockData }) {
  return (
    <>
      <section className="section-padding">
        <div className="custom-container">
          <div className="row gx-lg-5 gy-4 gy-lg-0">
            {<div className="col-12" dangerouslySetInnerHTML={{ __html: blockData?.cmpBlock_content_only_content }}></div>}
          </div>
        </div>
      </section>
    </>
  )
}
