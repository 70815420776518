import React from "react";
import Skeleton from "react-loading-skeleton";

export default function WishListSkel() {
  return (
    <>
      <div className="card card4 flex-row justify-content-between">
        <Skeleton className="card-image" />
        <div className="card-body d-flex flex-row justify-content-between">
          <div className="grid-item">
            <Skeleton width="120px" />
            <Skeleton height={30} />
            <Skeleton count={3} />
            <Skeleton width="120px" height={30} />
          </div>
          <div className="grid-item d-flex flex-column gap-3">
            <Skeleton
              width="230px"
              height={47.19}
              style={{ borderRadius: "35px" }}
            />
            <Skeleton
              width="230px"
              height={47.19}
              style={{ borderRadius: "35px" }}
            />
            <Skeleton
              width="230px"
              height={47.19}
              style={{ borderRadius: "35px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
