import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Banner from "../components/pages/Banner";
import { imageBaseUrl } from "../config/Config";

export default function InnerPageWrapper(props) {
  const ComponentUnder = props.cmpUnder;
  const ComponentOuter = props.cmpOuter;
  const data = props.data;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{data ? data.title : "Title not set"}</title>
        </Helmet>
        <Banner
          className={data.imageClass}
          data={{
            heading: data ? data.heading : "No heading set",
            image: data.image
              ? imageBaseUrl + "/" + data.image
              : "banner-1.jpg",
          }}
        />
        <main className="custom-container">
          {!ComponentOuter ? <ComponentUnder /> : null}
        </main>
        {!ComponentUnder ? <ComponentOuter /> : null}
      </HelmetProvider>
    </>
  );
}
