import React, { useEffect } from "react";
import UserProfileBio from "../../../components/pages/user-profile/UserProfileBio";
import { imageBaseUrl } from "../../../config/Config";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CountUp from "react-countup";
import { setUserDataAction } from "../../../redux-service/actions/action";
import secureLocalStorage from "react-secure-storage";

export default function UserProfile() {
  const navigate = useNavigate();
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData._id;

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer.userData);

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  useEffect(() => {
    dispatch(setUserDataAction(userID)); // Dispatch the action to fetch user data
  }, [dispatch, userID, getCartData.length]);

  return (
    <div className="row justify-content-center align-items-stretch">
      <div className="col-12 col-md-11 col-lg-10 col-xl-11 col-xxl-10">
        <div className="form-box">
          <div className="row mb-4">
            <div className="col-md-8">
              <UserProfileBio />
            </div>
            <div className="col-md-4 d-md-flex justify-content-end align-items-center mt-2 mt-md-0">
              <NavLink to="update" className="btn btn-primary">
                Update your Profile
              </NavLink>
            </div>
          </div>
          <div className="row gy-4 gy-xxl-0">
            <div className="col-md-6 col-xxl-3 stretch-card grid-margin">
              <div
                className="card bg-card-color-3 card-img-holder text-white h-100"
                onClick={() => navigate("/user/order")}
              >
                <div className="card-body">
                  <img
                    src={`${imageBaseUrl}/icons/circle.svg`}
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Your Orders
                    <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-4 mb-lg-5">
                    <CountUp
                      className="text-white"
                      delay={2}
                      end={userData?.user_counts?.order}
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxl-3 stretch-card grid-margin">
              <div
                className="card bg-card-color-2 card-img-holder text-white h-100"
                onClick={() => navigate("/user/wishlist")}
              >
                <div className="card-body">
                  <img
                    src={`${imageBaseUrl}/icons/circle.svg`}
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Your Wishlist
                    <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-4 mb-lg-5">
                    <CountUp
                      className="text-white"
                      delay={2}
                      end={userData?.user_counts?.wishlist}
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxl-3 stretch-card grid-margin">
              <div
                className="card bg-card-color-4 card-img-holder text-white h-100"
                onClick={() => navigate("/user/add-to-cart")}
              >
                <div className="card-body">
                  <img
                    src={`${imageBaseUrl}/icons/circle.svg`}
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Your Cart
                    <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-4 mb-lg-5">
                    <CountUp
                      className="text-white"
                      delay={2}
                      end={userData?.user_counts?.cart}
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxl-3 stretch-card grid-margin">
              <div
                className="card bg-card-color-1 card-img-holder text-white h-100"
                onClick={() => navigate("/user/profile")}
              >
                <div className="card-body">
                  <img
                    src={`${imageBaseUrl}/icons/circle.svg`}
                    className="card-img-absolute"
                    alt="circle"
                  />
                  <h4 className="font-weight-normal mb-3">
                    Your Address
                    <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                  </h4>
                  <h2 className="mb-5 h6">
                    {userData?.user_address?.length > 20
                      ? userData.user_address.slice(0, 20) + "..."
                      : userData?.user_address}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
