/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { OrderService } from "../../services/OrderService";
import OrderCard from "../../components/cards/OrderCard";
import NotFoundOrder from "../../components/data-loader/NotFoundOrder";
import IsAuthenticated from "../../components/auth-check/IsAuthenticated";
import { Navigate } from "react-router-dom";
import { JsonData } from "../../../JsonData";
import secureLocalStorage from "react-secure-storage";

export default function UserOrder() {
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData?._id;
  const [userOrderList, setUserOrderList] = useState([]);
  const getParams = new URLSearchParams(window.location.search);
  const getFocus = getParams.get("focus");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUserOrders = async () => {
    try {
      if (userID) {
        const response = await new OrderService().findUserOrders(userID);
        const result = response?.data?.result || [];
        setUserOrderList(result);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  useEffect(() => {
    fetchUserOrders();
  }, [userID]);

  useEffect(() => {
    const handleScroll = () => {
      if (getFocus) {
        const focusedOrderElement = document.getElementById(getFocus);
        if (focusedOrderElement) {
          console.log("Found element to scroll to:", focusedOrderElement);
          const orderGridElement = document.querySelector(".order-grid");
          if (orderGridElement) {
            console.log("Found parent container:", orderGridElement);
            console.log("Scrolling to:", focusedOrderElement.offsetTop - 120);
            window.scrollTo({
              top: focusedOrderElement.offsetTop - 120,
              behavior: "smooth",
            });
          } else {
            console.error("Parent container not found");
          }
        } else {
          console.error("Element with ID not found:", getFocus);
        }
      }
    };
  
    window.addEventListener("load", handleScroll);
  
    return () => {
      window.removeEventListener("load", handleScroll);
    };
  }, [getFocus]);
  
  

  if (!IsAuthenticated()) {
    return <Navigate to="/user/login" />;
  }

  return (
    <>
      {userOrderList && userOrderList.length > 0 ? (
        <section className="section-padding order-grid">
          {userOrderList.map((value, index) => (
            <div
              key={index}
              className={`order-focus ${
                value?.order_id === getFocus ? "active" : ""
              }`}
              id={value?.order_id}
            >
              <OrderCard key={index} value={value} index={index} />
            </div>
          ))}
        </section>
      ) : (
        <section className="section-padding">
          <div className="custom-container">
            <NotFoundOrder />
          </div>
        </section>
      )}
    </>
  );
}
