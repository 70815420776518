import React from "react";
import { imageBaseUrl } from "../../config/Config";

export default function FooterCol3() {
  return (
    <>
      <div className="grid-item">
        <h4>100% Secure Payment</h4>
        <ul className="footer-payment-icons">
          <li title="Bank">
            <img src={`${imageBaseUrl}/payment-icons/bank.png`} alt="bank" />
          </li>
          <li className="lg" title="Bank Wire">
            <img
              src={`${imageBaseUrl}/payment-icons/bank-wire.png`}
              alt="bank-wire"
            />
          </li>
          <li title="Bank ACH">
            <img
              src={`${imageBaseUrl}/payment-icons/bank-ach.png`}
              alt="bank-ach"
            />
          </li>
          <li title="VISA">
            <img src={`${imageBaseUrl}/payment-icons/visa.png`} alt="visa" />
          </li>
          <li title="Mastercard">
            <img
              src={`${imageBaseUrl}/payment-icons/mastercard.png`}
              alt="mastercard"
            />
          </li>
          <li className="lg" title="NET 30">
            <img
              src={`${imageBaseUrl}/payment-icons/net-30.png`}
              alt="net-30"
            />
          </li>
        </ul>
      </div>
    </>
  );
}
