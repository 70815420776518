import React from "react";
import ProductCard1 from "../../cards/ProductCard1";

export default function UserWishlistLayout1({ wishListData }) {
  return (
    <>
      <div className="wishlist-grid">
        {wishListData.map((value, index) => (
          <div className="grid-item" key={`wishlist_itme_${value._id}`}>
            <ProductCard1 value={value} index={index} />
          </div>
        ))}
      </div>
    </>
  );
}
