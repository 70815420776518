import React from "react";
import NotFoundCart from "../../data-loader/NotFoundCart";
import AddToCartUserBio from "./AddToCartUserBio";
import { useSelector } from "react-redux";
import AddToCartFooterBtn from "./AddToCartFooterBtn";
import CartDropdownIndex from "../../cart/CartDropdownIndex";
import OrderCheckoutTotalView from "../checkout/OrderCheckoutTotalView";

export default function AddToCartIndex() {
  // <!----- get cart data ----------------------------------------!>
  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  return (
    <>
      <div className="user-cart-page section-padding">
        {getCartData.length > 0 ? (
          <div className="user-cart-grid">
            <div className="grid-item d-flex flex-column justify-content-between">
              <div>
                <div className="cart-heading">
                  <h4 className="mb-0">Shopping Lists</h4>
                  <h4 className="mb-0">
                    Item{" "}
                    <span className="text-highlight-color">
                      ({getCartData.length})
                    </span>
                  </h4>
                </div>
                <CartDropdownIndex HideTfoot={true} />
              </div>
              <AddToCartFooterBtn data={{ className: "d-none d-lg-block" }} />
            </div>
            <div className="grid-item">
              <div className="order-summary">
                <div className="cart-heading">
                  <h4 className="mb-0">Order Summary</h4>
                </div>
                <AddToCartUserBio />
                <div className="order-summary-box form-box sm">
                  <OrderCheckoutTotalView />
                </div>
              </div>
              <AddToCartFooterBtn data={{ className: "d-lg-none" }} />
            </div>
          </div>
        ) : (
          <NotFoundCart />
        )}
      </div>
    </>
  );
}
