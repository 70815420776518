import React from "react";
import Skeleton from "react-loading-skeleton";

export default function ProductSingleSkel() {
  return (
    <>
      <section className="section-padding-md">
        <div className="row gx-xxl-5 justify-content-between">
          <div className="col-lg-5 mb-4 mb-lg-0">
            <div className="product-image-container">
              <div className="product-image-main">
                <Skeleton height={400} />
              </div>
              <div className="product-image-sub-row">
                <div className="product-image-sub">
                  <Skeleton width={100} height={100} />
                </div>
                <div className="product-image-sub">
                  <Skeleton width={100} height={100} />
                </div>
                <div className="product-image-sub">
                  <Skeleton width={100} height={100} />
                </div>
                <div className="product-image-sub">
                  <Skeleton width={100} height={100} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="product-details">
              <Skeleton width={200} height={40} className="mb-2" />
              <Skeleton count={3} />
              <hr />
              <Skeleton width={200} height={40} />
              <hr />
              <div className="d-flex flex-column flex-lg-row gap-3">
                <div>
                  <Skeleton className="btn" />
                </div>
                <div>
                  <Skeleton className="btn" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
