import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ProductCategoryService } from "../../services/ProductCategoryService";
import { JsonData } from "../../../JsonData";

export default function FooterCol2() {
  const [productCateData, setProductCateData] = useState([]);

  const getApiHandler = async () => {
    try {
      const response = await new ProductCategoryService().getCategoryMainList();
      const result = response.data.result;

      if (result && result.length > 0) {
        setProductCateData(result);
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  useEffect(() => {
    getApiHandler();
  }, []);

  return (
    <>
      <div className="grid-item footer-col-2">
        <h4>
          <NavLink to="/">Products</NavLink>
        </h4>
        <ul className="footer-menu">
          {productCateData &&
            productCateData
              .slice(0, 4)
              .reverse()
              .map((value, index) => (
                <li key={`product_category_footer_li_${index + 1}`}>
                  <NavLink
                    to={`/product/${value?.category_slug}/${value?._id}`}
                  >
                    {value?.category_name}
                  </NavLink>
                </li>
              ))}
        </ul>
      </div>
    </>
  );
}
