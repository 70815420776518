import "../../../../assets/css/product-single.css";
import React, { useEffect, useState } from "react";
import NotFoundProduct from "../../../data-loader/NotFoundProduct";
import ProductSingleSkel from "../../../skeleton/ProductSingleSkel";
import { useParams } from "react-router-dom";
import { JsonData } from "../../../../../JsonData";
import { ProductService } from "../../../../services/ProductService";
import ProductImageCol from "./ProductImageCol";
import ProductDetailsCol from "./ProductDetailsCol";
import SimilarItemsSlider from "../SimilarItemsSlider";

export default function ProductSingle() {
  const params = useParams();
  const itemID = params.id;

  const [singleProductData, setSingleProductData] = useState();
  const [productSimilarList, setProductSimilarList] = useState([]);
  const [notDataFound, sestNotDataFound] = useState(false);

  // Create a function to fetch similar products
  const fetchProductSimilarList = async (parentCategory) => {
    try {
      const payload = {
        findMany: parentCategory,
      };

      const getData = await new ProductService().getProductsFindByCategory(
        payload
      );

      const getDataResult = getData?.data?.result;
      return getDataResult;
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    }
  };

  useEffect(() => {
    const getApiHandler = async () => {
      try {
        const getData = await new ProductService().getProductShow(itemID);
        const getDataResult = getData?.data?.result;
        if (getDataResult) {
          setTimeout(() => {
            setSingleProductData(getDataResult);
          }, 500);

          const productSimilarListResult = await fetchProductSimilarList(
            getDataResult.product_category_ids
          );

          const NewproductSimilarListResult = productSimilarListResult.filter(
            (ele) => ele._id !== itemID
          );

          setProductSimilarList(NewproductSimilarListResult);
          sestNotDataFound(false);
        } else {
          sestNotDataFound(true);
        }
      } catch (error) {
        sestNotDataFound(true);
        console.error(JsonData?.messages?.errors?.read + " " + error);
      }
    };

    getApiHandler();
  }, [itemID]);

  if (notDataFound)
    return (
      <div className="custom-container">
        <NotFoundProduct data={{ heading: "This product was not found." }} />
      </div>
    );

  if (!singleProductData)
    return (
      <div className="custom-container">
        <ProductSingleSkel />
      </div>
    );

  return (
    <>
      <main className="single-product custom-container">
        <section className="section-padding-md">
          <div className="row gx-xxl-5 justify-content-between">
            <div className="col-xl-4 col-xxl-5 mb-4 mb-lg-5 mb-xl-0">
              <ProductImageCol singleProductData={singleProductData} />
            </div>
            <div className="col-xl-8 col-xxl-7">
              <ProductDetailsCol singleProductData={singleProductData} />
            </div>
          </div>
        </section>
        <SimilarItemsSlider
          sliderHeading="Product Similar Items"
          sliderData={productSimilarList}
          boxLayout={2}
          size="-md"
        />
      </main>
    </>
  );
}
