import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function VariantMeasurementInputHandle({
  value,
  variantValue,
  isDisabled = false,
  onResult = () => {},
  error,
  onError,
}) {
  const defaultType = variantValue?.variant_measurement?.in_status
    ? "IN"
    : variantValue?.variant_measurement?.mm_status
    ? "MM"
    : "";
  const [measurementValue, setMeasurementValue] = useState();
  const [measurementType, setMeasurementType] = useState(defaultType);
  const variantMeasurement = variantValue?.variant_measurement;

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const getCartCartProduct =
    getCartData &&
    getCartData.find((ele) => ele?.cart_product_id === value?._id);

  const getCartCartProductVariant =
    getCartCartProduct?.cart_selected_product_variants.find(
      (ele) => ele?._id === variantValue?._id
    );
  const getCartMeasurement =
    getCartCartProductVariant?.variant_measurement_selected;

  function sendError(status, message) {
    onError({
      id: variantValue._id,
      status: status,
      message: message,
    });
  }

  function sendResult(dataConfig) {
    onResult({
      type: dataConfig.type,
      value: Number(dataConfig.value),
      min: Number(dataConfig.min),
      max: Number(dataConfig.max),
    });
  }

  function measurementHandler(e) {
    const { value } = e.target;
    setMeasurementValue(value);

    const validNumericInput = /^-?\d*\.?\d+$/.test(value);

    if (!validNumericInput) {
      sendError(true, "Enter a valid numeric value.");
      console.log("call 1");
    } else {
      sendError(false, "");

      const measurementConfig =
        measurementType === "MM"
          ? variantMeasurement?.mm
          : measurementType === "IN"
          ? variantMeasurement?.in
          : null;
      const measurementTypeConfig =
        measurementType === "MM"
          ? "MM"
          : measurementType === "IN"
          ? "IN"
          : "N/A";

      if (measurementConfig) {
        const { min, max } = measurementConfig;
        console.log("min, max", min, max);

        const numericValue = parseFloat(value);
        const numericMin = parseFloat(min);
        const numericMax = parseFloat(max);

        if (isNaN(numericValue) || isNaN(numericMin) || isNaN(numericMax)) {
          sendError(true, "Min, max, or input value is not a valid number.");
        } else if (numericMin <= numericValue && numericValue <= numericMax) {
          sendResult({ value, min, max, type: measurementTypeConfig });
          sendError(false, "");
          console.log("call 2", value);
        } else {
          sendError(
            true,
            `Please enter a value between ${min} ${measurementTypeConfig} and ${max} ${measurementTypeConfig}.`
          );
        }
      } else {
        sendError(true, `Max and min values for ${measurementType} not found.`);
        console.log("call 3");
      }
    }
  }

  useEffect(() => {
    if (getCartMeasurement) {
      setMeasurementValue(getCartMeasurement.value);
      setMeasurementType(getCartMeasurement.type);
    } else {
      setMeasurementValue("");
      setMeasurementType(defaultType);
    }
  }, [defaultType, getCartMeasurement]);

  return (
    <>
      <label htmlFor="measurementValue">Measurement <sup>*</sup></label>
      <div
        className="measurement-input"
        aria-invalid={
          variantValue._id === error.id && error.message ? true : false
        }
      >
        <input
          className="form-control fix-input-width"
          id="measurementValue"
          name="measurementValue"
          value={measurementValue}
          onChange={measurementHandler}
          disabled={isDisabled}
        />
        <div className="select-wrapper">
          <select
            className="form-control"
            value={measurementType}
            onChange={(e) => {
              setMeasurementValue("");
              setMeasurementType(e.target.value);
            }}
            disabled={isDisabled}
          >
            {variantMeasurement?.in_status && <option value="IN">IN</option>}
            {variantMeasurement?.mm_status && <option value="MM">MM</option>}
          </select>
        </div>
      </div>
    </>
  );
}
