import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SeoMetaBlock({ blockData }) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {blockData && blockData?.cmpBlock_meta_title
              ? blockData?.cmpBlock_meta_title
              : "Title Not Set - Pass Tooling"}
          </title>
          {blockData && blockData?.cmpBlock_meta_description && (
            <meta
              name="description"
              content={blockData?.cmpBlock_meta_description}
            />
          )}
          {blockData && blockData?.cmpBlock_meta_keywords && (
            <meta name="keywords" content={blockData?.cmpBlock_meta_keywords} />
          )}
        </Helmet>
      </HelmetProvider>
    </>
  );
}
