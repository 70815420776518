import React from "react";
import { NavLink } from "react-router-dom";
import { imageBaseUrl, MAIN_APP_URL } from "../../config/Config";

export default function FooterCopyRight() {
  return (
    <>
      <div className="copyright d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
        <NavLink to={MAIN_APP_URL} className="order_1">
          <img
            src={`${imageBaseUrl}/logo-white.png`}
            alt="logo"
            className="img-fluid footer-logo"
          />
        </NavLink>
        <small className="mb-0 order_3">
          © {new Date().getFullYear()}{" "}
          <span className="text-highlight-color">Pass Tooling</span>. All Rights
          Reserved.
        </small>
        <ul className="footer-social-icons order_2">
          {/* <li className="youtube">
            <a href="/#">
              <i className="fa-brands fa-youtube foot-icon"></i>
            </a>
          </li> */}
          {/* <li className="google-plus">
            <a href="/#">
              <i className="fa-brands fa-google-plus-g foot-icon"></i>
            </a>
          </li> */}
          <li className="linkedin">
            <a
              href="https://www.linkedin.com/company/passtooling"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-linkedin-in foot-icon"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
