import React, { useEffect } from "react";
import { imageBaseUrl, SERVER_URL } from "../../../config/Config";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserDataAction } from "../../../redux-service/actions/action";
import secureLocalStorage from "react-secure-storage";

export default function UserProfileBio() {
  const navigate = useNavigate();
  const userAuthData = JSON.parse(secureLocalStorage.getItem("user_auth"));
  const userID = userAuthData._id;

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer.userData);

  useEffect(() => {
    dispatch(setUserDataAction(userID)); // Dispatch the action to fetch user data
  }, [dispatch, userID]);

  return (
    <>
      {userData && (
        <div
          className="user-profile-bio"
          onClick={() => navigate("/user/profile")}
        >
          <div className="user-img">
            {userData.user_profile ? (
              <img
                src={`${SERVER_URL}${userData.user_profile}`}
                alt="User Profile"
                className="img-fluid"
              />
            ) : (
              <img
                src={`${imageBaseUrl}/avatar.png`}
                alt="User Profile"
                className="img-fluid"
              />
            )}
          </div>
          <div className="user-text">
            <p>
              <b>Hi,</b> {userData.user_name}
            </p>
            <p>
              <Link className="link" to="mailto:">
                {userData.user_email}
              </Link>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
