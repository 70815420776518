/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProductCard2 from "../../cards/ProductCard2";
import { useDispatch, useSelector } from "react-redux";
import NotFoundProduct from "../../data-loader/NotFoundProduct";
import ProductTree from "./tree/ProductTree";
import ProductTopFilterBelt from "./ProductTopFilterBelt";
import { ProductService } from "../../../services/ProductService";
import { JsonData } from "../../../../JsonData";
import {
  mainProductList,
  mainProductListLoader,
  mainProductListPagination,
} from "../../../redux-service/actions/action";
import MainProductLoader from "../../data-loader/MainProductLoader";

export default function ProductMainList() {
  const productList = useSelector(
    (state) => state.productReducer.productMainList
  );
  const productListPagiData = useSelector(
    (state) => state.productReducer.productMainListPagination
  );
  const productListLoader = useSelector(
    (state) => state.productReducer.productMainListLoader
  );

  const isFilter = useSelector(
    (state) => state.productReducer.productMainListIsFilter
  );

  const [page, setPage] = useState(1);
  const productSectionRef = useRef(null);

  const dispatch = useDispatch();

  const getApiHandler = async () => {
    dispatch(mainProductListLoader(true));

    try {
      const query = `?page=${page}`;
      const response = await new ProductService().getProductList(query);
      const getDataResult = response?.data?.result;
      const getPagiData = response?.data?.paginationInfo;

      dispatch(mainProductList(getDataResult));
      if (getPagiData.currentPage > getPagiData.totalPages) {
        dispatch(
          mainProductListPagination({
            ...getPagiData,
            currentPage: 1,
          })
        );
      } else {
        dispatch(mainProductListPagination(getPagiData));
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.read + " " + error);
    } finally {
      dispatch(mainProductListLoader(false));
    }
  };

  const paginationHandler = (e, page) => {
    setPage(page);
    dispatch(
      mainProductListPagination({
        ...productListPagiData,
        currentPage: page,
      })
    );
  };

  useEffect(() => {
    if (!isFilter) {
      getApiHandler();
    }
  }, [page, productListPagiData?.currentPage, isFilter]);

  // Scroll to the top of the product section whenever the page changes
  useEffect(() => {
    if (productSectionRef?.current) {
      const offset = 100; // Adjust this value for desired space
      const element = productSectionRef.current;
      const top =
        element.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top, behavior: "smooth" });
    }
  }, [productListPagiData?.currentPage]);

  return (
    <section className="section-padding">
      <div className="custom-container">
        <ProductTopFilterBelt />
        <div className="product-main-grid" ref={productSectionRef}>
          <div className="grid-item">
            <ProductTree />
          </div>
          <div className="grid-item">
            {productListLoader ? (
              <MainProductLoader limit={productListPagiData?.limit || 20} />
            ) : (
              <>
                {productList && productList.length > 0 ? (
                  <div className="grid-item">
                    <div className="product-card-grid-2">
                      {productList.map((value, index) => (
                        <div className="grid-item" key={index}>
                          <ProductCard2 value={value} index={index} />
                        </div>
                      ))}
                    </div>
                    <div className="pagination-wrapper">
                      <Pagination
                        count={productListPagiData.totalPages || 1}
                        page={productListPagiData.currentPage}
                        size="large"
                        onChange={paginationHandler}
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : (
                  <NotFoundProduct />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
