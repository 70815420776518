import React from "react";
import UserProfileBio from "../user-profile/UserProfileBio";

export default function AddToCartUserBio() {
  return (
    <>
      <div className="form-box sm">
        <UserProfileBio />
      </div>
    </>
  );
}
