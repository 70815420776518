import React from "react";
import Banner from "../components/pages/Banner";
import { SERVER_URL } from "../config/Config";

export default function HeroBannerBlock({ blockData }) {
  return (
    <>
      <Banner
        data={{
          heading: blockData && (
            <div
              dangerouslySetInnerHTML={{
                __html: blockData?.cmpBlock_hero_banner_heading,
              }}
            ></div>
          ),
          image: blockData
            ? `${SERVER_URL}${blockData?.cmpBlock_hero_banner_image}`
            : "banner-1.jpg",
        }}
      />
    </>
  );
}
