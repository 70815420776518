/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import NotificationMessage from "../../../components/cards/NotificationMessage";
import { CustomerService } from "../../../services/CustomerService";

export default function RegisterVerify() {
  // navigate
  const navigate = useNavigate();
  // get query string
  const [searchParam] = useSearchParams();
  const verifyAuthToken = searchParam.get("token");
  //
  const [tokenValidation, setTokenValidation] = useState({
    status: false,
    icon: "",
    type: "default",
    message: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleTokenVerificationAPI() {
    try {
      const resp = await new CustomerService().CustomerRegisterVerify(
        verifyAuthToken
      );
      const result = resp.data;

      if (result.status === true) {
        if (result.message_type === 1) {
          setTokenValidation({
            status: true,
            icon: <i className="fa-solid fa-check"></i>,
            type: "success",
            message: result.message,
          });
        } else if (result.message_type === 2) {
          setTokenValidation({
            status: true,
            icon: <i className="fa-solid fa-triangle-exclamation"></i>,
            type: "warning",
            message: result.message,
          });
        }
        setTimeout(() => {
          navigate("/user/login");
        }, 5000);
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error(error.message, {
          icon: <i className="fa-solid fa-triangle-exclamation"></i>,
        });
      } else {
        const result = error.response.data;
        if (result && result.message_type === 3) {
          setTokenValidation({
            status: true,
            icon: <i className="fa-solid fa-xmark"></i>,
            type: "error",
            message: result.message,
          });
          setTimeout(() => {
            navigate("/");
          }, 5000);
        }
      }
    }
  }

  useEffect(() => {
    if (
      window.location.pathname === "/user/verify/register" &&
      verifyAuthToken
    ) {
      handleTokenVerificationAPI();
    } else {
      navigate("/");
    }
  }, [navigate, verifyAuthToken]);

  return tokenValidation.status ? (
    <NotificationMessage
      type={tokenValidation.type}
      icon={tokenValidation.icon}
      message={tokenValidation.message}
    />
  ) : null;
}
