import { toast } from "react-toastify"; // Notification popup show
import { useDispatch } from "react-redux";
import { useState } from "react";
import AuthService from "../../../../../../services/AuthService";
import { CartService } from "../../../../../../services/CartService";
import { findCartItemAction } from "../../../../../../redux-service/actions/action";
import { JsonData } from "../../../../../../../JsonData";

export default function VariantQtyPlusMinus({
  value,
  variantValue,
  size = "",
}) {
  const [qty, setQty] = useState(1);
  const userId = AuthService.getUserId();
  const dispatch = useDispatch();

  const qtyPlusMinusCartHandler = async (newQty) => {
    if (!value || !variantValue) {
      console.error("Cart value is missing.");
      return;
    }

    try {
      const payload = {
        cartId: value._id,
        variantId: variantValue._id,
        variant_quantity: newQty,
      };

      const response =
        await new CartService().itemVariantQtyPlusMinusToCartList(payload);

      if (response?.data?.status) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });
        if (userId) {
          dispatch(findCartItemAction(userId));
          // dispatch(headerCartMenuToggleAction());
        }
      }
    } catch (error) {
      console.error(JsonData?.messages?.errors?.qtyPlusMinus + " " + error);
    }
  };

  const qtyIncreaseHandler = () => {
    const newQty = variantValue.variant_quantity + 1;
    setQty(newQty);
    qtyPlusMinusCartHandler(newQty);
  };

  const qtyDecreaseHandler = () => {
    const newQty = variantValue.variant_quantity - 1;
    if (qty > 1) {
      setQty(newQty);
      qtyPlusMinusCartHandler(newQty);
    }
  };

  return (
    <>
      <div className={`qty-button qty-button-${size}`}>
        <button title="Quantity Decrease" onClick={qtyDecreaseHandler}>
          <i className="fa-solid fa-minus"></i>
        </button>
        {variantValue.variant_quantity}
        <button title="Quantity Increase" onClick={qtyIncreaseHandler}>
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
    </>
  );
}
