import React from "react";
import { NavLink } from "react-router-dom";
import { imageBaseUrl } from "../../config/Config";
import IsAuthenticated from "../auth-check/IsAuthenticated";

export default function NotFoundCart() {
  return (
    <>
      <div className="d-flex flex-column align-items-center gap-3 text-center bg-loader py-5">
        <img
          src={`${imageBaseUrl}/icons/empty-cart.svg`}
          className="img-fluid not-found-img"
          alt="Empty Cart"
        />
        {IsAuthenticated() ? (
          <>
            <h3 className="h4">Your cart is empty</h3>
            <NavLink to="/" className="btn btn-primary">
              Continue Shopping
            </NavLink>
          </>
        ) : (
          <>
            <h3 className="h4">You haven't logged in</h3>
            <NavLink to={"/"} className="btn btn-primary">
              Continue <br className="d-lg-none" /> Shopping - Log In
            </NavLink>
          </>
        )}
      </div>
    </>
  );
}
