import React from "react";
import WishListSkel from "../skeleton/WishListSkel";
import { DateAndTimeFormat } from "../core/DateFormats";
import { JsonData } from "../../../JsonData";
import CartItemInfoSingle from "../cart/CartItemInfoSingle";

export default function OrderCard({ value, index }) {
  const ShipTo = value?.customer_shipping?.shipment_address;

  return (
    <>
      {value ? (
        <div className="order-card" key={`user_order_item${index}`} id="">
          <div className="order-card-header">
            <div className="order-card-title">
              <h4 className="mb-0 d-flex align-items-center gap-2">
                Order ID
                <span className="text-highlight-color">#{value?.order_id}</span>
              </h4>
              <span className="order-card-date">
                {DateAndTimeFormat(value.createdAt)}
              </span>
            </div>
            <ul className="order-card-title desktop-only">
              <li>
                <span>
                  Order Total: {JsonData.CURRENCY_ICON}
                  {value?.order_charges?.total}
                </span>
              </li>
            </ul>
          </div>
          <div className="order-card-body">
            <div className="row gx-0 gx-lg-4 gx-xl-5">
              <div className="col-lg-4">
                <div className="card-order-product-grid scrollbar-sm scrollbar-gray">
                  <div className="table-responsive scrollbar-sm scrollbar-gray table-max-height">
                    <table className="cart-table">
                      <tbody>
                        {value?.customer_order_list.map((orderItem, index) => (
                          <tr key={`${orderItem?.order_id}_cart_${index}`}>
                            <td>
                              <CartItemInfoSingle value={orderItem} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-8" style={{ fontSize: "16px" }}>
                <div className="row gy-4 gx-2">
                  <div className="col-lg-4">
                    <h4>Shipping Info</h4>
                    <b>
                      {ShipTo.customer_name}, {ShipTo.customer_company}
                    </b>
                    <p>
                      Mo. {ShipTo.customer_phone_number}
                      <br />
                      {ShipTo.customer_address}{ShipTo.customer_secondary_address ? `, ${ShipTo.customer_secondary_address}` : '' }
                      <br />
                      {ShipTo.customer_city}, {ShipTo.customer_state}{" "}
                      {ShipTo.customer_zip_code}
                      <br />
                      {ShipTo.customer_country}
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <h4>Track Your Shipment</h4>
                    {value?.customer_shipping?.shipment_details
                      ?.parcel_number ? (
                      <a
                        href={`https://www.ups.com/track?sort_by=status&tracknums_displayed=1&TypeOfInquiryNumber=T&InquiryNumber=${value?.customer_shipping?.shipment_details?.parcel_number}&loc=en_US&requester=ST/trackdetails`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                      >
                        Check Shipment Status
                      </a>
                    ) : (
                      <p className="text-danger">Tracking ID not available</p>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <h4>Order Details</h4>
                    <ul className="ul-none d-flex flex-column gap-2">
                      <li>
                        <b>Status:</b> {value?.status}
                      </li>
                      <li>
                        <b>Payment Type:</b> {value?.customer_payment?.type}
                      </li>
                      {value?.customer_payment?.type ===
                        "Net 30 (via Stripe)" && (
                        <li>
                          <b>Due Date:</b>{" "}
                          {new Date(
                            value?.customer_payment?.response?.due_date * 1000
                          ).toLocaleDateString()}
                        </li>
                      )}
                      <li>
                        <b>Payment Status:</b> {value?.customer_payment?.status}
                      </li>
                      {value?.customer_payment?.type ===
                        "Net 30 (via Stripe)" && (
                        <li className="d-flex gap-2 align-items-center">
                          <b>Net 30 Invoice:</b>
                          <a
                            className="btn btn-outline-primary btn-xxl-sm"
                            href={value.download_order_net_30_invoice_pdf_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {value.download_order_net_30_invoice_pdf_url
                              ? "Download"
                              : "not available"}
                          </a>
                        </li>
                      )}
                      <li className="d-flex gap-2 align-items-center">
                        <b>Order PDF: </b>
                        <a
                          className="btn btn-outline-primary btn-xxl-sm"
                          href={value.download_order_details_pdf_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value.download_order_details_pdf_url
                            ? "Download"
                            : "not available"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-card-footer mobile-only">
            <ul className="order-card-title">
              <li>
                <span>
                  Order Total: {JsonData.CURRENCY_ICON}
                  {value?.order_charges?.total}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <WishListSkel />
      )}
    </>
  );
}
