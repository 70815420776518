import React from "react";
import { JsonData } from "../../../../JsonData";
import OrderCheckoutCalc from "./OrderCheckoutCalc";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function OrderCheckoutTotalView() {
  return (
    <>
      <div className="table-responsive scrollbar-sm scrollbar-gray">
        <table className="table summary-table">
          <tbody>
            <tr>
              <th>
                Sub Total{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`sub-total`}>Your Cart Total</Tooltip>}
                >
                  <i className="fa-solid fa-circle-info"></i>
                </OverlayTrigger>
              </th>
              <td>
                {JsonData.CURRENCY_ICON}
                <OrderCheckoutCalc chargeType="subTotal" />
              </td>
            </tr>
            <tr>
              <th>
                Shipping{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`shipping`}>Orders ship via UPS</Tooltip>
                  }
                >
                  <i className="fa-solid fa-circle-info"></i>
                </OverlayTrigger>
              </th>
              <td>
                {JsonData.CURRENCY_ICON}
                <OrderCheckoutCalc chargeType="shipping" />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>
                Total{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`total`}>Total Cost of Your Order</Tooltip>
                  }
                >
                  <i className="fa-solid fa-circle-info"></i>
                </OverlayTrigger>
              </th>
              <td>
                {JsonData.CURRENCY_ICON}
                <OrderCheckoutCalc chargeType="total" />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}
