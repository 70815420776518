/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import VariantOptionInputHandle from "./helper/VariantOptionInputHandle";
import VariantMeasurementInputHandle from "./helper/VariantMeasurementInputHandle";
import { useSelector } from "react-redux";
import {
  fetchSelectedChoseVariantOptionInCart,
  fetchSelectedMeasurementInCart,
  getCartVariantHelper,
  getVariantDefaultOptionHelper,
} from "../../../../helper/Helper";

export default function VariantSelector({
  variantOptionsList,
  value,
  variantValue,
  variantIndex,
  isMeasurementDisabled = false,
  error,
  onError = () => {},
}) {
  const [variantOptionsSelected, setVariantOptionsSelected] = useState([]);

  const getCartData = useSelector(
    (state) => state.userCartReducer.userCartData
  );

  const getCartVariant = getCartVariantHelper(
    getCartData,
    value?._id,
    variantValue?._id
  );

  const getVariantOptionList = variantValue?.variant_options || [];
  const getVariantDefaultOption =
    getVariantDefaultOptionHelper(getVariantOptionList);

  const isDisabled =
    getCartVariant?.isInCart !== undefined ? getCartVariant?.isInCart : false;

  function renderVariantOptionsInputsHandler(result) {
    const existingIndex = variantOptionsSelected.findIndex(
      (option) => option.variant_options_id === result.variant_options_id
    );

    if (existingIndex === -1) {
      setVariantOptionsSelected([...variantOptionsSelected, result]);
    } else {
      const updatedOptions = [...variantOptionsSelected];
      updatedOptions[existingIndex] = result;
      setVariantOptionsSelected(updatedOptions);
    }
  }

  const renderVariantOptionsInputs = (variantValue) => {
    // eslint-disable-next-line array-callback-return
    return variantOptionsList.map((optionName) => {
      const variantOptionValue = variantValue?.variant_options.find(
        (option) => option.variant_options_name === optionName
      );

      const variantOptionsLists =
        variantOptionValue?.variant_options_lists || null;

      if (variantOptionsLists && variantOptionsLists.length > 0) {
        return (
          <>
            <div className="col-lg-4 mb-3" key={`td_right_${optionName}`}>
              <VariantOptionInputHandle
                value={value}
                variantValue={variantValue}
                variantOptionValue={variantOptionValue}
                isDisabled={isDisabled}
                onResult={renderVariantOptionsInputsHandler}
              />
            </div>
          </>
        );
      }
    });
  };

  const renderMeasurementInput = (variantValue) => {
    return (
      <>
        <div className="col-lg-4 mb-3">
          <VariantMeasurementInputHandle
            value={value}
            variantValue={variantValue}
            isDisabled={isDisabled}
            onResult={(result) => {
              variantValue.variant_measurement_selected = result;
            }}
            error={error}
            onError={(error) => {
              onError(error);
            }}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    if (getVariantDefaultOption.isDefault) {
      // renderVariantOptionsArrayHandler(getVariantDefaultOption.data);
      if (getCartVariant.isInCart === false) {
        setVariantOptionsSelected(getVariantDefaultOption.data);
      }
    }
  }, [getCartVariant.isInCart]);

  useEffect(() => {
    if (!getCartVariant.isInCart) {
      variantValue.variant_options_selected = variantOptionsSelected;
    } else {
      setVariantOptionsSelected([]);
      variantValue.variant_options_selected = [];
      variantValue.variant_measurement_selected = undefined;
    }
  }, [variantOptionsSelected, variantValue.variant_options_selected]);

  return (
    <>
      <h3>Choose this option:</h3>
      <div className="row">
        {!isMeasurementDisabled && renderMeasurementInput(variantValue)}
        {renderVariantOptionsInputs(variantValue)}
      </div>
      <h3>User Selected: {!getCartVariant.isInCart && "N/A"}</h3>
      {getCartVariant.isInCart && (
        <ul className="user-selcted-list">
          {!isMeasurementDisabled && (
            <li>
              {fetchSelectedMeasurementInCart(
                getCartVariant.data?.variant_measurement_selected
              )}
            </li>
          )}
          {fetchSelectedChoseVariantOptionInCart(
            getCartVariant.data?.variant_options_selected
          )}
        </ul>
      )}
    </>
  );
}
