import axios from "../config/NodeAxios";
import { handleApiError } from "../components/core/CommonFunction";

export class ProductCategoryService {
  constructor() {
    this.endpoints = {
      DROPDOWN: "/product/category/public/dropdown", // public
      SHOW: "/product/category/public/:id", // public
      FIND_BY_PARENT: "/product/category/public/dropdown/find-by-parent/:id", // public
      SELECTED_DROPDOWN: "/product/category/public/dropdown/selected", // public
      FIND_MAIN_LIST: "/product/category/public/main/lists", // public
      FIND_BY_CATEGORY: "/product/category/public/find-by-category/:id", // public
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.config2 = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }

  async getCategoryDropdown() {
    try {
      const response = await axios.get(this.endpoints.DROPDOWN);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getCategoryMainList() {
    try {
      const response = await axios.get(this.endpoints.FIND_MAIN_LIST);
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getCategorySingle(id) {
    try {
      const response = await axios.get(this.endpoints.SHOW.replace(":id", id));
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getProductCategoryFindByCategory(id) {
    try {
      const response = await axios.get(
        this.endpoints.FIND_BY_CATEGORY.replace(":id", id)
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getProductCategoryFindByParent(id) {
    try {
      const response = await axios.get(
        this.endpoints.FIND_BY_PARENT.replace(":id", id)
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getSelectedProductCategoryDropdown(IDs) {
    try {
      const response = await axios.post(
        this.endpoints.SELECTED_DROPDOWN,
        IDs,
        this.config
      );
      return response;
    } catch (error) {
      handleApiError(error);
    }
  }
}
