import React, { useState } from "react";
import SectionHeading1 from "../../heading/SectionHeading1";
import KeywordMultipleMachineMakeSelect from "../../form/KeywordMultipleMachineMakeSelect";
import KeywordMultipleMachineModelSelect from "../../form/KeywordMultipleMachineModelSelect";
import { useForm } from "react-hook-form";
import { FormModelService } from "../../../services/FormModelService";
import { toast } from "react-toastify";

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    business_name: "",
    where_are_you_located: "",
    machine_make_ids: [],
    machine_model_ids: [],
    message: "",
  });

  const { phone, machine_make_ids, machine_model_ids } = inputData;

  // Updates `value` with a child component `value`.
  function fetchChildHadnler(name, value) {
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  }

  // <!----- input handler ----------------------------------------!>
  function inputHandler(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    // setValue([e.target.name], e.target.value);
  }
  function resetAll() {
    setInputData({
      name: "",
      email: "",
      phone: "",
      business_name: "",
      where_are_you_located: "",
      machine_make_ids: [],
      machine_model_ids: [],
      message: "",
    });

    setValue("name", "");
    setValue("email", "");
    setValue("phone", "");
    setValue("business_name", "");
    setValue("where_are_you_located", "");
    setValue("machine_make_ids", []);
    setValue("machine_model_ids", []);
    setValue("message", "");
  }

  function formHandler(data) {
    const payload = {
      name: data.name,
      email: data.email,
      phone: phone,
      business_name: data.business_name,
      where_are_you_located: data.where_are_you_located,
      machine_make_ids: machine_make_ids,
      machine_model_ids: machine_model_ids,
      message: data.message,
    };

    new FormModelService()
      .sendContactUs(payload)
      .then((response) => {
        if (response) {
          resetAll();
          toast.success(response.data.message, {
            icon: <i className="fa-solid fa-paper-plane"></i>,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <section className="section-padding pt-0">
        <div className="custom-container">
          <form
            onSubmit={handleSubmit(formHandler)}
            className="contact-form-box"
          >
            <SectionHeading1 data={{ heading: "Get In Touch" }} />
            <div className="row gy-3">
              <div className="col-lg-4 form-group">
                <label htmlFor="name">
                  Name<sup>*</sup>
                </label>
                {errors.name && (
                  <span className="error-message">{errors.name.message}</span>
                )}
                <input
                  type="text"
                  name="name"
                  id="name"
                  {...register("name", { required: "Name is required" })}
                  aria-invalid={errors.name ? "true" : "false"}
                  className="form-control"
                />
              </div>
              <div className="col-lg-4 form-group">
                <label htmlFor="email">
                  Email<sup>*</sup>
                </label>
                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}
                <input
                  type="text"
                  name="email"
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                  className="form-control"
                />
              </div>
              <div className="col-lg-4 form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="form-control"
                  onChange={inputHandler}
                />
              </div>
              <div className="col-lg-6 form-group">
                <label htmlFor="business_name">
                  Business Name<sup>*</sup>
                </label>
                {errors.business_name && (
                  <span className="error-message">
                    {errors.business_name.message}
                  </span>
                )}
                <input
                  type="text"
                  name="business_name"
                  id="business_name"
                  {...register("business_name", {
                    required: "Business Name is required",
                  })}
                  aria-invalid={errors.business_name ? "true" : "false"}
                  className="form-control"
                />
              </div>
              <div className="col-lg-6 form-group">
                <label htmlFor="where_are_you_located">
                  Where are you located?<sup>*</sup>
                </label>
                {errors.where_are_you_located && (
                  <span className="error-message">
                    {errors.where_are_you_located.message}
                  </span>
                )}
                <input
                  type="text"
                  name="where_are_you_located"
                  id="where_are_you_located"
                  {...register("where_are_you_located", {
                    required: "Location is required",
                  })}
                  aria-invalid={errors.where_are_you_located ? "true" : "false"}
                  className="form-control"
                />
              </div>
              <div className="col-lg-6 form-group">
                <KeywordMultipleMachineMakeSelect
                  inputName="machine_make_ids"
                  fetchChildData={fetchChildHadnler}
                />
              </div>
              <div className="col-lg-6 form-group">
                <KeywordMultipleMachineModelSelect
                  inputName="machine_model_ids"
                  fetchChildData={fetchChildHadnler}
                  machineMakeIds={machine_make_ids}
                />
              </div>
              <div className="col-lg-12 form-group">
                <label htmlFor="message">
                  Message<sup>*</sup>
                </label>
                {errors.message && (
                  <span className="error-message">
                    {errors.message.message}
                  </span>
                )}
                <textarea
                  name="message"
                  className="form-control"
                  id="message"
                  rows={4}
                  {...register("message", { required: "Message is required" })}
                  aria-invalid={errors.message ? "true" : "false"}
                ></textarea>
              </div>
              <div className="col-lg-12 d-flex flex-column flex-md-row gap-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <button type="reset" className="btn btn-outline-primary">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
