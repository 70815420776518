import React from "react";
import { SERVER_URL } from "../config/Config";

export default function ImageBlock({ blockData }) {
  if (!blockData) {
    return null;
  } else if (blockData?.cmpBlock_full_screen_image_image === "") {
    return null;
  }

  return (
    <>
      <section className="overflow-hidden bg-light">
        <div className="image-container">
          <img
            src={`${SERVER_URL}${blockData?.cmpBlock_full_screen_image_image}`}
            alt={`${blockData?.cmpBlock_full_screen_image_image}`}
            className="img-fluid"
          />
        </div>
      </section>
    </>
  );
}
