/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { imageBaseUrl } from "../../../../../config/Config";
import { Nav, Tab } from "react-bootstrap";
import ProductAdditionalOptionsHtmlDemo2 from "./ProductAdditionalOptionsHtmlDemo2";

export default function ProductDetailsDemoHtml() {
  return (
    <>
      <main className="single-product custom-container">
        <section className="section-padding-md">
          <div className="row gx-xxl-5 justify-content-between">
            <div className="col-xl-4 col-xxl-5 mb-4 mb-lg-5 mb-xl-0">
              <div className="product-image-container">
                <div className="single-product-sku">
                  <span>sku</span>
                </div>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="ProTab1"
                >
                  <Tab.Content>
                    <Tab.Pane key={1} eventKey={`ProTab1`}>
                      <div className="product-image-main">
                        <img
                          src={`${imageBaseUrl}/product-default-1.png`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane key={2} eventKey={`ProTab2`}>
                      <div className="product-image-main">
                        <img
                          src={`${imageBaseUrl}/product-default-1.png`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane key={3} eventKey={`ProTab3`}>
                      <div className="product-image-main">
                        <img
                          src={`${imageBaseUrl}/product-default-1.png`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane key={4} eventKey={`ProTab4`}>
                      <div className="product-image-main">
                        <img
                          src={`${imageBaseUrl}/product-default-1.png`}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  <Nav variant="pills" className="product-image-sub-row">
                    <Nav.Item>
                      <Nav.Link eventKey={`ProTab1`}>
                        <div className="product-image-sub">
                          <img
                            src={`${imageBaseUrl}/default-image-150-100.png`}
                            alt={`tab`}
                            className="img-fluid"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={`ProTab2`}>
                        <div className="product-image-sub">
                          <img
                            src={`${imageBaseUrl}/default-image-150-100.png`}
                            alt={`tab`}
                            className="img-fluid"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={`ProTab13`}>
                        <div className="product-image-sub">
                          <img
                            src={`${imageBaseUrl}/default-image-150-100.png`}
                            alt={`tab`}
                            className="img-fluid"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={`ProTab4`}>
                        <div className="product-image-sub">
                          <img
                            src={`${imageBaseUrl}/default-image-150-100.png`}
                            alt={`tab`}
                            className="img-fluid"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
              </div>
            </div>
            <div className="col-xl-8 col-xxl-7">
              <div className="product-details">
                <h2>Beta-V2 Station B: Complete Punch Assembly</h2>
                <p>
                  Beta-V2, Station B, diameter up to 31.7mm (1.248"), copper
                  sheet thickness max 15mm (0.59"). Includes: complete head
                  unit, punch body, punch guide, stripper (excludes die).
                </p>
                <hr />
                <span className="price price-lg">
                  $952.60 - $1,399.00
                </span>
                <hr />
                <ProductAdditionalOptionsHtmlDemo2 />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
