import { JsonData } from "../../../../JsonData";

export function CalculatePriceRange(data) {
  if (!data || data.length === 0) {
    return null;
  }

  const getVariationsPrices = data.map((ele) => ele.variant_price);

  const minPrice = getVariationsPrices ? Math.min(...getVariationsPrices) : NaN;
  const maxPrice = getVariationsPrices ? Math.max(...getVariationsPrices) : NaN;

  if (isNaN(minPrice) || isNaN(maxPrice)) {
    return null;
  }

  const formattedMinPrice = minPrice.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedMaxPrice = maxPrice.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <sup>{JsonData.CURRENCY_ICON}</sup>
      {formattedMinPrice === formattedMaxPrice ? (
        formattedMinPrice
      ) : (
        <>
          {formattedMinPrice}
          &nbsp;-&nbsp;
          <sup>{JsonData.CURRENCY_ICON}</sup>
          {formattedMaxPrice}
        </>
      )}
    </>
  );
}
