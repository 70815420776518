import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { SERVER_URL, imageBaseUrl } from "../../../../config/Config";

export default function ProductImageCol({ singleProductData }) {
  return (
    <>
      <div className="product-image-container">
        {singleProductData?.product_sku && (
          <div className="single-product-sku">
            <span>{singleProductData?.product_sku}</span>
          </div>
        )}
        <Tab.Container id="left-tabs-example" defaultActiveKey="ProTab1">
          <Tab.Content>
            {singleProductData?.product_images &&
            singleProductData?.product_images.length > 0
              ? singleProductData?.product_images.map(
                  (proImgValue, proImgIndex) => (
                    <Tab.Pane
                      key={proImgIndex}
                      eventKey={`ProTab${proImgIndex + 1}`}
                    >
                      <div className="product-image-main">
                        {proImgValue !== "" ? (
                          <img
                            src={`${SERVER_URL}${proImgValue}`}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={`${imageBaseUrl}/product-default-1.png`}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </div>
                    </Tab.Pane>
                  )
                )
              : null}
          </Tab.Content>
          <Nav variant="pills" className="product-image-sub-row">
            {singleProductData?.product_images &&
            singleProductData?.product_images.length > 0
              ? singleProductData?.product_images.map(
                  (proImgValue, proImgIndex) => (
                    <Nav.Item key={proImgIndex}>
                      <Nav.Link eventKey={`ProTab${proImgIndex + 1}`}>
                        <div className="product-image-sub">
                          {proImgValue !== "" ? (
                            <img
                              src={`${SERVER_URL}${proImgValue}`}
                              alt={`${singleProductData?.product_name}`}
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={`${imageBaseUrl}/default-image-150-100.png`}
                              alt={`${singleProductData?.product_name}`}
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  )
                )
              : null}
          </Nav>
        </Tab.Container>
      </div>
    </>
  );
}
